import { Entity, LinkedTo, Worksheet, WorksheetFields } from '../../interfaces/all'
import { getDisplayedBooleanValue, getDisplayedFieldType } from '../../interfaces/worksheet'
import SemanticTable from '../table'

type WorksheetComponentProps = Worksheet & {
  entities: Entity[]
}
export default function WorksheetComponent(props: WorksheetComponentProps) {
  const { entities, worksheet_name, worksheet_fields } = props

  function getDisplayedLinkedField(linkedTo: WorksheetFields['linked_to'], linkedFieldId: WorksheetFields['linked_field_id']): string {
    if(!linkedFieldId) return ''

    if(linkedTo === LinkedTo.ENTITY) {
      const entity = entities.find(entity => entity.id.toString() === linkedFieldId)

      return `${ entity?.data_name ?? ''}`
    }

    return ''
  }

  return <>
    <h4>{worksheet_name}</h4>
    <SemanticTable headers={[
      'Field name',
      'Field label',
      'Field type',
      'Linked to',
      'Linked field',
      'Required',
      'Display order'
    ]}
    rows={worksheet_fields.map(field => {
      return {
        cells: [
          field.field_name,
          field.field_label,
          getDisplayedFieldType(field.field_type),
          field.linked_to ?? '',
          getDisplayedLinkedField(field.linked_to, field.linked_field_id),
          getDisplayedBooleanValue(field.required),
          field.display_order_sequence
        ]
      }
    })} />
  </>
}
