import { AxiosResponse } from 'axios'

import { request } from './common'
import { DynamicEntity, Entity } from '../interfaces/all'

const entitiesPath = 'entities'


export const getDynamicEntities = async (): Promise<DynamicEntity[]> => {
  const { data } = await request({
    method: 'GET',
    path: entitiesPath,
    queryParams: [{ key: 'data_type', value: 'Dynamic' }]
  })

  const dynamincEntities = data as Array<{ id: number, data_name: string }>

  return dynamincEntities.map(x => ({ id: x.id, name: x['data_name'] }))
}

export const getEntities = async (): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: entitiesPath
  })
}

export const getEntity = async (entityId: string): Promise<AxiosResponse<Entity>> => {
  return await request({
    method: 'GET',
    path: `${ entitiesPath }/${ entityId }`
  })
}

export const createEntity = async (body: object): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: entitiesPath,
    body
  })
}

export const getRelatedEntities = async(entitiesIds: number[]): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: `${ entitiesPath }/get-related`,
    body: {
      entities: entitiesIds
    }
  })
}
