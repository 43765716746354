import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Form } from 'semantic-ui-react'

import { ColumnType, Entity, LinkedTo, Worksheet, WorksheetFields } from '../../../interfaces/all'
import SemanticLoader from '../../../components/loader'
import SemanticTextFormField from '../../../components/form/text-field'
import TitleWithDocumentation from '../../../components/title-with-documentation'
import SemanticDropdownFormField from '../../../components/form/dropdown-field'
import GlobalConfiguration from '../../../configuration/global'
import SemanticNumberFormField from '../../../components/form/number-field'
import SemanticIcon from '../../../components/icons/icon'
import { getEntities } from '../../../api/entity'
import { checkWorksheetFieldHasData, createWorksheetField, deleteWorksheet, deleteWorksheetField, getWorksheet, updateWorksheetField } from '../../../api/entity-worksheet'
import { showErrorToast, showToast } from '../../../components/toast'
import { useDocumentation } from '../../../hooks/documentation'
import { HttpStatusCode } from '../../../interfaces/status-codes'
import { SemanticButton } from '../../../components/buttons/buttons'
import { allUserFieldTypeOptions, entityOnlyOptions, getDynamicOptions, getLinkedToOptions, isLinkedToRequired, isLinkedFieldIdRequired } from '../../../components/worksheet/rules'
import ConfirmationModal, { ConfirmationModalProps } from '../../../components/modals/confirmation-modal'
import { getDisplayedFieldType } from '../../../interfaces/worksheet'
import SemanticBooleanFormField from '../../../components/form/boolean-field'

export default function EditWorksheetPage() {
  const { entityId, worksheetId } = useParams()
  const { state } = useLocation()
  const [worksheet, setWorksheet] = useState<Worksheet | undefined>()
  const isCaseBased = new GlobalConfiguration().isCaseBased()
  const [entities, setEntities] = useState<Entity[]>()
  const [worksheetFieldsOnEditMode, setWorksheetFieldsOnEditMode] = useState<WorksheetFields | undefined>()
  const [formLoading, setFormLoading] = useState(false)
  const [deletingWorksheet, setDeletingWorksheet] = useState(false)
  const [deleteConfirmationModal, setDeleteConfirmationModal] = useState<ConfirmationModalProps | undefined>()
  const documentation = useDocumentation()
  const navigate = useNavigate()


  useEffect(() => {
    async function loadWorksheet() {
      if (state) {
        setWorksheet(state)
      } else {
        getWorksheet(worksheetId as string).then(res => setWorksheet(res.data))
      }

      getEntities().then(res => setEntities(res.data))
    }

    loadWorksheet()
  }, [state, entityId, worksheetId])

  async function reloadWorksheet() {
    setWorksheet(undefined)
    setWorksheetFieldsOnEditMode(undefined)

    getWorksheet(worksheetId as string).then(res => setWorksheet(res.data))
  }

  const linkedToOptions = getLinkedToOptions(isCaseBased)
  const linkedFieldIdDynamicOptions = getDynamicOptions(entities)

  async function handleDeleteWorksheetField(worksheetFieldName: string) {
    setFormLoading(true)

    const { status: dataCheckStatus, data: hasData } = await checkWorksheetFieldHasData(entityId as string, worksheetFieldName)

    if (dataCheckStatus === HttpStatusCode.OK && !hasData) {
      const { status, data } = await deleteWorksheetField(worksheetId as string, worksheetFieldName)

      if (status === HttpStatusCode.NO_CONTENT) {
        showToast({ message: `${worksheetFieldName} field deleted` })
        reloadWorksheet()
      } else {
        showErrorToast(data?.message ?? 'Could not delete field')
      }
    } else {
      const error = dataCheckStatus === HttpStatusCode.OK ? `${worksheetFieldName} has some data and cannot be deleted` : 'failed to check if field has data'
      showErrorToast(error)
    }

    setFormLoading(false)
  }

  async function handleEdit(worksheetFields: WorksheetFields) {
    setFormLoading(true)

    const { status: dataCheckStatus, data: hasData } = await checkWorksheetFieldHasData(entityId as string, worksheetFields.field_name)

    if (dataCheckStatus === HttpStatusCode.OK && !hasData) {
      setWorksheetFieldsOnEditMode(worksheetFields)
    } else {
      const error = dataCheckStatus === HttpStatusCode.OK ? `${worksheetFields.field_name} has some data and cannot be changed` : 'failed to check if field has data'
      showErrorToast(error)
    }

    setFormLoading(false)
  }

  function validateAndSave() {
    if (!worksheetFieldsOnEditMode?.field_name) {
      showErrorToast('Field name is required')
      return
    }
    if (!worksheetFieldsOnEditMode.field_label) {
      showErrorToast('Field label is required')
      return
    }
    if (!worksheetFieldsOnEditMode.field_type) {
      showErrorToast('Field type is required')
      return
    }
    if (!worksheetFieldsOnEditMode.linked_to && isLinkedToRequired(worksheetFieldsOnEditMode)) {
      showErrorToast(`Linked To is required for type ${getDisplayedFieldType(worksheetFieldsOnEditMode.field_type)}`)
      return
    }
    if (!worksheetFieldsOnEditMode.linked_field_id && isLinkedFieldIdRequired(worksheetFieldsOnEditMode)) {
      showErrorToast(`Linked Field is required when linked to is ${worksheetFieldsOnEditMode.linked_to}`)
      return
    }
    if (!worksheetFieldsOnEditMode.display_order_sequence) {
      showErrorToast('Display order is required')
      return
    }
    if (!(worksheetFieldsOnEditMode.display_order_sequence > 0)) {
      showErrorToast('Display order must be above 0')
      return
    }

    if (worksheetFieldsOnEditMode?.id === -1) {
      createField()
    } else {
      updateField()
    }
  }

  async function createField() {
    if (!worksheetFieldsOnEditMode) return

    setFormLoading(true)

    const { field_name, field_label, field_type, linked_to, linked_field_id, required, display_order_sequence } = worksheetFieldsOnEditMode

    const { status, data } = await createWorksheetField({
      worksheet_id: worksheetId as string,
      field_name,
      field_type,
      field_label,
      linked_to,
      linked_field_id,
      required,
      display_order_sequence
    })

    if (status === HttpStatusCode.CREATED) {
      showToast({ message: `${field_name} created` })
    } else {
      showErrorToast(data?.message ?? 'Could not create field')
      setWorksheetFieldsOnEditMode(undefined)
    }

    setFormLoading(false)
    reloadWorksheet()
  }

  async function updateField() {
    setFormLoading(true)

    const { status, data } = await updateWorksheetField(worksheetFieldsOnEditMode as WorksheetFields)

    if (status === HttpStatusCode.NO_CONTENT) {
      showToast({ message: `${worksheetFieldsOnEditMode?.field_name} updated` })
    } else {
      showErrorToast(data?.message ?? 'Could not update field')
      setWorksheetFieldsOnEditMode(undefined)
    }

    setFormLoading(false)
    reloadWorksheet()
  }

  async function handleDeleteWorksheet() {
    setDeletingWorksheet(true)

    const { status, data } = await deleteWorksheet(worksheetId as string)

    if (status === HttpStatusCode.NO_CONTENT) {
      showToast({ message: `${worksheet?.worksheet_name} worksheet deleted` })
      navigate(`/admin/entities/${entityId}`)
    } else {
      showErrorToast(data?.message ?? 'Could not delete worksheet')
    }

    setDeletingWorksheet(false)
  }

  function addNewField() {
    if (!worksheet) return

    const newWorksheetField = {
      id: -1,
      field_name: '',
      field_label: '',
      field_type: '',
      required: false,
      display_order_sequence: worksheet.worksheet_fields.length + 1
    }

    const worksheetFields = [...worksheet.worksheet_fields, newWorksheetField]

    setWorksheet({
      ...worksheet,
      worksheet_fields: worksheetFields
    })

    setWorksheetFieldsOnEditMode(newWorksheetField)
  }

  const returnButton = <SemanticButton text={'Return'} color='grey' onClick={() => navigate(`/admin/entities/${entityId}`)} />

  const addFieldToWorksheetButton = <SemanticButton text='Add new field' disabled={deletingWorksheet || worksheetFieldsOnEditMode !== undefined}
    onClick={addNewField} />

  const deleteWorksheetButton = <SemanticButton text='Delete worksheet' color='red' disabled={deletingWorksheet || worksheetFieldsOnEditMode !== undefined} loading={deletingWorksheet}
    onClick={() => setDeleteConfirmationModal({
      header: worksheet?.worksheet_name ?? '',
      content: 'Are you sure you want to delete this worksheet?',
      onCancel: () => setDeleteConfirmationModal(undefined),
      onSaved: () => {
        setDeleteConfirmationModal(undefined)
        handleDeleteWorksheet()
      }
    })} />

  return <>
    <div style={{ margin: '0px auto', padding: '16px', minWidth: '1000px' }}>
      {
        worksheet ?
          <>
            <TitleWithDocumentation title={worksheet.worksheet_name} documentationTooltipProps={{ text: documentation.worksheets }} />
            <>
              <div style={{ marginBottom: '16px' }}>
                {returnButton}
                {addFieldToWorksheetButton}
                {worksheet.worksheet_fields.length === 0 && deleteWorksheetButton}
              </div>

              <Form loading={formLoading}>
                {worksheet.worksheet_fields.map((originalField, index) => {
                  const isEditing = originalField.id === worksheetFieldsOnEditMode?.id
                  const worksheetFields = isEditing ? worksheetFieldsOnEditMode as WorksheetFields : originalField

                  return <Form.Group widths='equal' key={index}>
                    <SemanticTextFormField index={0} value={worksheetFields.field_name} placeholder={'field name'} required disabled={!isEditing} label='field name'
                      onChange={(event) => setWorksheetFieldsOnEditMode({
                        ...worksheetFields,
                        field_name: event.target.value
                      })} />

                    <SemanticTextFormField index={1} value={worksheetFields.field_label} placeholder={'field label'} required disabled={!isEditing} label='field label'
                      onChange={(event) => setWorksheetFieldsOnEditMode({
                        ...worksheetFields,
                        field_label: event.target.value
                      })} />

                    <SemanticDropdownFormField index={2} value={worksheetFields.field_type} label={'Field type'} required disabled={!isEditing} dropdownItemProps={allUserFieldTypeOptions}
                      onChange={(_event, data) => setWorksheetFieldsOnEditMode({
                        ...worksheetFields,
                        field_type: data.value as string,
                        linked_to: undefined,
                        linked_field_id: undefined
                      })} />

                    <SemanticDropdownFormField index={3} value={worksheetFields.linked_to} label={'Linked to'}
                      dropdownItemProps={worksheetFields.field_type === ColumnType.LIST ? entityOnlyOptions : linkedToOptions}
                      disabled={!isLinkedToRequired(worksheetFields) || !isEditing} required={isLinkedToRequired(worksheetFields)}
                      onChange={(_event, data) => setWorksheetFieldsOnEditMode({
                        ...worksheetFields,
                        linked_to: data.value as LinkedTo
                      })} />

                    <SemanticDropdownFormField index={4} value={worksheetFields.linked_field_id} label={'Linked field'}
                      dropdownItemProps={linkedFieldIdDynamicOptions}
                      disabled={!isLinkedFieldIdRequired(worksheetFields) || !isEditing}
                      onChange={(_event, data) => setWorksheetFieldsOnEditMode({
                        ...worksheetFields,
                        linked_field_id: data.value as string
                      })} />

                    <SemanticBooleanFormField index={5} value={worksheetFields.required} label={'Required'}
                      disabled={!isEditing}
                      onChange={(_e, data) => setWorksheetFieldsOnEditMode({
                        ...worksheetFields,
                        required: data.checked ?? false
                      })} />

                    <SemanticNumberFormField index={6} value={worksheetFields.display_order_sequence} min={1}
                      label={'Display order'} required disabled={!isEditing}
                      onChange={(value) => setWorksheetFieldsOnEditMode({
                        ...worksheetFields,
                        display_order_sequence: value
                      })} />

                    {
                      worksheetFieldsOnEditMode ?
                        <>
                          {
                            worksheetFieldsOnEditMode.id === worksheetFields.id &&
                            <>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <SemanticIcon name='save' color='green' onClick={validateAndSave} />
                              </div>
                              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <SemanticIcon name='cancel' color='grey' onClick={() => {
                                  reloadWorksheet()
                                }} />
                              </div>
                            </>
                          }
                        </>
                        : <>
                          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <SemanticIcon name='edit' color='green' onClick={() => handleEdit(worksheetFields)} />
                          </span>

                          <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <SemanticIcon name='trash' color='red' onClick={() => {
                              setDeleteConfirmationModal({
                                header: worksheetFields.field_name,
                                content: 'Are you sure you want to delete this worksheet field?',
                                onSaved: () => {
                                  setDeleteConfirmationModal(undefined)
                                  handleDeleteWorksheetField(worksheetFields.field_name)
                                },
                                onCancel: () => setDeleteConfirmationModal(undefined)
                              })
                            }} />
                          </span>
                        </>
                    }
                  </Form.Group>
                })}
              </Form>
            </>
            {
              deleteConfirmationModal && <ConfirmationModal {...deleteConfirmationModal} />
            }
          </>

          : <SemanticLoader />}
    </div>
  </>
}
