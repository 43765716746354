import { Button, Icon, SemanticCOLORS, SemanticICONS, SemanticSIZES, StrictButtonProps } from 'semantic-ui-react'

export interface SemanticButtonProps {
  text: string,
  onClick?: any,
  disabled?: boolean
  size?: SemanticSIZES
  color?: SemanticCOLORS
  type?: StrictButtonProps['type']
  loading?: boolean
}
export function SemanticButton(props: SemanticButtonProps) {
  const { color = 'blue', disabled, text, onClick, size = 'small', type = 'button', loading } = props

  return <><Button type={type} loading={loading}
    color={color} size={size} disabled={disabled} onClick={onClick}>{text}
  </Button></>
}

interface AnimatedButtonProps {
  size?: SemanticSIZES
  color: SemanticCOLORS
  hiddenText: string
  iconName: SemanticICONS
  disabled?: boolean
}
function AnimatedButton(props: AnimatedButtonProps) {
  const {
    size = 'tiny',
    disabled = false,
    color,
    hiddenText,
    iconName
  } = props

  return <>
    <Button disabled={disabled} size={size} color={color} animated='vertical'>
      <Button.Content hidden>{hiddenText}</Button.Content>
      <Button.Content visible>
        <Icon name={iconName} />
      </Button.Content>
    </Button>
  </>
}

export function AnimatedEditButton() {
  return AnimatedButton({ color: 'green', hiddenText: 'edit', iconName: 'edit' })
}

export function AnimatedReturnButton() {
  return AnimatedButton({ color: 'grey', hiddenText: 'return', iconName: 'arrow left' })
}
