const accessTokenKey = 'accessToken'

const getAccessToken = (): string | null => {
  return sessionStorage.getItem(accessTokenKey)
}

const setAccessToken = (value: string): void => {
  sessionStorage.setItem(accessTokenKey, value)
}

const removeAccessToken = (): void => {
  sessionStorage.removeItem(accessTokenKey)
}

export {
  getAccessToken,
  setAccessToken,
  removeAccessToken
}
