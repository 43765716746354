import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { deleteDynamic, updateDynamic } from '../../api/dynamic/dynamics'
import { showToast } from '../../components/toast'
import { canDelete, canEdit } from '../../utils/permissions'
import { DataType, GenericGetResponse } from '../../interfaces/all'
import DynamicGrid from '../../components/ag-grid/dynamic-grid'
import { createDynamicDetails, getDynamicDetails } from '../../api/dynamic/dynamic-details'


function NestedDynamicPage() {
  const { entityId, fieldId, parentId } = useParams()
  const [dynamicData, setDynamicData] = useState<GenericGetResponse | undefined>()

  useEffect(() => {
    getDynamicDetails(fieldId as string, parentId as string).then(response => setDynamicData(response))
  }, [entityId, fieldId, parentId])

  async function reloadGrid() {
    getDynamicDetails(fieldId as string, parentId as string).then(response => setDynamicData(response))
  }

  async function deleteRow(dynamicId: number) {
    const { status } = await deleteDynamic(dynamicId as unknown as string)

    if (status === 204) {
      showToast({ message: 'Row deleted' })
    }

    reloadGrid()
  }

  async function createRow(data: any) {
    const { status } = await createDynamicDetails(data, fieldId as string, parentId as string)

    if (status === 201) {
      showToast({ message: 'Row created' })
    }

    reloadGrid()
  }

  async function updateRow(data: any) {
    const { status } = await updateDynamic(data)

    if (status === 200) {
      showToast({ message: 'Row updated' })
    }

    reloadGrid()
  }

  const userCanEdit = canEdit(parseInt(entityId as string), dynamicData?.meta.permissions)
  const userCanDelete = canDelete(parseInt(entityId as string), dynamicData?.meta.permissions)

  return (
    <>
      <div style={{ margin: '8px', height: '100%', display: 'flex', flexDirection: 'column'  }}>
        {
          dynamicData && dynamicData.meta && dynamicData.raw_data &&
          <DynamicGrid dataType={DataType.DYNAMIC}
            rows={dynamicData.raw_data}
            columns={dynamicData.meta.columns}
            onDelete={(dynamicId) => deleteRow(dynamicId)}
            onCancel={() => reloadGrid()}
            onCreate={(data) => createRow(data)}
            onUpdate={(data) => updateRow(data)}
            canDelete={userCanDelete}
            canEdit={userCanEdit} />
        }
      </div>
    </>
  )
}

export default NestedDynamicPage
