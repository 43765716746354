import { AxiosResponse } from 'axios'

import { request } from './common'

const publicPath = 'public'


export const getPublicDasboardsByOrganization = async (organizationName: string): Promise<AxiosResponse> => {
  return request({
    method: 'GET',
    path: `${ publicPath }/quicksight_dashboards`,
    queryParams: [{
      key: 'organization_name',
      value: organizationName
    }]
  })
}
