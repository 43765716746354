import { AxiosResponse } from 'axios'

import { request } from './common'
import { RegistrationStatus } from '../interfaces/all'

const registrationsPath = 'registrations'

export const getRegistrations = async (): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: registrationsPath
  })
}

export interface RegistrationBasicInformation {
  id: number
  organization_name: string
  email: string
}
export const getRegistrationBasicInformation = async(guid: string): Promise<AxiosResponse<RegistrationBasicInformation>> => {
  return await request({
    method: 'GET',
    path: `${ registrationsPath }/by-guid`,
    queryParams: [{ key: 'guid', value: guid }]
  })
}

interface CreateRegistrationProps {
  organizationName: string
  firstName: string
  lastName: string
  email: string
  username: string
  phoneNumber: string
  recaptchaToken: string
}
export const createRegistration = async(props: CreateRegistrationProps): Promise<AxiosResponse> => {
  const { email, firstName, lastName, organizationName, phoneNumber, username, recaptchaToken } = props

  return await request({
    method: 'POST',
    path: registrationsPath,
    body: {
      organization_name: organizationName,
      first_name: firstName,
      last_name: lastName,
      email,
      username,
      phone: phoneNumber,
      recaptcha_token: recaptchaToken
    }
  })
}

export const updateRegistrationStatus = async (id: number, status: RegistrationStatus): Promise<AxiosResponse> => {
  return await request({
    method: 'PATCH',
    path: `${ registrationsPath }/${ id }`,
    body: {
      status
    }
  })
}

interface VerifyEmailProps {
  guid: string
  password: string
}
export const verifyEmail = async (body: VerifyEmailProps): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: `${ registrationsPath }/verify-email`,
    body
  })
}


export const sendInviteUserEmail = async (registrationId: number): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: `${ registrationsPath }/${registrationId}/invite-user-email`
  })
}

export const deactivateUser = async (registrationId: number): Promise<AxiosResponse> => {
  return await request({
    method: 'PUT',
    path: `${ registrationsPath }/${registrationId}/deactivate`
  })
}

export const activateUser = async (registrationId: number): Promise<AxiosResponse> => {
  return await request({
    method: 'PUT',
    path: `${ registrationsPath }/${registrationId}/activate`
  })
}
