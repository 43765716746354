import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SemanticSegment from '../../../components/segment'
import { Assignment } from '../../../interfaces/api/assignment'
import { getPendingAssignments } from '../../../api/me'


export default function Assignments() {
  const [assignments, setAssignments] = useState<Assignment[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    getPendingAssignments().then(res => setAssignments(res))
  }, [])

  const actionItems = assignments?.map(assignment => ({
    text: `Service #${assignment.service_number} (Case #${assignment.case_number})`,
    onClick: () => navigate(`/cases/${assignment.case_id}/services/${assignment.service_id}`)
  }))

  return <SemanticSegment title='My Action items (services assigned to me)' segmentItems={actionItems} />
}
