import React, { useEffect, useState } from 'react'


interface DocumentationProps {
  packages: string
  entities: string
  groups: string
  users: string
  actions: string
  workflows: string
  locations: string
  transfers: string
  worksheets: string
  dynamichub: string
}
export const DocumentationContext = React.createContext<DocumentationProps>({} as DocumentationProps)

export const DocumentationProvider = ({ children }: any) => {
  const [documentation, setDocumentation] = useState<any>()

  useEffect(() => {
    fetch('/documentation.json').then((response) => {
      response.json().then(data => {
        setDocumentation(data)
      })
    })
  }, [])

  const value: DocumentationProps = {
    packages: documentation?.packages ?? '',
    entities: documentation?.entities ?? '',
    groups: documentation?.groups ?? '',
    users: documentation?.users ?? '',
    actions: documentation?.actions ?? '',
    workflows: documentation?.workflows ?? '',
    locations: documentation?.locations ?? '',
    transfers: documentation?.transfers ?? '',
    worksheets: documentation?.worksheets ?? '',
    dynamichub: documentation?.dynamichub ?? ''
  }

  return (<DocumentationContext.Provider value={value}>{ children }</DocumentationContext.Provider>)
}
