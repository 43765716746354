import React from 'react'
import { useState } from 'react'

interface GuidesContextProps {
  displayGuides: boolean
  setDisplayGuides: (displayGuides: boolean) => void
}

export const GuidesContext = React.createContext<GuidesContextProps>({} as GuidesContextProps)

export const GuidesProvider = ({ children }: any) => {
  const [displayGuides, setDisplayGuides] = useState(false)

  const value = {
    displayGuides,
    setDisplayGuides
  }

  return (
    <GuidesContext.Provider value={value} >
      { children }
    </GuidesContext.Provider>
  )
}
