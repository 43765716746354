import { BuildLeftAlignedDiv, BuildTitle } from './utils/utils'

export default function LoginPageGuides() {
  return (
    <>
      <div style={{ margin: '8px' }}>
        <BuildTitle title='Login' />
        <BuildLeftAlignedDiv content={'Free to try. No credit card needed and no obligation.'} />
        <br/>
        <BuildLeftAlignedDiv content={'To start, click on the “Try it for Free” blue button. Then, on the next page, register by creating a ' +
          'new organization, or if your organization is already registered, click on Join organization.'} />
      </div>
    </>
  )
}
