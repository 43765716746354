import { useEffect, useState } from 'react'
import { Menu, Image, Dropdown } from 'semantic-ui-react'
import { useNavigate, useParams } from 'react-router-dom'

import Logo from '../assets/lab_logo.png'
import { useAuth } from '../hooks/auth'
import { Permissions } from '../interfaces/all'
import { useCase } from '../hooks/case'
import { getCase } from '../api/cases/cases'
import { isSysAdmin, isSysEdit } from '../utils/permissions'
import { getPermissions } from '../api/me'
import { SemanticButton } from './buttons/buttons'
import IconWithText from './icon-with-text'
import { useGuides } from '../hooks/guides'
import GlobalConfiguration from '../configuration/global'

enum Tab {
  HOME = 'home',
  ADMIN = 'admin',
  PACKAGES = 'packages',
  DYNAMIC = 'dynamic',
  TRANSFER = 'transfers',
  REPORTS = 'reports'
}

function MainMenu() {
  const { isLoggedIn, onLogout, username, organizationName } = useAuth()
  const [active, setActive] = useState<Tab | undefined>()
  const [permissions, setPermissions] = useState<Permissions>()
  const isCaseBased = new GlobalConfiguration().isCaseBased()
  const { caseId } = useParams()
  const { selectCase } = useCase()
  const { setDisplayGuides } = useGuides()
  const navigate = useNavigate()

  useEffect(() => {
    async function selectCaseFromUrlParam(caseId: string) {
      getCase(caseId).then(response => {
        const selectedCase = response.raw_data[0]
        selectCase(
          selectedCase.id,
          selectedCase['Case #']
        )
      })
    }

    if (caseId) {
      selectCaseFromUrlParam(caseId)
    }
    // From React docs "React guarantees that setState function identity is stable and won’t change on re-renders.
    // This is why it’s safe to omit from the useEffect or useCallback dependency list"
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseId])

  useEffect(() => {
    async function fetchDynamicEntitiesAndUserPermissions() {
      getPermissions().then(response => setPermissions(response.data))
    }

    if (isLoggedIn) {
      fetchDynamicEntitiesAndUserPermissions()
    }
  }, [isLoggedIn])

  function logout() {
    navigate('/')
    onLogout()
  }

  const displayAdminTab = isSysAdmin(permissions) || isSysEdit(permissions)

  function setActiveAndNavigate(tab: Tab) {
    setActive(tab)
    navigate(`/${tab}`)
  }

  const menuItemsStyle = { fontSize: 14 }

  const guidesMenuItem = <Menu.Item>
    <IconWithText text={'Guides'} onClick={() => setDisplayGuides(true)}
      iconProps={{ color: 'grey', name: 'file alternate outline' }} />
  </Menu.Item>


  return <>
    <Menu style={{ margin: '0', borderRadius: '0' }} inverted size='mini'>
      {isLoggedIn
        ?
        <>
          <Menu.Item> <div style={{ fontSize: '20px', fontWeight: 600, margin: '4px', textAlign: 'center' }}> Infinite System </div></Menu.Item>
          <Menu.Item active={active === Tab.HOME} onClick={() => setActiveAndNavigate(Tab.HOME)}><div style={menuItemsStyle}>Home</div></Menu.Item>
          {
            displayAdminTab && <Menu.Item active={active === Tab.ADMIN} onClick={() => setActiveAndNavigate(Tab.ADMIN)}><div style={menuItemsStyle}>Admin Hub</div></Menu.Item>
          }
          <Menu.Item active={active === Tab.PACKAGES} onClick={() => setActiveAndNavigate(Tab.PACKAGES)}><div style={menuItemsStyle}>Packages</div></Menu.Item>
          <Menu.Item active={active === Tab.DYNAMIC} onClick={() => setActiveAndNavigate(Tab.DYNAMIC)}><div style={menuItemsStyle}>Dynamic Data</div></Menu.Item>
          {
            isCaseBased &&
            <Menu.Item active={active === Tab.TRANSFER} onClick={() => setActiveAndNavigate(Tab.TRANSFER)}><div style={menuItemsStyle}>Transfers</div></Menu.Item>
          }

          <Dropdown item text={'Reports'}>
            <Dropdown.Menu>
              <Menu.Item onClick={() => navigate('/quicksight')}><div style={{ ...menuItemsStyle }}>Infinite dashboard</div></Menu.Item>
              <Menu.Item><a href={`${new GlobalConfiguration().dashboardUrl}`} target="_blank" rel="noopener noreferrer">
                <div style={{ ...menuItemsStyle, color: 'black' }}>Infinite Search</div>
              </a></Menu.Item>
              <Menu.Item active={active === Tab.REPORTS} onClick={() => setActiveAndNavigate(Tab.REPORTS)}><div style={{ ...menuItemsStyle }}>My reports</div></Menu.Item>
            </Dropdown.Menu>
          </Dropdown>

          <Menu.Menu position='right'>
            {guidesMenuItem}
            <Dropdown item text={username ? `${username} (${organizationName})` : ''}>
              <Dropdown.Menu>
                <Dropdown.Item onClick={() => navigate('/profile')}>Profile</Dropdown.Item>
                <Dropdown.Item onClick={logout}>Log out</Dropdown.Item>

              </Dropdown.Menu>
            </Dropdown>
            <Menu.Item>
              <Image src={Logo} alt='Logo' size='tiny' />
            </Menu.Item>
          </Menu.Menu>
        </>
        : <>
          <Menu.Item></Menu.Item>
          <Menu.Item><a href="/"><div style={menuItemsStyle}>Infinite System</div></a></Menu.Item>
          <Menu.Item><a href="/html/products.html"><div style={menuItemsStyle}>Products</div></a></Menu.Item>
          <Menu.Item><a href="/html/services.html"><div style={menuItemsStyle}>Services</div></a></Menu.Item>
          <Menu.Item><a href="/html/pricing.html"><div style={menuItemsStyle}>Pricing</div></a></Menu.Item>
          <Menu.Item><a href="/html/contact-us.html"><div style={menuItemsStyle}>Contact us</div></a></Menu.Item>
          <Menu.Menu position={'right'}>
            {guidesMenuItem}
            <Menu.Item><SemanticButton text={'Try it for free'} onClick={() => navigate('/create-account')} type={'button'} /></Menu.Item>
          </Menu.Menu>
        </>
      }
    </Menu>
  </>
}

export default MainMenu
