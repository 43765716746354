import { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'

import { SemanticButton } from '../buttons/buttons'
import SemanticButtonIcon from '../button-icon'
import SemanticIcon from '../icons/icon'
import { showErrorToast, showToast } from '../toast'
import { createEntityWorksheet } from '../../api/entity-worksheet'
import { ColumnType, Entity, WorksheetFormFields } from '../../interfaces/all'
import { getEntities } from '../../api/entity'
import SemanticTextFormField from '../form/text-field'
import SemanticDropdownFormField from '../form/dropdown-field'
import SemanticNumberFormField from '../form/number-field'
import GlobalConfiguration from '../../configuration/global'
import { allUserFieldTypeOptions, entityOnlyOptions, getDynamicOptions, getLinkedToOptions, isLinkedFieldIdRequired, isLinkedToRequired } from './rules'
import { getDisplayedFieldType } from '../../interfaces/worksheet'
import SemanticBooleanFormField from '../form/boolean-field'


interface WorksheetFormFieldsError {
  index: number
  fieldKey: string
  error: string
}

interface CreateWorksheetProps {
  onCreated: any
  onCanceled: any
  entityId: string
  defaultWorksheetName?: string
  defaultWorksheetFields?: WorksheetFormFields[]
}
export default function CreateWorksheet(props: CreateWorksheetProps) {
  const { defaultWorksheetFields, defaultWorksheetName, entityId, onCreated, onCanceled } = props
  const [worksheetName, setWorksheetName] = useState<string | undefined>()
  const [worksheetFields, setWorksheetFields] = useState<WorksheetFormFields[]>([])
  const [worksheetFieldsErrors, setWorksheetFieldsErrors] = useState<WorksheetFormFieldsError[]>([])
  const [creatingWorksheet, setCreatingWorksheet] = useState(false)
  const [entities, setEntities] = useState<Entity[]>()
  const MIN_DISPLAY_ORDER = 1
  const isCaseBased = new GlobalConfiguration().isCaseBased()

  useEffect(() => {
    getEntities().then(res => setEntities(res.data))

    if(defaultWorksheetFields) {
      setWorksheetFields(defaultWorksheetFields)
    }
    if(defaultWorksheetName) {
      setWorksheetName(defaultWorksheetName)
    }
  }, [defaultWorksheetFields, defaultWorksheetName])

  function getFormError(index: number, fieldKey: string): string | undefined {
    return worksheetFieldsErrors.find(err => err.index === index && err.fieldKey === fieldKey)?.error
  }

  async function submit() {
    setCreatingWorksheet(true)

    const { status, data } = await createEntityWorksheet(entityId, worksheetName as string, worksheetFields)

    if (status === 201) {
      showToast({ message: 'Worksheet created' })
      onCreated()
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Worksheet not created')
    }

    setCreatingWorksheet(false)
  }

  async function validateInputs() {
    setWorksheetFieldsErrors([])

    if (!(worksheetFields && worksheetFields.length > 0)) {
      showErrorToast('Add at least one worksheet field!')
      return
    }

    const newWorksheetFieldsErrors: WorksheetFormFieldsError[] = []

    worksheetFields.forEach((worksheetField, index) => {
      if (!worksheetField.field_name) {
        newWorksheetFieldsErrors.push({
          index,
          fieldKey: 'field_name',
          error: 'field name is required'
        })
      }
      if (!worksheetField.field_label) {
        newWorksheetFieldsErrors.push({
          index,
          fieldKey: 'field_label',
          error: 'field label is required'
        })
      }
      if (!worksheetField.field_type) {
        newWorksheetFieldsErrors.push({
          index,
          fieldKey: 'field_type',
          error: 'field type is required'
        })
      }
      if (!worksheetField.linked_to && isLinkedToRequired(worksheetField)) {
        newWorksheetFieldsErrors.push({
          index,
          fieldKey: 'linked_to',
          error: `Linked To is required for type ${getDisplayedFieldType(worksheetField.field_type)}`
        })
      }
      if (!worksheetField.linked_field_id && isLinkedFieldIdRequired(worksheetField)) {
        newWorksheetFieldsErrors.push({
          index,
          fieldKey: 'linked_field_id',
          error: `Linked Field is required when linked to is ${worksheetField.linked_to}`
        })
      }
      if(!worksheetField.display_order_sequence) {
        newWorksheetFieldsErrors.push({
          index,
          fieldKey: 'display_order_sequence',
          error: 'Display Order is required'
        })
      } else if(!(worksheetField.display_order_sequence > 0)) {
        newWorksheetFieldsErrors.push({
          index,
          fieldKey: 'display_order_sequence',
          error: 'Display Order must be above 0'
        })
      }
    })

    setWorksheetFieldsErrors(newWorksheetFieldsErrors)

    if (!(newWorksheetFieldsErrors && newWorksheetFieldsErrors.length > 0)) {
      submit()
    }
  }

  function addNewField() {
    const copy = [...worksheetFields, {
      field_name: '',
      field_label: '',
      field_type: '',
      required: false,
      display_order_sequence: worksheetFields.length + 1
    }]

    setWorksheetFields(copy)
  }

  function removeField(index: number) {
    const copy = [...worksheetFields]
    copy.splice(index, 1)

    setWorksheetFields(copy)
  }

  function handleChange(index: number, key: string, value: string | number | boolean) {
    const copy = [...worksheetFields]

    // @ts-ignore
    copy[index][key] = value

    setWorksheetFields(copy)
  }

  const importWorksheetFieldTypeOptions = [
    ColumnType.TEXT,
    ColumnType.LARGE_TEXT,
    ColumnType.NUMBER,
    ColumnType.DATE,
    ColumnType.DATETIME
  ].map((x) => ({ key: x, value: x, text: x }))

  const fieldTypeOptions = (defaultWorksheetFields && defaultWorksheetFields.length > 0) ? importWorksheetFieldTypeOptions : allUserFieldTypeOptions
  
  const linkedToOptions = getLinkedToOptions(isCaseBased)
  const linkedFieldIdDynamicOptions = getDynamicOptions(entities)

  function getFormInputCommonProps(index: number, inputName: string, label: string) {
    return {
      label,
      onChange: (e: React.ChangeEvent<HTMLInputElement>) => handleChange(index, inputName, e.target.value),
      error: getFormError(index, inputName),
    }
  }

  return <>
    <Form>
      <SemanticTextFormField index={0} value={worksheetName ?? ''} label={'Worksheet name'} required
        placeholder={'worksheet name'} onChange={(e) => setWorksheetName(e.target.value)}
      />
      {
        worksheetFields.map((field, index) =>
          <Form.Group widths='equal' key={index}>
            <SemanticTextFormField index={0} value={field.field_name} placeholder={'field name'}
              {...getFormInputCommonProps(index, 'field_name', 'Field name')} required />

            <SemanticTextFormField index={1} value={field.field_label} placeholder={'field label'}
              {...getFormInputCommonProps(index, 'field_label', 'Field label')} required />

            <SemanticDropdownFormField index={2} value={field.field_type} label={'Field type'}
              dropdownItemProps={fieldTypeOptions} error={getFormError(index, 'field_type')}
              onChange={(_event, data) => {
                handleChange(index, 'field_type', data.value as string)
                handleChange(index, 'linked_to', '')
                handleChange(index, 'linked_field_id', '')
              }}/>

            <SemanticDropdownFormField index={3} value={field.linked_to} label={'Linked to'}
              dropdownItemProps={field.field_type === ColumnType.LIST ? entityOnlyOptions : linkedToOptions} error={getFormError(index, 'linked_to')}
              onChange={(_event, data) => handleChange(index, 'linked_to', data.value as string)}
              disabled={!isLinkedToRequired(field)} required={isLinkedToRequired(field)} />

            <SemanticDropdownFormField index={4} value={field.linked_field_id} label={'Linked field'}
              dropdownItemProps={linkedFieldIdDynamicOptions}
              error={getFormError(index, 'linked_field_id')}
              onChange={(_event, data) => handleChange(index, 'linked_field_id', data.value as string)}
              disabled={!isLinkedFieldIdRequired(field)} />

            <SemanticBooleanFormField index={5} value={field.required} label={'Required'} onChange={(_e, data) => handleChange(index, 'required', data.checked ?? false)}/> 

            <SemanticNumberFormField index={6} value={field.display_order_sequence} min={MIN_DISPLAY_ORDER}
              label={'Display order'} onChange={(value) => handleChange(index, 'display_order_sequence', value)}
              error={getFormError(index, 'display_order_sequence')} required/>

            <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <SemanticIcon name='delete' color='red' onClick={() => removeField(index)} />
            </span>
          </Form.Group>)
      }
      <div style={{ textAlign: 'left' }}>
        <SemanticButtonIcon buttonProps={{ labelPosition: 'left', color: 'black', onClick: () => addNewField(), disabled: creatingWorksheet }}
          iconProps={{ color: 'yellow', name: 'plus' }} text='Add new worksheet field' />
      </div>
      <div style={{ margin: '16px auto', textAlign: 'center' }}>
        <SemanticButton color='grey' text={'Cancel'} onClick={onCanceled} disabled={creatingWorksheet}/>
        <SemanticButton text={'Create'} onClick={validateInputs} disabled={!(worksheetName) || creatingWorksheet} />
      </div>
    </Form>
  </>
}
