import { useEffect, useState } from 'react'
import { CellClickedEvent } from 'ag-grid-community'
import { AgGridReactProps } from 'ag-grid-react'

import { Registration, RegistrationStatus } from '../../../interfaces/all'
import AgGrid from '../../../components/ag-grid/ag-grid'
import { showErrorToast, showToast } from '../../../components/toast'
import SemanticTextFormField from '../../../components/form/text-field'
import { SemanticButton } from '../../../components/buttons/buttons'
import { activateUser, deactivateUser, getRegistrations, sendInviteUserEmail, updateRegistrationStatus } from '../../../api/registration'
import SemanticIcon from '../../../components/icons/icon'
import { validateEmail } from '../../../utils/utils'
import { inviteUser } from '../../../api/organizations'
import SemanticLoader from '../../../components/loader'
import TitleWithDocumentation from '../../../components/title-with-documentation'
import { useDocumentation } from '../../../hooks/documentation'
import SidebarWrapperForPage from '../../../components/sidebar-wrapper-for-page'
import UsersGuides from '../../../guides/users'
import { HttpStatusCode } from '../../../interfaces/status-codes'


export default function UsersIndexPage() {
  const [registrations, setRegistrations] = useState<Registration[]>([])
  const [loading, setLoading] = useState(false)
  const [updatingUserId, setUpdatingUserId] = useState<number>(0)
  const [inviteUserEmail, setInviteUserEmail] = useState<string>('')
  const [sendingInvite, setSendingInvite] = useState(false)
  const [emailError, setEmailError] = useState<string | undefined>()
  const documentation = useDocumentation()

  useEffect(() => {
    setLoading(true)
    getRegistrations().then(res => {
      setRegistrations(res.data)
      setLoading(false)
    })
  }, [])

  async function reloadGrid() {
    setLoading(true)
    getRegistrations().then(res => {
      setRegistrations(res.data)
      setLoading(false)
    })
  }

  async function sendInvite(email: string): Promise<void> {
    if (!email || !validateEmail(email)) {
      setEmailError('Email is not valid')
      return
    }

    setSendingInvite(true)

    const { status, data } = await inviteUser(email)

    if (status === 201) {
      showToast({ message: `Invite sent to ${email}` })
      setInviteUserEmail('')
    } else {
      showErrorToast(data?.message ?? 'Invite not sent')
    }

    setSendingInvite(false)
    reloadGrid()
  }

  async function updateRegistration(registration: Registration, registrationStatus: RegistrationStatus): Promise<void> {
    setUpdatingUserId(registration.id)

    const { status, data } = await updateRegistrationStatus(registration.id, registrationStatus)

    if (status === 200) {
      showToast({ message: `${registration.email} is now ${registrationStatus}` })
    } else {
      showErrorToast(data?.message ?? `Could not change ${registration.status} to ${registrationStatus}`)
    }

    reloadGrid()
    setUpdatingUserId(0)
  }

  async function resendInvite(registration: Registration): Promise<void> {
    setUpdatingUserId(registration.id)

    const { status, data } = await sendInviteUserEmail(registration.id)

    if (status === HttpStatusCode.CREATED) {
      showToast({ message: `Invite sent to ${registration.email}` })
    } else {
      showErrorToast(data?.message ?? 'Invite not sent')
    }

    reloadGrid()
    setUpdatingUserId(0)
  }

  async function deactivateUserApiCall(registration: Registration): Promise<void> {
    setUpdatingUserId(registration.id)

    const { status, data } = await deactivateUser(registration.id)

    if (status === HttpStatusCode.NO_CONTENT) {
      showToast({ message: `User ${registration.email} deactivated` })
    } else {
      showErrorToast(data?.message ?? 'User could not be deactivated')
    }

    reloadGrid()
    setUpdatingUserId(0)
  }

  async function activateUserApiCall(registration: Registration): Promise<void> {
    setUpdatingUserId(registration.id)

    const { status, data } = await activateUser(registration.id)

    if (status === HttpStatusCode.NO_CONTENT) {
      showToast({ message: `User ${registration.email} activated` })
    } else {
      showErrorToast(data?.message ?? 'User could not be activated')
    }

    reloadGrid()
    setUpdatingUserId(0)
  }

  const commonColumnDefs = {
    editable: false,
    resizable: true,
    flex: 1
  }

  const columnDefs: AgGridReactProps['columnDefs'] = [
    {
      pinned: 'left',
      width: 60,
      cellRenderer: (event: CellClickedEvent) => {
        if (event.data.status !== RegistrationStatus.AWAITING_ADMIN_APPROVAL) return

        const disabled = updatingUserId !== 0

        return <span title='approve' onClick={() => disabled ? undefined : updateRegistration(event.data, RegistrationStatus.AWAITING_EMAIL_VERIFICATION)}>
          <SemanticIcon color={'green'} name={'check'} disabled={disabled}
          /></span>
      },
    },
    {
      pinned: 'left',
      width: 60,
      cellRenderer: (event: CellClickedEvent) => {
        if (event.data.status !== RegistrationStatus.AWAITING_ADMIN_APPROVAL) return

        const disabled = updatingUserId !== 0

        return <span title='reject' onClick={() => disabled ? undefined : updateRegistration(event.data, RegistrationStatus.REJECTED)}>
          <SemanticIcon color={'red'} name={'delete'} disabled={disabled}
          /></span>
      },
    },
    {
      pinned: 'left',
      width: 60,
      cellRenderer: (event: CellClickedEvent) => {
        if (event.data.status !== RegistrationStatus.INVITED) return

        const enabled = updatingUserId === 0

        return <span title='resend invite' onClick={() => enabled ? resendInvite(event.data) : undefined}>
          <SemanticIcon color={'blue'} name={'mail outline'} disabled={!enabled}
          /></span>
      },
    },
    {
      pinned: 'left',
      width: 60,
      cellRenderer: (event: CellClickedEvent) => {
        const enabled = updatingUserId === 0

        if (event.data.status === RegistrationStatus.ACTIVE) {
          return <span title='deactivate user' onClick={() => enabled ? deactivateUserApiCall(event.data) : undefined}>
            <SemanticIcon color={'green'} name={'check circle'} disabled={!enabled} /></span>
        } else if (event.data.status === RegistrationStatus.INACTIVE) {
          return <span title='activate user' onClick={() => enabled ? activateUserApiCall(event.data) : undefined}>
            <SemanticIcon color={'green'} name={'circle outline'} disabled={!enabled} /></span>
        }
      }
    },
    {
      ...commonColumnDefs,
      headerName: 'First name',
      field: 'first_name'
    },
    {
      ...commonColumnDefs,
      headerName: 'Last name',
      field: 'last_name'
    },
    {
      ...commonColumnDefs,
      headerName: 'Email',
      field: 'email'
    },
    {
      ...commonColumnDefs,
      headerName: 'Status',
      field: 'status'
    },
  ]

  const pageContent = <>
    <div style={{ margin: '0 auto', padding: '8px', maxWidth: '800px', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <TitleWithDocumentation title={'Users'} documentationTooltipProps={{ text: documentation.users }} />
      <h3>
        Invite new users
      </h3>
      <div>
        <div>
          <SemanticTextFormField index={0} value={inviteUserEmail} label={'Email'} placeholder={'email'} error={emailError}
            onChange={(event) => {
              setInviteUserEmail(event.target.value)
              setEmailError(undefined)
            }}
          />
        </div>
        <div style={{ marginTop: '12px ' }}>
          <SemanticButton text={'Invite'} onClick={() => sendInvite(inviteUserEmail)}
            disabled={updatingUserId !== 0 || sendingInvite} />
          {
            sendingInvite && <span style={{ marginLeft: '12px' }}>{'Sending invite...'}</span>
          }
        </div>
      </div>
      <h3>Current users</h3>
      {
        loading ? <SemanticLoader /> : <AgGrid columnDefs={columnDefs} rowData={registrations} />
      }
    </div>
  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<UsersGuides />} />
}
