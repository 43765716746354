import { ITextFilterParams } from 'ag-grid-community'
import { Column, ColumnType, RawData, isBooleanColumn, isDateColumn, isDatetimeColumn, isDictionaryColumn, isLargeTextColumn, isNumberColumn, isPickListColumn, isTextColumn } from '../../interfaces/all'

import { dateFormatter, dateComparator } from '../../utils/datetime'
import { EMPTY_KEY } from '../form/form'
import { DatetimeEditor, DatetimeCellRenderer } from './date-time'


export function getColumnDefs(col: Column, editable = false): object {
  const commonColumnDefs = {
    headerName: col.label ?? col.name,
    field: col.name,
    resizable: true
  }

  const editableColumnDefs = (editable && !col.readonly) ? {
    editable: true,
    singleClickEdit: true
  } : {}

  const typeBasedColumnDef = getTypeBasedColumnDef(col)

  return {
    ...commonColumnDefs,
    ...editableColumnDefs,
    ...typeBasedColumnDef
  }
}

interface GetColumnsDefs {
  columns: Column[] | undefined
  editable?: boolean
}
export function getColumnsDefs(getColumnDefsProps: GetColumnsDefs): Array<any> {
  const { columns, editable = false } = getColumnDefsProps

  return columns?.filter(col => col.displayed !== false)
    .map(col => {
      return getColumnDefs(col, editable)
    }) ?? []
}

export function AgGridDateFormatter(params: any): string | undefined {
  return params?.value ? dateFormatter(params.value) : undefined
}

function getTypeBasedColumnDef(column: Column): object {
  const { name, type, required } = column

  const filterParams: ITextFilterParams = {
    buttons: ['reset', 'apply'],
    closeOnApply: true
  }

  if (isDictionaryColumn(column)) {
    let values = Object.keys(column.options)
    let options: any = { ...column.options }

    if (!required) {
      options = {
        '': '',
        ...options
      }
      values.unshift('')
    }

    return {
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values
      },
      refData: options
    }
  } else if (isPickListColumn(column)) {
    let options = [...column.options]

    if (!required) {
      options.unshift('')
    }

    return {
      cellEditor: 'agSelectCellEditor',
      cellEditorParams: {
        values: options
      }
    }
  } else if (isLargeTextColumn(column)) {
    return {
      cellEditor: 'agLargeTextCellEditor',
      cellEditorPopup: true,
      cellEditorParams: {
        maxLength: 10000,
        rows: 10,
        cols: 50
      },
      tooltipField: name,
      filter: true,
      filterParams
    }
  } else if (isDateColumn(column)) {
    return {
      cellEditor: 'agDateStringCellEditor',
      valueFormatter: AgGridDateFormatter,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...filterParams,
        comparator: AgGridDateComparator
      },
    }
  } else if (isDatetimeColumn(column)) {
    return {
      cellRenderer: DatetimeCellRenderer,
      cellEditor: DatetimeEditor,
      filter: 'agDateColumnFilter',
      filterParams: {
        ...filterParams,
        comparator: AgGridDateComparator
      },
    }
  } else if (isNumberColumn(column)) {
    return {
      cellDataType: type,
      filter: 'agNumberColumnFilter',
      filterParams
    }
  } else if (isBooleanColumn(column)) {
    return {
      cellDataType: type,
      filter: true,
      filterParams
    }
  } else if (isTextColumn(column)) {
    return {
      filter: true,
      filterParams
    }
  }

  return {}
}

function AgGridDateComparator(filterLocalDateAtMidnight: Date, cellValue: string): number {
  if (cellValue === null) {
    return 0
  }

  return dateComparator(filterLocalDateAtMidnight, cellValue)
}

export const buildExcelHeadersAndRows = (rawData: RawData[], columns: Column[]): { headers: string[], rows: (string | number)[][] } => {
  const columnsToExport = columns.filter(c => c.displayed).filter(c => c.type !== ColumnType.LIST)

  const headers = columnsToExport.map(c => c.name)

  const rows = rawData.map(data => {
    const row: (string | number)[] = []

    for (const [key, value] of Object.entries(data)) {
      const column = columnsToExport.find(c => c.name === key)

      if (column?.type && [ColumnType.BOOLEAN, ColumnType.DATE, ColumnType.DATETIME, ColumnType.LARGE_TEXT, ColumnType.NUMBER, ColumnType.PICKLIST, ColumnType.TEXT].includes(column.type)) {
        row.push(value ?? '')
      } else if (column?.type === ColumnType.DICTIONARY) {
        row.push(column.options[value ?? ''])
      }
    }

    return row
  })

  return {
    headers: headers,
    rows
  }
}

export function getNonfulfilledRequiredCustomFields(data: any, columns: Column[]): string | undefined {
  const nonCustomFields = ['id', 'entity_id', 'service_id', 'Entity ID', 'Display Name']

  const requiredColumns = columns.filter(x => x.required
    && !nonCustomFields.includes(x.name)
    && x.type !== ColumnType.LIST)

  for (const col of requiredColumns) {
    const value = data[col.name]

    if ((col.type === ColumnType.DICTIONARY && value === EMPTY_KEY)
      || (value === undefined || value === null || value === '')) {
      return col.name
    }
  }
}
