import { SemanticCOLORS } from 'semantic-ui-react';
import { SemanticButton, SemanticButtonProps } from './buttons';

export default function CancellationButton(props: SemanticButtonProps) {
  const cancellationColor: SemanticCOLORS = 'grey'

  const semanticProps  = {
    color: cancellationColor,
    ...props
  }

  return <SemanticButton {...semanticProps}/>
}
