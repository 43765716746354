import { useEffect, useState } from 'react'

import { Column } from '../../interfaces/all'
import { useNavigate, useParams } from 'react-router-dom'
import { createService, getServiceColumns } from '../../api/services/services'
import { showToast } from '../../components/toast'
import SemanticForm, { getFormFieldsFromColumns } from '../../components/form/form'

function ServicesCreatePage() {
  const [columns, setColumns] = useState<Column[]>([])
  const { caseId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    getServiceColumns(caseId as string).then(response => setColumns(response))
  }, [caseId])

  async function create(body: any) {
    const { status } = await createService({ ...body, caseId })

    if (status === 201) {
      showToast({ message: 'Service created' })
    }

    navigate(`/cases/${caseId}/services`)
  }

  const formFields = getFormFieldsFromColumns(columns)

  return <div style={{ maxWidth: '300px', margin: '30px auto' }}>
    <SemanticForm title={'Create service'} formFields={formFields}
      onCancel={() => navigate(`/cases/${caseId}/services`)} onSubmit={(data: any) => create(data)} />
  </div>
}

export default ServicesCreatePage
