import { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SemanticForm, { FormField } from '../../../components/form/form'
import { showErrorToast, showToast } from '../../../components/toast'
import { createLocation } from '../../../api/locations'
import SemanticLoader from '../../../components/loader'
import { ColumnType } from '../../../interfaces/all'


export default function LocationCreatePage() {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function onSave(input: any) { 
    setLoading(true)

    const { status, data } = await createLocation({ name: input['name'], barcode: input['barcode'] })

    if (status === 201) {
      showToast({ message: 'Location created!' })
    } else {
      showErrorToast(data.message ?? 'Location not created')
    }

    navigate('/admin/locations')
  }

  const formFields: FormField[] = [
    {
      key: 'name',
      type: ColumnType.TEXT,
      label: 'name',
      required: true,
      placeholder: 'name ...'
    },
    {
      key: 'barcode',
      type: ColumnType.TEXT,
      label: 'barcode',
      required: true,
      placeholder: 'barcode ...'
    }
  ]

  return <>
    <div style={{ maxWidth: '300px', margin: '30px auto' }}>
      {
        loading ? <SemanticLoader/> : <>
          <SemanticForm title='Create Location'
            formFields={formFields}
            onCancel={() => navigate('/admin/locations')}
            onSubmit={(data: any) => onSave(data)}
          />
        </>
      }
    </div>
  </>
}
