import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getCase } from '../../api/cases/cases'
import { AnimatedEditButton, AnimatedReturnButton } from '../../components/buttons/buttons'
import { getColumnsDefs } from '../../components/ag-grid/utils'
import AgGrid from '../../components/ag-grid/ag-grid'
import { canEdit } from '../../utils/permissions'
import { ColumnType, GenericGridResponse } from '../../interfaces/all'
import { CaseItem } from '../../interfaces/api/cases'

function CasePage() {
  const { caseId } = useParams()
  const [cases, setCases] = useState<GenericGridResponse<CaseItem[]> | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    getCase(caseId as string).then(response => setCases(response))
  }, [caseId])

  const userCanEdit = canEdit(cases?.raw_data[0]['Case Type'] as unknown as number, cases?.meta.permissions)

  const columnDefs: any = [
    {
      headerName: 'Return',
      width: 100,
      cellRenderer: AnimatedReturnButton,
      onCellClicked: () => navigate('/cases'),
    },
    {
      headerName: 'Edit',
      width: 100,
      cellRenderer: userCanEdit ? AnimatedEditButton : null,
      onCellClicked: () => userCanEdit ? navigate(`/cases/${caseId}/edit`) : null,
    },
    ...getColumnsDefs({
      columns: cases?.meta.columns.filter(col => col.type !== ColumnType.LIST),
      editable: false
    })
  ]
  const rowData = cases?.raw_data

  return (
    <AgGrid columnDefs={columnDefs} rowData={rowData} />
  )
}

export default CasePage
