import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SemanticSegment from '../../../components/segment'
import { RecentActivity } from '../../../interfaces/local-storage'
import { getRecentActivities } from '../../../utils/localStorage/recent-activities'


export default function RecentActivities() {
  const [recentActivities, setRecentActivities] = useState<RecentActivity[]>([])

  const navigate = useNavigate()

  useEffect(() => {
    setRecentActivities(getRecentActivities())
  }, [])
  
  const recentActivityItems = recentActivities.map(item => ({
    text: item.name,
    onClick: () => navigate(`/entity/${item.id}`)
  }))

  return <SemanticSegment title='Recent activities' segmentItems={recentActivityItems} />
}
