import { useState } from 'react'
import { Menu } from 'semantic-ui-react'

interface MenuItem {
  name: string
  onClick?: any
}
interface SemanticMenuProps {
  items: MenuItem[]
  compact?: boolean
}
export default function SemanticMenu(props: SemanticMenuProps) {
  const [active, setActive] = useState<string>('')
  const { items, compact = false } = props

  function handleOnClick(name: string, onClick: any) {
    setActive(name)
    if(onClick) {
      onClick()
    }
  }

  return <>
    <Menu compact={compact} pointing>
      {items && items.length > 0 && items.map((item, index) =>
        <Menu.Item key={index} name={item.name} active={active === item.name}
          onClick={() => handleOnClick(item.name, item.onClick)}
        />
      )}
    </Menu>
  </>
}
