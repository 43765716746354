import { useEffect, useState } from 'react'
import { DropdownItemProps, Form } from 'semantic-ui-react'

import { SemanticButton } from '../buttons/buttons'
import { Column, ColumnType, isDictionaryColumn, isPickListColumn } from '../../interfaces/all'
import SemanticBooleanFormField from './boolean-field'
import SemanticNumberFormField from './number-field'
import SemanticDropdownFormField from './dropdown-field'
import SemanticDateFormField from './date-field'
import SemanticTextFormField from './text-field'
import SemanticLargeTextFormField from './large-text-field'
import SemanticDateTimeFormField from './datetime-field'


export interface FormField {
  key: string
  type: ColumnType
  label: string
  placeholder?: string
  defaultValue?: any
  dropdownItemProps?: DropdownItemProps[]
  required?: boolean
  error?: string
}
type FormFieldWithValue = FormField & {
  value: string | number | undefined
}

interface SemanticFormProps {
  title?: string
  formFields: FormField[]
  onCancel: Function
  onSubmit: Function
  submitDisabled?: boolean
  submitLoading?: boolean
  cancelLabel?: string
  submitLabel?: string
}

export const EMPTY_KEY = '-1'

export default function SemanticForm(props: SemanticFormProps) {
  const { title, formFields, onCancel, onSubmit, submitDisabled, submitLoading, cancelLabel = 'Cancel', submitLabel = 'Save' } = props
  const [formFieldsWithValue, setFormFieldsWithValue] = useState<FormFieldWithValue[]>([])
  const [submitEnabled, setSubmitEnabled] = useState(false)

  useEffect(() => {
    const newList: FormFieldWithValue[] = []

    formFields.forEach(formField => {
      newList.push({
        ...formField,
        value: formField.defaultValue
      })
    })

    setFormFieldsWithValue(newList)
  }, [formFields])

  function handleChange(index: number, value: any) {
    const copy = [...formFieldsWithValue]

    copy[index].value = value
    delete copy[index].error

    setFormFieldsWithValue(copy)
    setSubmitEnabled(isRequiredFieldsFulfilled(copy))
  }

  function isRequiredFieldsFulfilled(formFields: FormFieldWithValue[]): boolean {
    return formFields.filter(x => x.required)
      .every(formField => {
        const { value, type } = formField

        if (type === ColumnType.DICTIONARY && value === EMPTY_KEY) {
          return false
        } else if(type === ColumnType.DATE || type === ColumnType.DATETIME) {
          return !!value
        } else {
          return value !== undefined && value !== ''
        }
      })
  }

  function validateAndSubmit() {
    let submit = true
    const copy = [...formFieldsWithValue]

    formFieldsWithValue.forEach((formField, index) => {
      const { required, value, label, type } = formField

      if (type === ColumnType.DICTIONARY && value === EMPTY_KEY) {
        formFieldsWithValue[index]['value'] = undefined
      }

      if(!required || type === ColumnType.LIST) return

      if ((type === ColumnType.DICTIONARY && value === EMPTY_KEY)
       || (value === undefined || value === null || value === '')) {
        copy[index].error = `${label} is required`
        submit = false
      }
    })

    if (submit) {
      const keyValue: { [key: string]: string | number | undefined } = {}
      formFieldsWithValue.forEach(formField => {
        keyValue[formField.key] = formField.value
      })
      onSubmit(keyValue)
    } else {
      setFormFieldsWithValue(copy)
      setSubmitEnabled(false)
    }
  }

  return <>
    <div>
      {
        title && <h1>{title}</h1>
      }
      <Form>
        {
          formFieldsWithValue.length > 0 &&
          formFieldsWithValue.map((formField, index) => {
            const { type, label, placeholder, dropdownItemProps, value, error, required } = formField

            if (type === ColumnType.DICTIONARY && dropdownItemProps && dropdownItemProps?.length > 0) {
              return SemanticDropdownFormField({
                value,
                label,
                dropdownItemProps,
                index,
                required,
                error,
                onChange: (_event, data) => handleChange(index, data.value)
              })
            }

            if (type === ColumnType.PICKLIST && dropdownItemProps && dropdownItemProps.length > 0) {
              return SemanticDropdownFormField({
                value,
                label,
                dropdownItemProps,
                index,
                required,
                error,
                onChange: (_event, data) => handleChange(index, data.value)
              })
            }

            if (type === ColumnType.NUMBER) {
              return SemanticNumberFormField({
                value: value as number,
                label,
                index,
                required,
                error,
                onChange: (number) => handleChange(index, number)
              })
            }

            if (type === ColumnType.DATE) {
              return SemanticDateFormField({
                value: typeof value === 'string' ? value : '',
                label,
                index,
                required,
                onChange: (event) => handleChange(index, event.target.value)
              })
            }

            if(type === ColumnType.DATETIME) {
              return SemanticDateTimeFormField({
                value: typeof value === 'string' ? value : '',
                label,
                index,
                required,
                onChange: (event) => handleChange(index, event.target.value)
              })
            }

            if (type === ColumnType.BOOLEAN) {
              return SemanticBooleanFormField({
                value: value as unknown as boolean,
                label,
                index,
                onChange: (_e, data) => handleChange(index, data.checked)
              })
            }

            if (type === ColumnType.LARGE_TEXT) {
              return SemanticLargeTextFormField({
                value: value as string,
                label,
                placeholder: placeholder ?? '',
                index,
                required,
                error,
                onChange: (event) => handleChange(index, event.target.value)
              })
            }

            if (type === ColumnType.LIST) {
              // Lists are sent to BE without changes, and not displayed withih the Form
              // Ideally, these Forms should PATCH changes instead of replacing the whole object (PUT)
              return null
            }

            if (type !== ColumnType.TEXT) {
              console.error(`SemanticForm does not support ${type} yet`)
            }

            return SemanticTextFormField({
              value: typeof value === 'string' ? value : '',
              label,
              placeholder: placeholder ?? '',
              index,
              required,
              error,
              onChange: (event) => handleChange(index, event.target.value)
            })
          })
        }
        <SemanticButton text={cancelLabel} onClick={onCancel} color='grey' />
        <SemanticButton text={submitLabel} onClick={validateAndSubmit} loading={submitLoading}
          disabled={!submitEnabled || submitDisabled} />
      </Form>
    </div>
  </>
}

export function getFormFieldsFromColumns(columns: Column[]): FormField[] {
  return columns.filter(col => col.displayed)
    .filter(col => !col.readonly)
    .map(column => {
      const { name, type, required } = column

      let dropdownItemProps = undefined
      if (isDictionaryColumn(column)) {
        dropdownItemProps = Object.entries(column.options ?? {}).map((option) => ({
          key: option[0], value: option[0], text: option[1]
        }))
        const emptyOption = { key: EMPTY_KEY, value: EMPTY_KEY, text: `Select ${name}` }
        dropdownItemProps.unshift(emptyOption)
      } else if (isPickListColumn(column)) {
        dropdownItemProps = column.options.map((option => ({ key: option, value: option, text: option })))
        const emptyOption = { key: '', value: '', text: '' }
        dropdownItemProps = [emptyOption, ...dropdownItemProps]
      }

      return {
        key: name,
        type,
        label: name,
        required,
        dropdownItemProps
      }
    })
}
