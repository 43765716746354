import { BuildLeftAlignedDiv, BuildList, BuildTitle } from './utils/utils'

export default function DynamicGuides() {
  return <>
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Dynamic'/>
      <BuildLeftAlignedDiv content={'The Dynamic menu will take you the full list of all the Dynamic type entities you created. Based on your permission you may not be able to edit all of them. Examples of usage of Dynamic entities may be:'}/>
      <BuildList items={[
        'Master list of submitting Agency(s) and their locations and contact info',
        'Master list of your equipment',
        'List of Offenses with their codes and corresponding FBI or other agency offense codes that can be used as picklists (dropdown) in cases and services, and anywhere else you want',
        'Click on View next each Dynamic Entity to view and edit the list.',
        'Notice the Dynamic type can be exported to Excel'
      ]}/>
    </div>
  </>
}
