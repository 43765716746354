import { AxiosResponse } from 'axios'

import { request } from './common'

const quicksightPath = 'quicksight'


export const getQuicksightEmbededUrl = async (): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: `${ quicksightPath }/root`
  })
}
