import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { Column, DataType, GenericGetResponse, RawData } from '../../interfaces/all'
import { createDynamic, deleteDynamic, getDynamics, updateDynamic } from '../../api/dynamic/dynamics'
import { showErrorToast, showToast } from '../../components/toast'
import { canDelete, canEdit } from '../../utils/permissions'
import DynamicGrid from '../../components/ag-grid/dynamic-grid'
import ConfirmationModal from '../../components/modals/confirmation-modal'
import { getEntity } from '../../api/entity'
import SemanticLoader from '../../components/loader'


export default function EntityPage() {
  const { entityId } = useParams()
  const [entityName, setEntityName] = useState<string>('')
  const [originalDynamicData, setOriginalDynamicData] = useState<GenericGetResponse | undefined>()
  const [agGridRows, setAgGridRows] = useState<RawData[] | undefined>()
  const [agGridColumns, setAgGridColumns] = useState<Column[] | undefined>()
  const [confirmationModalDynamic, setConfirmationModalDynamic] = useState<RawData | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    if (entityId) {
      getDynamics(entityId).then(response => setOriginalDynamicData(response))
      getEntity(entityId).then(response => setEntityName(response.data.data_name))
    }
  }, [entityId])

  useEffect(() => {
    setAgGridRows(originalDynamicData?.raw_data.map(item => ({ ...item })))
    setAgGridColumns(originalDynamicData?.meta.columns.map(item => ({ ...item })))
  }, [originalDynamicData])

  async function reloadGrid() {
    getDynamics(entityId as string).then(response => setOriginalDynamicData(response))
  }

  async function deleteRow(dynamicId: number) {
    const { status } = await deleteDynamic(dynamicId as unknown as string)

    if (status === 204) {
      showToast({ message: 'Row deleted' })
    }

    reloadGrid()
  }

  async function createRow(body: any) {
    const { status, data } = await createDynamic({ ...body, 'entity_id': entityId })

    if (status === 201) {
      showToast({ message: 'Row created' })
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Row not created')
    }

    reloadGrid()
  }

  async function updateRow(body: any) {
    const { status, data } = await updateDynamic(body)

    if (status === 200) {
      showToast({ message: 'Row updated' })
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Row not updated')
    }

    reloadGrid()
  }

  const userCanEdit = canEdit(parseInt(entityId as string), originalDynamicData?.meta.permissions)
  const userCanDelete = canDelete(parseInt(entityId as string), originalDynamicData?.meta.permissions)

  function handleCancel() {
    setAgGridRows(originalDynamicData?.raw_data.map(item => ({ ...item })))
  }

  return (
    <>
      <div style={{ padding: '8px', height: '100%', display: 'flex', flexDirection: 'column' }}>
        <h1 style={{ textAlign: 'center' }}>{entityName}</h1>
        {
          agGridRows && agGridColumns ?
            <DynamicGrid dataType={DataType.DYNAMIC}
              entityId={entityId as string}
              rows={agGridRows}
              columns={agGridColumns}
              onDelete={(dynamicId) => deleteRow(dynamicId)}
              onCancel={handleCancel}
              onEdit={(dynamicId) => navigate(`/dynamic/${dynamicId}/edit`)}
              onCreate={(data) => createRow(data)}
              onUpdate={(data) => updateRow(data)}
              canDelete={userCanDelete}
              canEdit={userCanEdit} />
            : <SemanticLoader />
        }
      </div>
      {
        confirmationModalDynamic &&
        <ConfirmationModal header={`Dynamic: ${confirmationModalDynamic['Display Name']}`}
          content={'Are you sure you want to delete this dynamic?'}
          onCancel={() => setConfirmationModalDynamic(undefined)}
          onSaved={() => {
            setConfirmationModalDynamic(undefined)
            deleteRow(confirmationModalDynamic.id)
          }} />
      }
    </>
  )
}
