import { useEffect, useState } from 'react'
import { Group, User } from '../../interfaces/all'
import SemanticList from '../list'
import SemanticModal from './modal'
import { getUsers } from '../../api/users'
import { Checkbox } from 'semantic-ui-react'
import { showToast } from '../toast'
import { updateGroup } from '../../api/groups'
import { getDisplayName } from '../../utils/utils'

interface GroupUsersModalProps {
  group: Group & { id: string }
  onCancel: Function
  onSaved: Function
}
export default function GroupUsersModal(props: GroupUsersModalProps) {
  const { group, onCancel, onSaved } = props
  const initialUsersIds = group.members.map(member => member.id)
  const [allUsers, setAllUsers] = useState<User[]>([])
  const [usersIds, setUsersIds] = useState<number[]>(initialUsersIds)

  useEffect(() => {
    getUsers().then(res => {
      setAllUsers(res.data)
    })
  }, [])

  async function saveAndClose() {
    const newUsersIds = usersIds.filter(id => !initialUsersIds.includes(id))
    const removeUsersIds = initialUsersIds.filter(id => !usersIds.includes(id))

    const { status } = await updateGroup({ groupId: group.id, newUsersIds, removeUsersIds })

    if (status === 200) {
      showToast({ message: 'Group users updated' })
    }

    onSaved()
  }

  function addOrRemoveUserId(userId: number) {
    const index = usersIds.indexOf(userId)

    if (index > -1) {
      setUsersIds(usersIds.filter(id => id !== userId))
    } else {
      setUsersIds([...usersIds, userId])
    }
  }

  const content = <SemanticList items={
    allUsers.map(user => {
      const defaultChecked = group.members.some(member => member.id === user.id)

      return (
        <div className='item' key={user.id}>
          <Checkbox defaultChecked={defaultChecked} label={getDisplayName(user)}
            onChange={() => addOrRemoveUserId(user.id)} />
        </div>
      )
    })
  } />

  return <>
    <SemanticModal header={`Editing group: ${group.name}`}
      content={content}
      actions={[
        { color: 'grey', onClick: onCancel, text: 'Cancel' },
        { color: 'blue', onClick: saveAndClose, text: 'Save' }
      ]}
    />
  </>
}
