import SemanticIcon from './icon'

interface EditIconProps {
  onClick: any
  disabled?: boolean
}
export default function EditIcon(props: EditIconProps) {
  const { onClick, disabled } = props

  return <span title='edit' onClick={onClick}>
    <SemanticIcon color={'blue'} name={'edit'} disabled={disabled}
    /></span>
}
