import { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'

import SemanticModal from './modal'
import { SemanticButton } from '../buttons/buttons'
import { getProfile } from '../../api/me'
import { showErrorToast } from '../toast'


interface ConfirmationWithPinModalProps {
  onCancel: Function
  onConfirmed: (pin: string) => void
  warning?: any
}
export default function ConfirmationWithPinModal(props: ConfirmationWithPinModalProps) {
  const { onCancel, onConfirmed, warning } = props
  const [typedPin, setTypedPin] = useState('')
  const [error, setError] = useState<string | undefined>()
  const [registeredPin, setRegisteredPin] = useState<string | undefined>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getPin() {
      const { data } = await getProfile()

      if (data.pin === '') {
        showErrorToast('User has no PIN')
        onCancel()
      }

      setRegisteredPin(data.pin)
      setLoading(false)
    }

    getPin()
  }, [onCancel])

  function handleConfirmation() {
    if (typedPin === registeredPin) {
      onConfirmed(registeredPin)
      setLoading(true)
    } else {
      setError('Invalid PIN')
    }
  }

  const content = <>
    { warning ? <div style={{ marginBottom: '16px'}}>{ warning }</div> : undefined}
    <Form>
      <Form.Input fluid type='password' label='PIN' placeholder='pin ...' value={typedPin} error={error}
        onChange={(e) => {
          setTypedPin(e.target.value)
          setError(undefined)
        }} />
      <div style={{ textAlign: 'center' }}>
        <SemanticButton color='grey' text={'Cancel'} onClick={onCancel} />
        <SemanticButton color='blue' text={'Confirm'} onClick={handleConfirmation} disabled={!typedPin || loading} type='submit' loading={loading} />
      </div>
    </Form>
  </>

  return <SemanticModal header='Please confirm with your PIN' content={content} />
}
