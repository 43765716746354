import { ColumnType } from './all'

export const getDisplayedFieldType = (fieldType: string) => {
  if(fieldType === ColumnType.BOOLEAN) {
    return 'yes/no'
  } else if(fieldType === ColumnType.LARGE_TEXT) {
    return 'large text'
  }

  return fieldType
}

export const getDisplayedBooleanValue = (value: boolean) => value ? 'yes' : 'no'
