enum SystemOperationMode {
  ALL_DYNAMIC = 'all_dynamic',
  CASE_BASED = 'case_based'
}

export default class GlobalConfiguration {
  systemOperationMode: SystemOperationMode
  host: string
  dashboardUrl: string

  constructor() {
    // @ts-ignore
    const loadedMode = `${ window['globalConfigurations']['systemOperationMode'] }`
    if (loadedMode === SystemOperationMode.ALL_DYNAMIC || loadedMode === SystemOperationMode.CASE_BASED) {
      this.systemOperationMode = loadedMode
    } else {
      throw new Error(`Invalid systemOperationMode, it has to be one of: ${ Object.values(SystemOperationMode)}`)
    }
      
    // @ts-ignore
    this.host = `${ window['globalConfigurations']['host'] }`
    // @ts-ignore
    this.dashboardUrl = `${ window['globalConfigurations']['dashboardUrl'] }`
  }

  isCaseBased() {
    return this.systemOperationMode === SystemOperationMode.CASE_BASED
  }
}
