import { DropdownItemProps } from 'semantic-ui-react'
import { ColumnType, Entity, LinkedTo, WorksheetFormFields } from '../../interfaces/all'
import { getDropdownItemPropsFromStrings } from '../form/dropdown-field'
import { getDisplayedFieldType } from '../../interfaces/worksheet'


export const allUserFieldTypeOptions = [
  ColumnType.TEXT,
  ColumnType.LARGE_TEXT,
  ColumnType.NUMBER,
  ColumnType.BOOLEAN,
  ColumnType.DATE,
  ColumnType.DATETIME,
  ColumnType.PICKLIST,
  ColumnType.LIST
].map((x) => ({ key: x, value: x, text: getDisplayedFieldType(x) }))

export const entityOnlyOptions = [{ key: LinkedTo.ENTITY, value: LinkedTo.ENTITY, text: LinkedTo.ENTITY }]

export function getLinkedToOptions(isCaseBased: boolean): DropdownItemProps[] {
  return getDropdownItemPropsFromStrings(isCaseBased ? Object.values(LinkedTo) : [LinkedTo.DYNAMIC, LinkedTo.ENTITY, LinkedTo.USER])
}

export function getDynamicOptions(entities: Entity[] | undefined) {
  return entities?.filter(x => x.data_type === LinkedTo.DYNAMIC)
    .map(x => ({ key: x.id, value: x.id, text: x.data_name })) ?? []
}

export function isLinkedToRequired(field: WorksheetFormFields): boolean {
  return field.field_type === ColumnType.LIST || field.field_type === ColumnType.PICKLIST
}

export function isLinkedFieldIdRequired(field: WorksheetFormFields): boolean {
  const { field_type, linked_to } = field

  return (field_type === ColumnType.LIST || field_type === ColumnType.PICKLIST)
    && linked_to === LinkedTo.ENTITY
}
