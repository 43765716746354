import { AxiosResponse } from 'axios'
import { request } from './common'
import { Package } from '../interfaces/all'

const packagesPath = 'packages'

export const getPackages = async(): Promise<AxiosResponse<Package[]>> => {
  return await request({
    method: 'GET',
    path: packagesPath
  })
}

export const getPackage = async(id: number): Promise<AxiosResponse<Package>> => {
  return await request({
    method: 'GET',
    path: `${ packagesPath }/${ id }`
  })
}

export const getImportedPackages = async(): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: `${ packagesPath }/imported`
  })
}


interface CreatePackageProps {
  packageName: string
  entities: number[]
}
export const createPackage = async (props: CreatePackageProps): Promise<AxiosResponse> => {
  const { packageName, entities } = props

  return await request({
    method: 'POST',
    path: packagesPath,
    body: {
      package: packageName,
      entity_list: entities
    }
  })
}

export const importPackage = async (packageId: string): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: `${ packagesPath }/${ packageId }/import`
  })
}

export const deletePackage = async (packageId: string): Promise<AxiosResponse> => {
  return await request({
    method: 'DELETE',
    path: `${ packagesPath }/${ packageId }`
  })
}
