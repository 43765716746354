export enum SelectedType {
  USER = 'user',
  LOCATION = 'location'
}

export enum MenuOptions {
  BARCODE = 'barcode',
  USER = 'user',
  LOCATION = 'location',
}

interface TransferValidation {
  evidence_id: number
  breaks_chain: boolean
}
export type TransferValidationResponse = TransferValidation

export type BulkTransferValidationResponse = TransferValidation[]
