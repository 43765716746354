import { Form } from 'semantic-ui-react'

interface SemanticDateFormFieldProps {
  index: number
  value: string
  label: string
  required?: boolean
  error?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
export default function SemanticDateFormField(props: SemanticDateFormFieldProps) {
  const { label, index, value, error, required, onChange } = props

  return <>
    <Form.Input type='date' required={required} key={index} fluid value={value}
      label={label} error={error}
      onChange={onChange}
    />
  </>
}
