import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { Column } from '../../interfaces/all'
import { createCase, getCaseColumns } from '../../api/cases/cases'
import { showErrorToast, showToast } from '../../components/toast'
import SemanticForm, { getFormFieldsFromColumns } from '../../components/form/form'
import { setRecentCase } from '../../utils/localStorage/recent-cases'


function CasesCreatePage() {
  const [columns, setColumns] = useState<Column[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    // TODO - either 1- create this api or 2- get all possible columns from cases list page
    getCaseColumns().then(response => setColumns(response))
  }, [])

  async function create(body: any) {
    const { status, data } = await createCase(body)

    if (status === 201) {
      const caseId = data as number
      showToast({ message: 'Case created' })

      setRecentCase({ id: caseId, caseNumber: body['Case #'] })

      navigate(`/cases/${caseId}/edit`)
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Case not created')
    }
  }

  const formFields = getFormFieldsFromColumns(columns)

  return <div style={{ maxWidth: '300px', margin: '30px auto' }}>
    <SemanticForm title={'Create case'} formFields={formFields}
      onCancel={() => navigate('/cases')} onSubmit={(data: any) => create(data)} />
  </div>
}

export default CasesCreatePage
