import { Input, InputOnChangeData } from 'semantic-ui-react'

interface SemanticInputProps {
  value: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>, data: InputOnChangeData) => void
}
export default function SemanticInput(props: SemanticInputProps) {
  const { value, onChange } = props

  return <>
    <Input value={value} size='small' icon='search' placeholder='Search...' onChange={onChange}
    /></>
}
