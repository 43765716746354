import dayjs from 'dayjs'

export function dateFormatter(date: string): string {
  return dayjs(date).format('MM/DD/YYYY')
}

export function dateTimeFormatter(date: string): string {
  return dayjs(date).format('MM/DD/YYYY hh:mm a')
}

export function dateComparator(date1: string | Date, date2: string | Date): number {
  const dayjs1 = dayjs(date1)
  const dayjs2 = dayjs(date2)

  if (dayjs2.isBefore(dayjs1)) {
    return -1
  } else if (dayjs2.isAfter(dayjs1)) {
    return 1
  }
  return 0
}

export function getYear(): number {
  return dayjs().year() 
}
