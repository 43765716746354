import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'semantic-ui-react'

import { SemanticButton } from '../../../components/buttons/buttons'
import { showErrorToast } from '../../../components/toast'
import { createActionType } from '../../../api/actions/types'

export default function ActionTypesCreatePage() {
  const [actionTypeName, setActionTypeName] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  async function onSubmit() {
    setLoading(true)

    const { status, data } = await createActionType(actionTypeName as string)

    if (status === 201) {
      navigate('/admin/actiontypes')
    } else if(status === 400) {
      showErrorToast(data?.message ?? 'Action type not created')
      navigate('/admin/actiontypes')
    }
  }

  return <>
    <div style={{ maxWidth: '300px', margin: '30px auto' }}>
      <h1>Create new action type</h1>
      <Form>
        <Form.Input fluid label='Action type' placeholder='action type name ...' onChange={(e) => setActionTypeName(e.target.value)} />
        <SemanticButton text={'Cancel'} color='grey' onClick={() => navigate('/admin/actiontypes')} />
        <SemanticButton text={'Create'} onClick={onSubmit} disabled={!(actionTypeName) || loading} loading={loading}/>
      </Form>
    </div>
  </>
}
