import { Segment } from 'semantic-ui-react'
import SemanticIcon, { SemanticIconProps } from './icons/icon'

interface SegmentWithIconProps {
  index: number
  text: string
  onClick?: any
  semanticIconProps: SemanticIconProps
}
export default function SegmentWithIcon(props: SegmentWithIconProps) {
  const { semanticIconProps, index, text, onClick } = props

  return <Segment key={index}>
    <span style={{ cursor: 'pointer' }} onClick={onClick}>
      {text}
    </span>
    <span style={{ float: 'right' }}>
      <SemanticIcon {...semanticIconProps}/>
    </span>
  </Segment>
}
