import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SemanticButton } from '../../../components/buttons/buttons'
import SemanticLoader from '../../../components/loader'
import SemanticList from '../../../components/list'
import CancellationButton from '../../../components/buttons/cancelation-button'
import { deleteLocation, getLocations } from '../../../api/locations'
import { Location } from '../../../interfaces/api/location'
import { showErrorToast, showToast } from '../../../components/toast'
import { useDocumentation } from '../../../hooks/documentation'
import TitleWithDocumentation from '../../../components/title-with-documentation'
import LocationGuides from '../../../guides/location'
import SidebarWrapperForPage from '../../../components/sidebar-wrapper-for-page'


export default function LocationsIndexPage() {
  const [locations, setLocations] = useState<Location[]>([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const documentation = useDocumentation()

  useEffect(() => {
    getLocations().then(response => {
      setLocations(response.data)
      setLoading(false)
    })
  }, [])

  async function reloadLocations() {
    setLoading(true)

    getLocations().then(response => {
      setLocations(response.data)
      setLoading(false)
    })
  }

  async function remove(locationId: number) {
    setLoading(true)

    const { status, data } = await deleteLocation(locationId)

    if (status === 204) {
      showToast({ message: 'Location deleted!' })
    } else {
      showErrorToast(data.message ?? 'Location not deleted')
    }

    reloadLocations()
  }

  const locationsList = locations.length > 0 ? <SemanticList items={locations.map((location) => <>
    <SemanticButton size='mini' text='delete' color='red' onClick={() => remove(location.id)} />
    <SemanticButton size='mini' text='edit' color='green' onClick={() => navigate(`/admin/locations/${location.id}/edit`)} />
    {location.name}
  </>)} /> : 'no locations to display'

  const pageContent = <div style={{ maxWidth: '300px', margin: '12px auto', minHeight: '400px' }}>
    <TitleWithDocumentation title={'Locations'} documentationTooltipProps={{ text: documentation.locations }} />
    {
      loading ? <SemanticLoader /> :
        <>
          <div style={{ margin: '8px' }}>
            <CancellationButton text={'Return'} onClick={() => navigate('/admin')} />
            <SemanticButton text={'Create new location'} onClick={() => navigate('/admin/locations/create')} />
          </div>
          {locationsList}</>
    }
  </div>

  return <SidebarWrapperForPage page={pageContent} sidebar={<LocationGuides/>}/>
}
