import { AxiosResponse } from 'axios'
import { request } from './common'
import { Location } from '../interfaces/api/location'


const locationsPath = 'locations'

export const getLocations = async (): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: locationsPath
  })
}

export const getLocation = async (id: string): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: `${locationsPath}/${id}`
  })
}

type CreateLocationProps = Omit<Location, 'id'>
export const createLocation = async (body: CreateLocationProps): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: `${locationsPath}`,
    body
  })
}

type UpdateLocationProps = Omit<Location, 'id'>
export const updateLocation = async (id: string, body: UpdateLocationProps): Promise<AxiosResponse> => {
  return await request({
    method: 'PUT',
    path: `${locationsPath}/${id}`,
    body
  })
}

export const deleteLocation = async (id: number): Promise<AxiosResponse> => {
  return await request({
    method: 'DELETE',
    path: `${locationsPath}/${id}`
  })
}
