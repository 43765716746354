// TODO move all these to appropriate files

export interface Case {
  id: number
  caseNumber: string
}

export interface Entity {
  id: number
  data_name: string
  data_type: string
}

export interface DynamicEntity {
  id: number
  name: string
}

export enum LinkedTo {
  CASE = 'Case',
  SERVICE = 'Service',
  EVIDENCE = 'Evidence',
  DYNAMIC = 'Dynamic',
  ENTITY = 'Entity',
  USER = 'User'
}

export interface WorksheetFields {
  id: number
  field_name: string
  field_label: string
  field_type: string
  linked_to?: LinkedTo
  linked_field_id?: string
  required: boolean
  display_order_sequence: number
}
export type WorksheetFormFields = Omit<WorksheetFields, 'id'>

export interface Worksheet {
  worksheet_id: number
  worksheet_name: string
  worksheet_fields: WorksheetFields[]
}

export enum ColumnType {
  TEXT = 'text',
  LARGE_TEXT = 'large_text',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  DATE = 'date',
  DATETIME = 'datetime',
  PICKLIST = 'picklist',
  LIST = 'list',
  DICTIONARY = 'dictionary'
}

interface BaseColumn {
  name: string
  label?: string
  displayed?: boolean
  required?: boolean
  readonly?: boolean
}
type TextColumn = BaseColumn & { type: ColumnType.TEXT }
type LargeTextColumn = BaseColumn & { type: ColumnType.LARGE_TEXT }
type NumberColumn = BaseColumn & { type: ColumnType.NUMBER }
type BooleanColumn = BaseColumn & { type: ColumnType.BOOLEAN }
type DateColumn = BaseColumn & { type: ColumnType.DATE }
type DatetimeColumn = BaseColumn & { type: ColumnType.DATETIME }
type PicklistColumn = BaseColumn & {
  type: ColumnType.PICKLIST,
  options: string[]
}
type DictionaryColumn = BaseColumn & {
  type: ColumnType.DICTIONARY,
  options: {
    [key: string]: string
  }
}
type ListColumn = BaseColumn & {
  type: ColumnType.LIST,
  linked_id?: number
  field_id?: number
}
export type Column = TextColumn | LargeTextColumn | NumberColumn | BooleanColumn | DateColumn | DatetimeColumn | PicklistColumn | DictionaryColumn | ListColumn

export const isTextColumn = (col: Column): col is TextColumn => col.type === ColumnType.TEXT
export const isLargeTextColumn = (col: Column): col is LargeTextColumn => col.type === ColumnType.LARGE_TEXT
export const isNumberColumn = (col: Column): col is NumberColumn => col.type === ColumnType.NUMBER
export const isBooleanColumn = (col: Column): col is BooleanColumn => col.type === ColumnType.BOOLEAN
export const isDateColumn = (col: Column): col is DateColumn => col.type === ColumnType.DATE
export const isDatetimeColumn = (col: Column): col is DatetimeColumn => col.type === ColumnType.DATETIME
export const isPickListColumn = (col: Column): col is PicklistColumn => col.type === ColumnType.PICKLIST
export const isListColumn = (col: Column): col is ListColumn => col.type === ColumnType.LIST
export const isDictionaryColumn = (col: Column): col is DictionaryColumn => col.type === ColumnType.DICTIONARY

export interface Group {
  name: string
  members: User[]
  permissions: Permissions
  entities: Entity[]
}

export interface User {
  id: number,
  username: string,
  first_name: string,
  last_name: string
  email: string
  is_active: boolean
  is_staff: boolean
  barcode?: number
}

export enum RegistrationStatus {
  ACTIVE = 'Active',
  INACTIVE = 'Inactive',
  INVITED = 'Invited',
  AWAITING_ADMIN_APPROVAL = 'Awaiting Admin Approval',
  AWAITING_EMAIL_VERIFICATION = 'Awaiting Email Verification',
  REJECTED = 'Rejected'
}
export interface Registration {
  id: number
  username: string
  email: string
  status: RegistrationStatus
  first_name: string | null
  last_name: string | null
}


export interface RawData {
  id: number
  [key: string]: string | number | null | undefined
}

export enum Permission {
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
  SYSTEM_EDIT = 'sys_edit',
  SYSTEM_ADMIN = 'sys_admin',
}
export interface Permissions {
  global: Permission[]
  entities: {
    [entity: number]: Permission[] | undefined
  }
}
export interface Metadata {
  columns: Column[]
  permissions?: Permissions
}


// Deprecated - use GenericGridResponse instead
export interface GenericGetResponse {
  meta: Metadata,
  raw_data: RawData[]
}

export interface GenericGridResponse<T> {
  meta: Metadata
  'raw_data': T
}

export interface ActionType  {
  id: number
  name: string
}

export enum DataType {
  ACTION = 'Action',
  CASE = 'Case',
  DYNAMIC = 'Dynamic',
  EVIDENCE = 'Evidence',
  SERVICE = 'Service'
}

type EntityWithWorksheets = Entity & { worksheets: Worksheet[] }
export interface Package {
  id: number
  package_name: string
  created_by_username: string
  created_by_organization_name: string
  entities: EntityWithWorksheets[]
}

export interface GenericDictionary {
  [key: string]: any
}
