import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom'
import './app.css'

import EvidencesIndexPage from './pages/evidences'
import NotFound from './pages/not-found'
import ServicesPage from './pages/services'
import CasesIndexPage from './pages/cases'
import CaseEditPage from './pages/cases/edit'
import CasesCreatePage from './pages/cases/create'
import CasePage from './pages/cases/case'
import EvidencePage from './pages/evidences/evidence'
import EvidencesCreatePage from './pages/evidences/create'
import EvidenceEditPage from './pages/evidences/edit'
import ServicesCreatePage from './pages/services/create'
import ServicePage from './pages/services/service'
import CaseServiceEditPage from './pages/services/edit'
import Toast from './components/toast'
import DynamicsIndexPage from './pages/dynamic'
import LoginPage from './pages/auth/login'
import CaseBasedHomePage from './pages/home/case-based/index'
import { AuthProvider } from './providers/auth'
import { useAuth } from './hooks/auth'
import MainMenu from './components/main-menu'
import CaseSubMenu from './components/case-sub-menu'
import { CaseProvider } from './providers/case'
import Forbidden from './pages/forbidden'
import AdminPage from './pages/admin'
import EntityCreatePage from './pages/admin/entity/create'
import EntitiesIndexPage from './pages/admin/entity'
import EntityEditPage from './pages/admin/entity/edit/edit'
import GroupsIndexPage from './pages/admin/groups'
import GroupCreatePage from './pages/admin/groups/create'
import GroupEditPage from './pages/admin/groups/edit'
import ActionTypes from './pages/admin/actiontypes'
import ActionTypesCreatePage from './pages/admin/actiontypes/create'
import SemanticLoader from './components/loader'
import NestedDynamicPage from './pages/dynamic/nested-dynamic'
import DynamicEditPage from './pages/dynamic/edit'
import OrganizationCreateConfirmationPage from './pages/auth/organization/create-confirmation'
import OrganizationJoinConfirmationPage from './pages/auth/organization/join-confirmation'
import UsersIndexPage from './pages/admin/users'
import OrganizationJoinViaLinkPage from './pages/auth/organization/join-via-link'
import OrganizationJoinViaLinkConfirmationPage from './pages/auth/organization/join-via-link-confirmation'
import AdminEmailVerificationPage from './pages/auth/admin-email-verification'
import CompleteRegistrationConfirmationPage from './pages/auth/complete-registration-confirmation'
import PackagesIndexPage from './pages/packages'
import PackageCreatePage from './pages/packages/create'
import PackagePage from './pages/packages/package'
import EntityPage from './pages/entity/entity'
import AccountCreatePage from './pages/auth/create-account'
import WorkflowIndexPage from './pages/admin/workflow'
import WorkflowCreatePage from './pages/admin/workflow/create'
import WorkflowPage from './pages/admin/workflow/workflow'
import AllDynamicHomePage from './pages/home/all-dynamic'
import GlobalConfiguration from './configuration/global'
import ProfilePage from './pages/profile'
import LocationsIndexPage from './pages/admin/locations'
import LocationCreatePage from './pages/admin/locations/create'
import LocationEditPage from './pages/admin/locations/edit'
import EvidenceTransferPage from './pages/evidences/transfer'
import TransafersIndexPage from './pages/transfers'
import BulkTransfersConfirmationPage from './pages/transfers/confirmation'
import EvidenceChainOfCustodyPage from './pages/evidences/chain-of-custody'
import UserEmailVerificationPage from './pages/auth/user-email-verification'
import ForgotPasswordPage from './pages/auth/forgot-password'
import ResetPasswordPage from './pages/auth/reset-password'
import { DocumentationProvider } from './providers/documentation'
import EditWorksheetPage from './pages/admin/worksheet/edit'
import { GuidesProvider } from './providers/guides'
import ReportsPage from './pages/reports'
import { ReportPage } from './pages/reports/report'
import InternalError from './pages/internal-error'
import EntityImportCases from './pages/admin/entity/import/cases'
import AllEvidencesPage from './pages/evidences/all-evidences'
import OrphanEvidenceEditPage from './pages/evidences/orphan-evidence-edit'
import EntityImportEvidences from './pages/admin/entity/import/evidences'
import EntityImportServices from './pages/admin/entity/import/services'
import AllServicesPage from './pages/services/all-services'
import ServiceEditPage from './pages/services/single-service-edit'
import QuicksightIndexPage from './pages/quicksight'
import EntityImportDynamic from './pages/admin/entity/import/dynamic'
import Footer from './components/footer'
import OrganizationPublicPage from './pages/public/organization'
import PublicIndexPage from './pages/public'


const ProtectedRoute = ({ children }: any) => {
  const { isLoggedIn, tokenLoaded } = useAuth()

  if (!tokenLoaded) {
    return <SemanticLoader />
  }

  if (!isLoggedIn) {
    return <Navigate to='/' replace />
  }

  return children
}

const isCaseBased = new GlobalConfiguration().isCaseBased()

const caseBasedPagesOrNothing: Page[] = isCaseBased ? [
  { path: 'cases', element: <CasesIndexPage /> },
  { path: 'cases/create', element: <CasesCreatePage /> },
  { path: 'cases/:caseId', element: <><CaseSubMenu /><CasePage /></> },
  { path: 'cases/:caseId/edit', element: <><CaseSubMenu /><CaseEditPage /></> },
  { path: 'cases/:caseId/evidences', element: <><CaseSubMenu /><EvidencesIndexPage /></> },
  { path: 'cases/:caseId/evidences/create', element: <><CaseSubMenu /><EvidencesCreatePage /></> },
  { path: 'cases/:caseId/evidences/:evidenceId', element: <><CaseSubMenu /><EvidencePage /></> },
  { path: 'cases/:caseId/evidences/:evidenceId/edit', element: <><CaseSubMenu /><EvidenceEditPage /></> },
  { path: 'cases/:caseId/evidences/:evidenceId/transfer', element: <><CaseSubMenu /><EvidenceTransferPage /></> },
  { path: 'cases/:caseId/evidences/:evidenceId/chain-of-custody', element: <><CaseSubMenu /><EvidenceChainOfCustodyPage /></> },
  { path: 'cases/:caseId/services', element: <><CaseSubMenu /><ServicesPage /></> },
  { path: 'cases/:caseId/services/create', element: <><CaseSubMenu /><ServicesCreatePage /></> },
  { path: 'cases/:caseId/services/:serviceId', element: <><CaseSubMenu /><ServicePage /></> },
  { path: 'cases/:caseId/services/:serviceId/edit', element: <><CaseSubMenu /><CaseServiceEditPage /></> },
  { path: 'evidences', element: <><AllEvidencesPage /></> },
  { path: 'services', element: <><AllServicesPage /></> },
  { path: 'evidences/:evidenceId/edit', element: <><OrphanEvidenceEditPage /></> },
  { path: 'evidences/:evidenceId/transfer', element: <><EvidenceTransferPage /></> },
  { path: 'services/:serviceId/edit', element: <><ServiceEditPage /></> },
  { path: 'transfers', element: <TransafersIndexPage /> },
  { path: 'transfers/bulk-confirmation', element: <BulkTransfersConfirmationPage /> },
  { path: 'admin/entities/:entityId/importcases', element: <EntityImportCases /> },
  { path: 'admin/entities/:entityId/importevidences', element: <EntityImportEvidences /> },
  { path: 'admin/entities/:entityId/importservices', element: <EntityImportServices /> },
  { path: 'admin/entities/:entityId/importdynamic', element: <EntityImportDynamic /> }
] : []

interface Page {
  path: string
  element: JSX.Element
}
const protectedPages: Page[] = [
  { path: 'home', element: isCaseBased ? <CaseBasedHomePage /> : <AllDynamicHomePage /> },
  { path: 'admin', element: <AdminPage /> },
  { path: 'admin/entities', element: <EntitiesIndexPage /> },
  { path: 'admin/entities/create', element: <EntityCreatePage /> },
  { path: 'admin/entities/:entityId', element: <EntityEditPage /> },
  { path: 'admin/entities/:entityId/worksheets/:worksheetId/edit', element: <EditWorksheetPage /> },
  { path: 'admin/groups', element: <GroupsIndexPage /> },
  { path: 'admin/groups/create', element: <GroupCreatePage /> },
  { path: 'admin/groups/:groupId', element: <GroupEditPage /> },
  { path: 'admin/actiontypes', element: <ActionTypes /> },
  { path: 'admin/actiontypes/create', element: <ActionTypesCreatePage /> },
  { path: 'admin/workflows', element: <WorkflowIndexPage /> },
  { path: 'admin/workflows/create', element: <WorkflowCreatePage /> },
  { path: 'admin/workflows/:workflowId', element: <WorkflowPage /> },
  { path: 'admin/users', element: <UsersIndexPage /> },
  { path: 'admin/locations', element: <LocationsIndexPage /> },
  { path: 'admin/locations/create', element: <LocationCreatePage /> },
  { path: 'admin/locations/:locationId/edit', element: <LocationEditPage /> },
  ...caseBasedPagesOrNothing,
  { path: 'dynamic', element: <DynamicsIndexPage /> },
  { path: 'dynamic/:dynamicId/edit', element: <DynamicEditPage /> },
  { path: 'dynamic/:entityId/fieldId/:fieldId/parentId/:parentId', element: <NestedDynamicPage /> },
  { path: 'entity/:entityId', element: <EntityPage /> },
  { path: 'packages', element: <PackagesIndexPage /> },
  { path: 'packages/create', element: <PackageCreatePage /> },
  { path: 'packages/:packageId', element: <PackagePage /> },
  { path: 'reports', element: <ReportsPage /> },
  { path: 'reports/:reportId', element: <ReportPage /> },
  { path: 'quicksight', element: <QuicksightIndexPage /> },
  { path: 'profile', element: <ProfilePage /> }
]

const getProtectedPages = (): Page[] => {
  return protectedPages.map(page => ({
    path: page.path,
    element: <ProtectedRoute>{getDefaultPageLayout(page.element)}</ProtectedRoute>
  }))
}

const getDefaultPageLayout = (page: JSX.Element): JSX.Element => {
  return <>
    <div style={{ flexGrow: 0, flexShrink: 0}}><MainMenu /></div>
    <div style={{ flexGrow: 1 }}>{page}</div>
    <div style={{ flexGrow: 0, flexShrink: 0}}> <Footer /></div>
  </>
}

function App() {
  return (
    <>
      <AuthProvider>
        <CaseProvider>
          <GuidesProvider>
            <DocumentationProvider>
              <div
                style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column'
                }}>
                <RouterProvider router={createBrowserRouter([
                  { path: '/create-account', element: getDefaultPageLayout(<AccountCreatePage />) },
                  { path: '/create-organization-confirmation', element: getDefaultPageLayout(<OrganizationCreateConfirmationPage />) },
                  { path: '/join-organization-confirmation', element: getDefaultPageLayout(<OrganizationJoinConfirmationPage />) },
                  { path: '/join-organization-via-link/:guid', element: getDefaultPageLayout(<OrganizationJoinViaLinkPage />) },
                  { path: '/join-organization-via-link-confirmation', element: getDefaultPageLayout(<OrganizationJoinViaLinkConfirmationPage />) },
                  { path: '/admin-email-verification/:guid', element: getDefaultPageLayout(<AdminEmailVerificationPage />) },
                  { path: '/user-email-verification/:guid', element: getDefaultPageLayout(<UserEmailVerificationPage />) },
                  { path: '/email-verification-confirmation', element: getDefaultPageLayout(<CompleteRegistrationConfirmationPage />) },
                  { path: '/forgot-password', element: getDefaultPageLayout(<ForgotPasswordPage />) },
                  { path: '/reset-password/:guid', element: getDefaultPageLayout(<ResetPasswordPage />) },
                  { path: '/public', element: getDefaultPageLayout(<PublicIndexPage />) },
                  { path: '/public/:organizaitonName', element: getDefaultPageLayout(<OrganizationPublicPage />) },
                  ...getProtectedPages(),
                  { path: '*', element: getDefaultPageLayout(<NotFound />) },
                  { path: '/', element: getDefaultPageLayout(<LoginPage />) },
                  { path: '/forbidden', element: getDefaultPageLayout(<Forbidden />) },
                  { path: '/internal-error', element: getDefaultPageLayout(<InternalError />) }
                ])} />
                <Toast />
              </div>
            </DocumentationProvider>
          </GuidesProvider>
        </CaseProvider>
      </AuthProvider>
    </>
  )
}

export default App
