import SemanticIcon from './icon'

interface DeleteIconProps {
  onClick: any
  disabled?: boolean
}
export default function DeleteIcon(props: DeleteIconProps) {
  const { onClick, disabled } = props

  return <span title='delete' onClick={onClick}>
    <SemanticIcon color={'red'} name={'delete'} disabled={disabled}
    /></span>
}
