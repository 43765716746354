import SemanticModal from './modal'
import { Group, Permission } from '../../interfaces/all'
import { Checkbox } from 'semantic-ui-react'
import SemanticList from '../list'
import { useState } from 'react'
import { addOrRemoveFromList } from '../../utils/utils'
import { showToast } from '../toast'
import { updateGroup } from '../../api/groups'


interface GroupPermissionsModalProps {
  group: Group & { id: string }
  onCancel: Function
  onSaved: Function
}

export default function GroupPermissionsModal(props: GroupPermissionsModalProps) {
  const { group, onCancel, onSaved } = props
  const [newPermissions, setNewPermissions] = useState<Permission[]>(group.permissions.global)

  async function saveAndClose() {
    const permissionsToAdd = newPermissions.filter(permission => !group.permissions.global.includes(permission))
    const permissionsToRemove = group.permissions.global.filter(permission => !newPermissions.includes(permission))

    const { status } = await updateGroup({ groupId: group.id, permissionsToAdd, permissionsToRemove })

    if (status === 200) {
      showToast({ message: 'Group permissions updated' })
    }

    onSaved()
  }

  function addOrRemovePermission(permission: Permission): void {
    setNewPermissions(addOrRemoveFromList(newPermissions, permission))
  }

  const content = <SemanticList items={
    Object.values(Permission).map(permission => {
      const defaultChecked = group.permissions.global.includes(permission)

      return (
        <div className='item' key={permission}>
          <Checkbox defaultChecked={defaultChecked} label={permission}
            onChange={() => addOrRemovePermission(permission)} />
        </div>
      )
    })
  } />

  return <>
    <SemanticModal header={`Editing group: ${group.name}`}
      content={content}
      actions={[
        { color: 'grey', onClick: onCancel, text: 'Cancel' },
        { color: 'blue', onClick: saveAndClose, text: 'Save' }
      ]}
    /></>
}
