import { Table, TableRowProps } from 'semantic-ui-react'

type Cell = any
export interface SemanticTableRow {
  rowProps?: TableRowProps
  cells: Cell[]
}
interface SemanticTableProps {
  headers: string[]
  rows: SemanticTableRow[]
}
export default function SemanticTable(props: SemanticTableProps) {
  const { headers, rows } = props

  return <>
    <Table celled>
      <Table.Header>
        <Table.Row key={'header'}>
          {
            headers.map((header, index) => <Table.HeaderCell key={index}>{header}</Table.HeaderCell>)
          }
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {
          rows.map((row, index) => <Table.Row key={index} {...row.rowProps}>
            { row.cells.map((cell, index) => <Table.Cell key={index}>{ cell }</Table.Cell>)}
          </Table.Row>)
        }
      </Table.Body>
    </Table></>
}
