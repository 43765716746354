import { useEffect, useState } from 'react'
import { DropdownItemProps, Form, Grid } from 'semantic-ui-react'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import SemanticMenu from '../../components/menu'
import { SemanticButton } from '../../components/buttons/buttons'
import { useNavigate } from 'react-router-dom'
import SemanticDropdownFormField from '../../components/form/dropdown-field'
import { getOrganizations, joinOrganization } from '../../api/organizations'
import { validateEmail, validatePhoneNumber } from '../../utils/utils'
import { showErrorToast } from '../../components/toast'
import { createRegistration } from '../../api/registration'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import CreateAccountPageGuides from '../../guides/create-account'

export default function AccountCreatePage() {
  const [isNewOrganization, setIsNewOrganization] = useState(true)
  const [organizationName, setOrganizationName] = useState<string | undefined>(undefined)
  const [organizationOptions, setOrganizationOptions] = useState<DropdownItemProps[]>([])
  const [firstName, setFirstName] = useState<string | undefined>(undefined)
  const [lastName, setLastName] = useState<string | undefined>(undefined)
  const [email, setEmail] = useState<string | undefined>(undefined)
  const [emailError, setEmailError] = useState<string | undefined>(undefined)
  const [username, setUsername] = useState<string | undefined>(undefined)
  const [phoneNumber, setPhoneNumber] = useState<string | undefined>(undefined)
  const [phoneNumberError, setPhoneNumberError] = useState<string | undefined>(undefined)
  const [sendingRequest, setSendingRequest] = useState(false)
  const navigate = useNavigate()
  const { executeRecaptcha } = useGoogleReCaptcha()

  useEffect(() => {
    getOrganizations().then(response => {
      const options: DropdownItemProps[] = response.data.map(organizationName => ({
        key: organizationName,
        value: organizationName,
        text: organizationName
      }))

      setOrganizationOptions(options)
    })
  }, [])

  function onChangeEmail(value: string) {
    setEmailError(undefined)
    setEmail(value)
  }

  function onChangePhoneNumber(value: string) {
    setPhoneNumberError(undefined)
    setPhoneNumber(value)
  }

  function onChangeOrganization(organizationName: string): void {
    setOrganizationName(organizationName)
  }

  async function validateAndSubmit() {
    if (!email || !validateEmail(email)) {
      setEmailError('Email is not valid')
      return
    }

    if (isNewOrganization && (!phoneNumber || !validatePhoneNumber(phoneNumber))) {
      setPhoneNumberError('Phone number is not valid')
      return
    }

    setSendingRequest(true)

    if (isNewOrganization) {
      handleCreateRegistration()
    } else {
      handleJoinOrganization()
    }
  }

  async function handleCreateRegistration() {
    if (!executeRecaptcha) {
      console.error('Recaptcha is not ready yet!')
      return
    }

    const recaptchaToken = await executeRecaptcha('create_registration')

    const { status, data } = await createRegistration({
      organizationName: organizationName as string,
      firstName: firstName as string,
      lastName: lastName as string,
      email: email as string,
      username: username as string,
      phoneNumber: phoneNumber as string,
      recaptchaToken
    })

    if (status === 201) {
      navigate('/create-organization-confirmation')
    } else if (status === 400) {
      const errorMessage = data?.message ?? 'Organization not created'

      showErrorToast(errorMessage, 10000)
    }

    setSendingRequest(false)
  }

  async function handleJoinOrganization() {
    if (!executeRecaptcha) {
      console.error('Recaptcha is not ready yet!')
      return
    }

    const recaptchaToken = await executeRecaptcha('join_organization')

    const { status, data } = await joinOrganization({
      organizationName: organizationName as string,
      firstName: firstName as string,
      lastName: lastName as string,
      email: email as string,
      username: username as string,
      recaptchaToken
    })

    if (status === 201) {
      navigate('/join-organization-confirmation')
    } else if (status === 400) {
      const errorMessage = data?.message ?? 'Could not join organization'

      showErrorToast(errorMessage, 10000)
    }

    setSendingRequest(false)
  }

  const registrationColumn = <div style={{ maxWidth: '400px', margin: '30px auto' }}>
    <SemanticMenu items={[{
      name: 'Create new organizaiton',
      onClick: () => {
        setIsNewOrganization(true)
        setOrganizationName(undefined)
      }
    }, {
      name: 'Join an organization',
      onClick: () => {
        setIsNewOrganization(false)
        setOrganizationName(undefined)
      }
    }]} />
    <Form>
      <h3>Organization information</h3>
      {
        isNewOrganization
          ?
          <Form.Input fluid required label='Organization name' placeholder='organization name' onChange={(e) => setOrganizationName(e.target.value)} />
          :
          <SemanticDropdownFormField index={0} value={organizationName} label={'Organization name'}
            dropdownItemProps={organizationOptions}
            onChange={(_event, data) => onChangeOrganization(data.value as string)} required={true} />
      }
      <h3>{isNewOrganization ? 'Administrator information' : 'User information'}</h3>
      <Form.Input fluid required label='First name' placeholder='first name' onChange={(e) => setFirstName(e.target.value)} />
      <Form.Input fluid required label='Last name' placeholder='last name' onChange={(e) => setLastName(e.target.value)} />
      <Form.Input fluid required label='Email' placeholder='email' onChange={(e) => onChangeEmail(e.target.value)} error={emailError} />
      <Form.Input fluid required label='Username' placeholder='username' onChange={(e) => setUsername(e.target.value)} />
      <Form.Input fluid required={isNewOrganization} label='Phone number' placeholder='phone number' onChange={(e) => onChangePhoneNumber(e.target.value)} error={phoneNumberError} />
      <SemanticButton text={'Cancel'} onClick={() => navigate('/home')} color='grey' disabled={sendingRequest} />
      <SemanticButton text={isNewOrganization ? 'Create' : 'Join'} onClick={validateAndSubmit} disabled={!(organizationName && firstName && lastName && email && username) || sendingRequest} />
      {
        sendingRequest && <span style={{ marginLeft: '12px' }}>{isNewOrganization ? 'Creating organization ...' : 'Joining organization ...'}</span>
      }
    </Form>
  </div>

  const pageContent = <>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>{registrationColumn}</Grid.Column>
        <Grid.Column>
          <div>
            <br/>
            <br/>
            <br/>
            <br/>
            <br/>
            <iframe width="400" height="260"
              src="https://www.youtube.com/embed/LEddXtyxN4U"
              title="Infinite System Registration Page"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen></iframe>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<CreateAccountPageGuides />} />
}
