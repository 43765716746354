import { Case } from '../../interfaces/all'

const recentCasesKey = 'recentCases'
const casesNumberLimit = 5

const getRecentCases = (): Case[] => {
  const cases = localStorage.getItem(recentCasesKey)

  return cases ? JSON.parse(cases) : []
}

const setRecentCase = (recentCase: Case): void => {
  let recentCases = getRecentCases()

  recentCases = recentCases.filter(c => c.id !== recentCase.id)
  
  if (recentCases.length >= casesNumberLimit) {
    recentCases.pop()
  }

  recentCases.unshift(recentCase)

  localStorage.setItem(recentCasesKey, JSON.stringify(recentCases))
}

const removeRecentCase = (id: number): void => {
  const parsedCases = getRecentCases()

  const newParsedCases = parsedCases.filter(c => c.id !== id)

  localStorage.setItem(recentCasesKey, JSON.stringify(newParsedCases))
}

export {
  getRecentCases,
  setRecentCase,
  removeRecentCase
}
