import { Link, useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { Form, Grid, Image } from 'semantic-ui-react'

import { getAccessToken } from '../../api/auth'
import { showErrorToast } from '../../components/toast'
import { SemanticButton } from '../../components/buttons/buttons'
import { useAuth } from '../../hooks/auth'
import SemanticLoader from '../../components/loader'
import InfiniteSystemLogo from '../../assets/infinite_system_logo.png'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import LoginPageGuides from '../../guides/login'

function LoginPage() {
  const { onLogin, isLoggedIn } = useAuth()
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (isLoggedIn) {
      navigate('/home')
    }
  }, [isLoggedIn, navigate])

  async function submit(event: React.MouseEvent<HTMLButtonElement>) {
    event.preventDefault()

    setLoading(true)

    const loginResponse = await getAccessToken(username, password)

    if (loginResponse.status === 200) {
      const body = await loginResponse.json() as { access: string, refresh: string }

      onLogin(body.access)

      navigate('/home')
    } else if (loginResponse.status === 401) {
      showErrorToast('Invalid username/password')
    }

    setLoading(false)
  }

  const loginForm = <>
    <Form>
      <Form.Input fluid label='Username' placeholder='username' value={username} onChange={(e) => setUsername(e.target.value)} />
      <Form.Input fluid type='password' label='Password' placeholder='password' value={password} onChange={(e) => setPassword(e.target.value)} />
      <div style={{ textAlign: 'center' }}>
        <span style={{ marginRight: '8px' }}>
          <SemanticButton text={'Login'} onClick={submit} disabled={!(username && password)} type={'submit'} />
        </span>
        <span style={{ marginLeft: '8px' }}>
          <SemanticButton text={'Try it for free'} onClick={() => navigate('/create-account')} type={'button'} />
        </span>

      </div>
      <div style={{ marginTop: '16px', textAlign: 'center' }}>
        <Link to={'/forgot-password'}>Forgot password?</Link>
      </div>
    </Form>
  </>

  const pageContent = <>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>
          <div style={{ maxWidth: '300px', margin: '30px auto', textAlign: 'center' }}>
            <div style={{ marginBottom: '24px' }}>
              <Image src={InfiniteSystemLogo} alt='Infinite system logo' size='medium' style={{ padding: '4px' }} />
            </div>
            <div style={{ fontSize: 20, fontWeight: 'bold' }}>
              Improve productivity and accuracy. Collaborate without limits through shared data 
              and infinite adaptability
            </div>
            <div>
              <br/>
              <br/>
              <iframe width="400" height="260" 
                src={'https://www.youtube.com/embed/xkeYm-LLjl8?si=BF3qdToIpEOtGxNu'}
                title="Login page" 
                frameBorder='0' allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; 
                picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen>
              </iframe>
            </div>
          </div>
        </Grid.Column>
        <Grid.Column>
          <div style={{ maxWidth: '300px', margin: '30px auto' }}>
            <h2>Login</h2>
            {
              loading ? <SemanticLoader /> : loginForm
            }
          </div></Grid.Column>
      </Grid.Row>
    </Grid>
  </>
  
  return <SidebarWrapperForPage page={pageContent} sidebar={<LoginPageGuides />} />
}

export default LoginPage
