import SemanticList from './list'

interface NestedSemanticListProps {
  nestedList: {
    item: any
    nestedItems: any[]
  }[]
}
export default function NestedSemanticList(props: NestedSemanticListProps) {
  const { nestedList } = props

  const items = nestedList.map(listItem =>
    <>
      {listItem.item}
      {
        listItem.nestedItems.length > 0 ? <SemanticList items={listItem.nestedItems} /> : undefined
      }
    </>)

  return <SemanticList items={items} />
}
