import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Checkbox, Form } from 'semantic-ui-react'

import { SemanticButton } from '../../components/buttons/buttons'
import { Entity } from '../../interfaces/all'
import { getEntities, getRelatedEntities } from '../../api/entity'
import { addOrRemoveFromList, getEntityDisplayName } from '../../utils/utils'
import NestedSemanticList from '../../components/nested-list'
import { createPackage } from '../../api/packages'
import { showErrorToast, showToast } from '../../components/toast'
import ConfirmationModal from '../../components/modals/confirmation-modal'


export default function PackageCreatePage() {
  const [entities, setEntities] = useState<Entity[]>([])
  const [relatedEntities, setRelatedEntities] = useState<{ [key: number]: number[] }>()
  const [packageName, setPackageName] = useState('')
  const [selectedEntities, setSelectedEntities] = useState<number[]>([])
  const [creatingPackage, setCreatingPackage] = useState(false)
  const [displayCreatePackageConfirmationModal, setDisplayCreatePackageConfirmationModal] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    async function getEntitiesList() {
      const { data: entitiesData } = await getEntities()

      setEntities(entitiesData)

      const { data: relatedEntities } = await getRelatedEntities((entitiesData as Entity[]).map(entity => entity.id))

      setRelatedEntities(relatedEntities)
    }

    getEntitiesList()
  }, [])

  function addOrRemoveEntity(id: number): void {
    const newList = addOrRemoveFromList([...selectedEntities], id)

    setSelectedEntities(newList)
  }

  async function create() {
    setCreatingPackage(true)

    const { status, data } = await createPackage({ packageName, entities: selectedEntities })

    if (status === 201) {
      showToast({ message: 'Package created!' })
      navigate('/packages')
    } else {
      showErrorToast(data?.message ?? 'Error when creating a package')
    }

    setCreatingPackage(false)
  }

  function getRelatedEntitiesNames(entityId: number): string[] {
    let names: string[] = []

    if (!relatedEntities) return names

    return relatedEntities[entityId].map(relatedEntityId => {
      const relatedEntity = entities.find(entity => entity.id === relatedEntityId) as Entity
      return getEntityDisplayName(relatedEntity)
    })
  }

  const createPackageFormAndCheckboxes = <>
    <Form>
      <Form.Input fluid required label='Package name' placeholder='package name' value={packageName} onChange={(e) => setPackageName(e.target.value)} />
    </Form>
    <h5>Select entities:</h5>
    {
      <NestedSemanticList nestedList={entities.map(entity => {
        const defaultChecked = selectedEntities.includes(entity.id)

        return {
          item: <Checkbox defaultChecked={defaultChecked} label={getEntityDisplayName(entity)} onChange={() => addOrRemoveEntity(entity.id)} />,
          nestedItems: defaultChecked ? getRelatedEntitiesNames(entity.id) : []
        }
      })} />
    }
  </>

  return <>
    <div style={{ maxWidth: '300px', margin: '30px auto' }}>
      <h1>Create new package</h1>
      <SemanticButton text={'Return'} color='grey' onClick={() => navigate('/packages')} disabled={creatingPackage} />
      <SemanticButton text={'Create package'} color='blue' onClick={() => setDisplayCreatePackageConfirmationModal(true)} disabled={!(packageName && selectedEntities.length > 0) || creatingPackage} />
      <div style={{ margin: '12px auto' }}>
        {
          entities.length > 0 ? createPackageFormAndCheckboxes : 'No entities available'
        }
      </div>
    </div>
    {
      displayCreatePackageConfirmationModal &&
        <ConfirmationModal header={`Package: ${ packageName }`} content={'Are you sure you want to create this package?'}
          onCancel={() => setDisplayCreatePackageConfirmationModal(false)} onSaved={() => create()}/>
    }
  </>
}
