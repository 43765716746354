import { Button, ButtonProps, Icon, IconProps } from 'semantic-ui-react'

interface SemanticButtonIconProps {
  iconProps?: IconProps
  buttonProps?: ButtonProps
  text: string
  disabled?: boolean
}
export default function SemanticButtonIcon(props: SemanticButtonIconProps) {
  const { buttonProps, disabled = false, iconProps, text } = props

  const allButtonProps = {
    disabled,
    ...buttonProps
  }

  const allIconProps = {
    disabled,
    ...iconProps
  }

  return <>
    <Button icon {...allButtonProps}>
      { text }
      <Icon {...allIconProps} />
    </Button>
  </>
}
