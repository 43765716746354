import { AxiosResponse } from 'axios'
import { request } from './common'

const groupsPath = 'groups'

export const getGroup = async(id: string): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: `${groupsPath}/${id}`
  })
}

export const getGroups = async (): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: groupsPath
  })
}

export const createGroup = async(name: string): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: groupsPath,
    body: {
      name
    }
  })
}

interface UpdateGroupProps {
  groupId: string,
  newUsersIds?: number[],
  removeUsersIds?: number[]
  permissionsToAdd?: string[],
  permissionsToRemove?: string[],
  newEntitiesIds?: number[],
  removeEntitiesIds?: number[]
}
export const updateGroup = async(props: UpdateGroupProps): Promise<AxiosResponse> => {
  const {
    groupId,
    newUsersIds = [],
    removeUsersIds = [],
    permissionsToAdd = [],
    permissionsToRemove = [],
    newEntitiesIds = [],
    removeEntitiesIds = []
  } = props

  return await request({
    method: 'PUT',
    path: `${groupsPath}/${ groupId }`,
    body: {
      new_users_ids: newUsersIds,
      remove_users_ids: removeUsersIds,
      new_permissions: permissionsToAdd,
      remove_permissions: permissionsToRemove,
      new_entities_ids: newEntitiesIds,
      remove_entities_ids: removeEntitiesIds
    }
  })
}
