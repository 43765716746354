import DocumentationTooltip, { DocumentationTooltipProps} from './documentation-tooltip'

interface TitleWithDocumentationProps {
  title: string
  documentationTooltipProps: DocumentationTooltipProps
}
export default function TitleWithDocumentation({ title, documentationTooltipProps }: TitleWithDocumentationProps) {
  return <div style={{ marginBottom: '8px'}}>
    <span style={{ fontSize: '24px', fontWeight: 600, marginRight: '8px' }}>
      { title }
    </span>
    <span>
      <DocumentationTooltip {...documentationTooltipProps} />
    </span>

  </div>
}
