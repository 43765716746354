import SemanticIcon from './icon'

interface CancelIconProps {
  onClick: any
  disabled?: boolean
}
export default function CancelIcon(props: CancelIconProps) {
  const { onClick, disabled } = props

  return <span title='cancel' onClick={onClick}>
    <SemanticIcon color={'grey'} name={'arrow left'} disabled={disabled}
    /></span>
}
