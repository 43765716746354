import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Workflow } from '../../../interfaces/api/workflows'
import { getWorkflow } from '../../../api/workflows'
import { ActionType } from '../../../interfaces/all'
import { getActionTypes } from '../../../api/actions/types'
import SemanticTable from '../../../components/table'
import { SemanticButton } from '../../../components/buttons/buttons'
import SemanticLoader from '../../../components/loader'


export default function WorkflowPage() {
  const { workflowId } = useParams()
  const [workflow, setWorkflow] = useState<Workflow | undefined>()
  const [actionTypes, setActionTypes] = useState<ActionType[]>([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    async function getData() {
      if (workflowId) {
        setLoading(true)

        const [workflowResponse, actionTypesResponse] = await Promise.all([
          getWorkflow(workflowId),
          getActionTypes()
        ])

        setWorkflow(workflowResponse.data)
        setActionTypes(actionTypesResponse.data)
        setLoading(false)
      }
    }

    getData()
  }, [workflowId])

  function getActionTypeName(id: number): string {
    return actionTypes.find(actionType => actionType.id === id)?.name ?? ''
  }

  const rows = workflow?.action_types
    .sort((a, b) => a.order_sequence - b.order_sequence)
    ?.map(actionType => ({
      cells: [
        getActionTypeName(actionType.id),
        actionType.order_sequence.toString()
      ]
    })) ?? []

  const workflowTable = <SemanticTable headers={['Action Type', 'Order']} rows={rows} />

  return <>
    <div style={{ maxWidth: '500px', margin: '30px auto' }}>
      <h4>{workflow?.name ?? 'Loading workflow ...'}</h4>
      <div style={{ marginTop: '8px' }}>
        <SemanticButton text={'Return'} color='grey' onClick={() => navigate('/admin/workflows')} />
      </div>
      {
        loading ? <SemanticLoader /> : workflowTable
      }
    </div>
  </>
}
