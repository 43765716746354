import { BuildLeftAlignedDiv, BuildList, BuildTitle } from './utils/utils'

export default function TransfersGuides() {
  return <>
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Transfers'/>
      <BuildLeftAlignedDiv content={'You can transfer an item or multiple items from the transfer tab or do it directly from the "Evidence". Here is how our transfer is different from typical LIMS.'}/>
      <BuildList items={[
        'You can transfer from anyone or any location to anyone or any location',
        'You can transfer to someone without them putting their pin in right away, but that will result in transfer not being completed and will result in broken chain of custody or the person can reject the transfer.',
        'The log will show the lag time between transfer being made and the receiver accepting it.'
      ]}/>
    </div>
  </>
}
