import { DropdownItemProps, DropdownProps, Form } from 'semantic-ui-react'

interface SemanticDropdownFormFieldProps {
  index: number
  label: string
  value: DropdownItemProps['value']
  dropdownItemProps: DropdownItemProps[]
  required?: boolean
  error?: string
  onChange: (event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) => void
  disabled?: boolean
  placeholder?: string
}
export default function SemanticDropdownFormField(props: SemanticDropdownFormFieldProps) {
  const { label, dropdownItemProps, index, value, error, required, onChange, disabled = false, placeholder = undefined } = props

  return <>
    <Form.Field required={required} key={index}>
      <label>{label}</label>

      <Form.Dropdown search fluid selection error={error} value={value}
        onChange={onChange}
        options={dropdownItemProps}
        disabled={disabled}
        placeholder={placeholder}
      />
    </Form.Field>
  </>
}

export function getDropdownItemPropsFromStrings(items: string[]): DropdownItemProps[] {
  return items.map(item => ({ key: item, value: item, text: item }))
}
