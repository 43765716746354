import { useEffect, useState } from 'react'

import { SemanticButton } from '../../components/buttons/buttons'
import SemanticList from '../../components/list'
import { getDynamicEntities } from '../../api/entity'
import { DynamicEntity } from '../../interfaces/all'
import { useNavigate } from 'react-router'
import SemanticInput from '../../components/ag-grid/input'
import SemanticLoader from '../../components/loader'
import TitleWithDocumentation from '../../components/title-with-documentation'
import { useDocumentation } from '../../hooks/documentation'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import DynamicGuides from '../../guides/dynamic'
import { setRecentActivity } from '../../utils/localStorage/recent-activities'
import { Grid } from 'semantic-ui-react'


function DynamicsIndexPage() {
  const [dynamicEntities, setDynamicEntities] = useState<DynamicEntity[]>()
  const [loading, setLoading] = useState(true)
  const [textFilter, setTextFilter] = useState('')
  const navigate = useNavigate()
  const documentation = useDocumentation()

  useEffect(() => {
    getDynamicEntities().then(response => {
      setDynamicEntities(response)
      setLoading(false)
    })
  }, [])

  const displayedEntities = dynamicEntities?.filter(entity => {
    if (!textFilter) return true

    return entity.name.toLowerCase().includes(textFilter.toLowerCase())
  })

  const entitiesList = displayedEntities && <SemanticList items={displayedEntities.map(dynamicEntity => <>
    <SemanticButton size='mini' text='view' color='blue' onClick={() => {
      setRecentActivity({ id: dynamicEntity.id, name: dynamicEntity.name })
      navigate(`/entity/${dynamicEntity.id}`)
    }} />
    {dynamicEntity.name}
  </>)} />

  const loader = <div style={{ maxWidth: '300px', margin: '30px auto' }}>
    <SemanticLoader />
  </div>

  const pageContent = <>
    <div style={{ maxWidth: '600px', margin: '4px auto' }}>
      <TitleWithDocumentation title={'Dynamic Data Entry Hub'} documentationTooltipProps={{ text: documentation.dynamichub }} />

      <Grid columns={2} divided>
        <Grid.Row>
          <Grid.Column>
            {
              loading ? loader : entitiesList
            }
          </Grid.Column>
          <Grid.Column>
            <div style={{ marginTop: '4px'}}>
              <h4>Search</h4>
              <SemanticInput value={textFilter} onChange={(_e, data) => setTextFilter(data.value)} />
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<DynamicGuides />} />
}

export default DynamicsIndexPage
