import { AxiosResponse } from 'axios'
import { Case, Column, GenericGridResponse } from '../../interfaces/all'
import { request } from '../common'
import { CaseItem, CaseNumbers } from '../../interfaces/api/cases'


const casePath = 'cases'

export const getCases = async (): Promise<GenericGridResponse<CaseItem[]>> => {
  const { data } = await request({
    method: 'GET',
    path: casePath
  })

  return data as GenericGridResponse<CaseItem[]>
}

export const getCase = async (caseId: string): Promise<GenericGridResponse<CaseItem[]>> => {
  const { data } = await request({
    method: 'GET',
    path: `${casePath}/${caseId}`
  })

  return data as GenericGridResponse<CaseItem[]>
}

// TODO - there should be an API for that, to be used on create case page
// {...}/cases/columns
export const getCaseColumns = async (): Promise<Column[]> => {
  const { meta } = await getCases()

  return meta.columns
}

export const getCaseNumbers = async (): Promise<Case[]> => {
  const { data } = await request({
    method: 'GET',
    path: `${casePath}/numbers`
  })

  return (data as CaseNumbers).map(item => ({ id: item.id, caseNumber: item.case_number })) ?? []
}

export const createCase = async (body: object): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: casePath,
    body
  })
}

export const updateCase = async (body: object, caseId: string): Promise<AxiosResponse> => {
  return await request({
    path: `${casePath}/${caseId}`,
    method: 'PUT',
    body
  })
}

export const deleteCase = async (caseId: number): Promise<AxiosResponse> => {
  return await request({
    path: `${casePath}/${caseId}`,
    method: 'DELETE'
  })
}
