import { useRef } from 'react'

import { SemanticButton } from './buttons/buttons'
import { showErrorToast } from './toast'

interface UploadFileHandlerProps {
  buttonText: string
  buttonDisabled?: boolean
  maxFileSize?: number
  callback: (file: File) => void
}
export default function UploadFileHandler(props: UploadFileHandlerProps) {
  const {
    buttonText,
    buttonDisabled = false,
    maxFileSize = 10 * 1000 * 1000, // defaults to 10MB
    callback
  } = props
  const hiddenFileInput = useRef(null)

  const handleClick = () => {
    if (hiddenFileInput !== null && hiddenFileInput.current !== null) {
      // @ts-ignore
      hiddenFileInput.current.click()
    }
  }

  function handleOnChange(event: React.ChangeEvent<HTMLInputElement>) {
    const file = event?.target?.files?.[0]

    if(!file) {
      return // file not selected
    }

    if(file.size > maxFileSize) {
      showErrorToast('File is too large')
      return
    }

    callback(file)
  }

  return (
    <>
      <SemanticButton onClick={handleClick} text={buttonText} disabled={buttonDisabled} />
      <input type='file' accept=".xlsx"
        onChange={handleOnChange}
        ref={hiddenFileInput} style={{ display: 'none' }} // Make the file input element invisible
      />
    </>
  )
}
