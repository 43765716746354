import { AxiosResponse } from 'axios'

import { request } from './common'
import { FormNewField } from '../interfaces/api/worksheet'

const excelImportsPath = 'excel-imports'


export const importCasesFromExcel = async (file: File, entityId: string, worksheetName: string, newWorksheetFields: FormNewField[], sourceDestinationMap: Record<string, string>): Promise<AxiosResponse> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('entity_id', entityId)
  formData.append('worksheet_name', worksheetName)
  formData.append('new_worksheet_fields', JSON.stringify(newWorksheetFields))
  formData.append('source_destination_map', JSON.stringify(sourceDestinationMap))

  return await request({
    method: 'POST',
    path: `${ excelImportsPath }/cases`,
    body: formData
  })
}

export const importEvidencesFromExcel = async (file: File, entityId: string, worksheetName: string, newWorksheetFields: FormNewField[], sourceDestinationMap: Record<string, string>): Promise<AxiosResponse> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('entity_id', entityId)
  formData.append('worksheet_name', worksheetName)
  formData.append('new_worksheet_fields', JSON.stringify(newWorksheetFields))
  formData.append('source_destination_map', JSON.stringify(sourceDestinationMap))

  return await request({
    method: 'POST',
    path: `${ excelImportsPath }/evidences`,
    body: formData
  })
}

export const importServicesFromExcel = async (file: File, entityId: string, worksheetName: string, newWorksheetFields: FormNewField[], sourceDestinationMap: Record<string, string>): Promise<AxiosResponse> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('entity_id', entityId)
  formData.append('worksheet_name', worksheetName)
  formData.append('new_worksheet_fields', JSON.stringify(newWorksheetFields))
  formData.append('source_destination_map', JSON.stringify(sourceDestinationMap))

  return await request({
    method: 'POST',
    path: `${ excelImportsPath }/services`,
    body: formData
  })
}

export const importDynamicFromExcel = async (file: File, entityId: string, worksheetName: string, newWorksheetFields: FormNewField[], sourceDestinationMap: Record<string, string>): Promise<AxiosResponse> => {
  const formData = new FormData()
  formData.append('file', file)
  formData.append('entity_id', entityId)
  formData.append('worksheet_name', worksheetName)
  formData.append('new_worksheet_fields', JSON.stringify(newWorksheetFields))
  formData.append('source_destination_map', JSON.stringify(sourceDestinationMap))

  return await request({
    method: 'POST',
    path: `${ excelImportsPath }/dynamic`,
    body: formData
  })
}
