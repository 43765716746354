import { Form } from 'semantic-ui-react'

interface SemanticTextFormFieldProps {
  index: number
  value: string
  label: string
  placeholder: string
  required?: boolean
  error?: string
  disabled?: boolean
  type?: string
  autoFocus?: boolean
  readonly?: boolean
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onBlur?: () => void
  onTab?: () => void
}
export default function SemanticTextFormField(props: SemanticTextFormFieldProps) {
  const { label, placeholder, index, value, error, required, disabled = false, type, autoFocus = false, readonly = false, onChange, onBlur, onTab } = props

  function handleOnKeyDown (event: React.KeyboardEvent<HTMLInputElement>) {
    if(onTab !== undefined && event.key === 'Tab') {
      event.preventDefault()
      onTab()
    }
  }

  return <>
    <Form.Input required={required} key={index} fluid value={value} type={type} readOnly={readonly}
      label={label} placeholder={placeholder ?? ''} error={error} autoFocus={autoFocus}
      onChange={onChange} disabled={disabled} onBlur={onBlur} onKeyDown={handleOnKeyDown}
    /></>
}
