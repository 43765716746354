import { useEffect, useState } from 'react'

import { createAction, deleteAction, getActionsByService, updateAction, updateActionOrder } from '../api/actions/actions'
import { DataType, GenericGetResponse } from '../interfaces/all'
import { showErrorToast, showToast } from './toast'
import DynamicGrid from './ag-grid/dynamic-grid'
import AddWorkflowModal from './modals/add-workflow-modal'
import { HttpStatusCode } from '../interfaces/status-codes'


interface ActionsComponentProps {
  serviceId: string
  canDelete?: boolean
  canEdit?: boolean
}
export default function ActionsComponent(props: ActionsComponentProps) {
  const { serviceId, canDelete, canEdit } = props
  const [actions, setActions] = useState<GenericGetResponse | undefined>()
  const [addingWorkflow, setAddingWorkflow] = useState(false)

  useEffect(() => {
    if (serviceId) {
      getActionsByService(serviceId).then(response => setActions(response))
    }
  }, [serviceId])

  async function reloadGrid() {
    setActions(undefined)

    getActionsByService(serviceId as string).then(response => setActions(response))
  }

  async function deleteRow(actionId: number) {
    const { status } = await deleteAction(actionId as unknown as string)

    if (status === 204) {
      showToast({ message: 'Action deleted' })
    }

    reloadGrid()
  }

  async function createRow(rowData: any) {
    const { status, data } = await createAction({ ...rowData, 'service_id': serviceId })

    if (status === 201) {
      showToast({ message: 'Action created' })
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Action not created')
    }

    reloadGrid()
  }

  async function updateRow(data: any) {
    const { status } = await updateAction(data.id, data)

    if (status === 200) {
      showToast({ message: 'Action updated' })
    }

    reloadGrid()
  }

  async function dragEndCallback(actionId: number, toIndex: number) {    
    const listWithoutMovedItem: number[] = []
    actions?.raw_data.forEach(x => {
      if(x.id !== actionId) {
        listWithoutMovedItem.push(x.id)
      }
    })

    const newOrderedList = [
      ...listWithoutMovedItem.slice(0, toIndex),
      actionId,
      ...listWithoutMovedItem.slice(toIndex)
    ]

    
    const { status, data } = await updateActionOrder(newOrderedList)

    if(status === HttpStatusCode.OK) {
      showToast({ message: 'Action moved'})
      reloadGrid()
    } else {
      showErrorToast(data?.message ?? 'Failed to moved action')
    }
  }

  return (
    <>
      <div style={{ padding: '8px', height: '100%', display: 'flex', flexDirection: 'column' }}>
        {
          actions && actions.meta && actions.raw_data &&
          <DynamicGrid dataType={DataType.ACTION}
            rows={actions.raw_data}
            columns={actions.meta.columns}
            onDelete={(actionId) => deleteRow(actionId)}
            onCancel={() => reloadGrid()}
            onCreate={(data) => createRow(data)}
            onUpdate={(data) => updateRow(data)}
            onAddWorkflowClick={() => setAddingWorkflow(true)}
            dragActionEndCallback={dragEndCallback}
            canDelete={canDelete}
            canEdit={canEdit} />
        }
      </div>
      {
        addingWorkflow && <AddWorkflowModal
          serviceId={serviceId}
          onCancel={() => setAddingWorkflow(false)}
          onSaved={() => {
            setAddingWorkflow(false)
            reloadGrid()
          }} />
      }
    </>
  )
}
