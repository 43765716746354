import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { getEvidence } from '../../api/evidences/evidences'

import { AnimatedReturnButton } from '../../components/buttons/buttons'
import { getColumnsDefs } from '../../components/ag-grid/utils'
import AgGrid from '../../components/ag-grid/ag-grid'
import { GenericGetResponse } from '../../interfaces/all'

function EvidencePage() {
  const { evidenceId } = useParams()
  const [evidences, setEvidences] = useState<GenericGetResponse | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    getEvidence(evidenceId as string).then(response => setEvidences(response))
  }, [evidenceId])

  const columnDefs: any = [
    {
      headerName: 'Return',
      width: 100,
      cellRenderer: AnimatedReturnButton,
      onCellClicked: () => navigate(-1),
    },
    ...getColumnsDefs({ columns: evidences?.meta.columns, editable: false })
  ]
  const rowData = evidences?.raw_data

  return (
    <AgGrid columnDefs={columnDefs} rowData={rowData} />
  )
}

export default EvidencePage
