import { AxiosResponse } from 'axios'

import { request } from './common'

const barcodesPath = 'barcodes'


export const getBarcodes = async (): Promise<AxiosResponse> => {
  return request({
    method: 'GET',
    path: barcodesPath
  })
}
