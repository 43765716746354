import { useEffect, useState } from 'react'

import { getQuicksightEmbededUrl } from '../../api/quicksight'
import { HttpStatusCode } from '../../interfaces/status-codes'
import { showErrorToast } from '../../components/toast'
import QuicksightIFrame from '../../components/quicksight-iframe'


export default function QuicksightIndexPage() {
  const [url, setUrl] = useState<string | undefined>(undefined)
  const [message, setMessage] = useState('Loading dashboard ...')

  useEffect(() => {
    getQuicksightEmbededUrl().then(res => {
      if (res.status === HttpStatusCode.OK) {
        setUrl(res.data.url)
      } else {
        setMessage('No access to Quicksight')
        showErrorToast(res.data?.message ?? 'No access to Quicksight')
      }
    })
  }, [])

  return <>
    <div style={{ textAlign: 'center', height: '100%', width: '100%' }}>
      {
        !url && <><div style={{ padding: '40px'}}>{ message }</div></>
      }
      <QuicksightIFrame src={url} />
    </div></>
}
