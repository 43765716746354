import { DropdownItemProps } from 'semantic-ui-react'
import { useState } from 'react'

import { User } from '../../interfaces/all'
import { Barcode, BarcodeType } from '../../interfaces/api/barcode'
import { MenuOptions, SelectedType } from '../../interfaces/transfer'
import { Location } from '../../interfaces/api/location'
import SemanticMenu from '../../components/menu'
import BarcodeForm from '../../components/form/barcode-form'
import SemanticDropdownFormField from '../../components/form/dropdown-field'


interface UserOrLocationSelectorProps {
  defaultBarcode: string
  defaultId?: number
  defaultType?: SelectedType
  allBarcodes: Barcode[]
  allUsers: User[]
  allLocations: Location[]
  userOptions: DropdownItemProps[]
  locationOptions: DropdownItemProps[]
  updateUserOrLocation: (type: SelectedType | undefined, id: number | undefined, barcode: string) => void
}
export default function UserOrLocationSelector(props: UserOrLocationSelectorProps) {
  const {
    allBarcodes,
    allLocations,
    allUsers,
    defaultBarcode,
    defaultType,
    defaultId,
    userOptions,
    locationOptions,
    updateUserOrLocation
  } = props

  const [tab, setTab] = useState<MenuOptions>(getDefaultMenuOption())
  const [barcode, setBarcode] = useState('')
  const [error, setError] = useState<string | undefined>()

  function getDefaultMenuOption(): MenuOptions {
    if (defaultBarcode === '') {
      if (defaultType === SelectedType.USER) return MenuOptions.USER

      if (defaultType === SelectedType.LOCATION) return MenuOptions.LOCATION
    }

    return MenuOptions.BARCODE
  }

  function getFromBarcode(barcode: string): { id: number, type: BarcodeType } | undefined {
    if (barcode === '') {
      return
    }

    const barcodeObject = allBarcodes.find(x => x.barcode === barcode)

    if (barcodeObject) {
      let id
      if (barcodeObject?.type === BarcodeType.USER) {
        id = allUsers.find(user => user.barcode === barcodeObject.id)?.id
      } else if (barcodeObject?.type === BarcodeType.LOCATION) {
        id = allLocations.find(location => location.barcode === barcode)?.id
      } else {
        setError('Barcode not found for user nor location')
        return
      }

      return {
        id: id as number,
        type: barcodeObject.type
      }
    } else {
      setError('Barcode not found')
      return
    }
  }

  function getAndSetUserOrLocationFromBarcode(barcode: string) {
    const findFromBarcodeResponse = getFromBarcode(barcode)

    if (findFromBarcodeResponse) {
      if (findFromBarcodeResponse.type === BarcodeType.USER) {
        updateUserOrLocation(SelectedType.USER, findFromBarcodeResponse.id, barcode)
        setError(undefined)
      } else if (findFromBarcodeResponse.type === BarcodeType.LOCATION) {
        updateUserOrLocation(SelectedType.LOCATION, findFromBarcodeResponse.id, barcode)
        setError(undefined)
      } else {
        setError('Invalid barcode for user or location')
      }
    } else {
      setError('Invalid barcode for user or location')
    }
  }

  const displayText = (type: SelectedType | undefined, id: number | undefined): string | undefined => {
    if (type === SelectedType.USER) {
      const user = allUsers.find(user => user.id === id)

      if (user) return `User: ${user.username}`
    } else if(type === SelectedType.LOCATION) {
      const location = allLocations.find(location => location.id === id)

      if (location) return `Location: ${location.name}`
    }

    return
  }

  const headers = [{
    name: MenuOptions.BARCODE,
    onClick: () => {
      setTab(MenuOptions.BARCODE)
      setBarcode('')
      setError(undefined)
      updateUserOrLocation(undefined, undefined, '')
    }
  }, {
    name: MenuOptions.USER,
    onClick: () => {
      setTab(MenuOptions.USER)
      setBarcode('')
      setError(undefined)
      updateUserOrLocation(undefined, undefined, '')
    }
  }, {
    name: MenuOptions.LOCATION,
    onClick: () => {
      setTab(MenuOptions.LOCATION)
      setBarcode('')
      setError(undefined)
      updateUserOrLocation(undefined, undefined, '')
    }
  }]

  return <>
    <SemanticMenu items={headers} compact={true} />
    <div style={{ maxWidth: '400px', margin: '12px auto' }}>
      {
        tab === MenuOptions.BARCODE && <>
          <BarcodeForm index={3} value={barcode} label='' error={error}
            onChangeCallback={(event) => {
              setError(undefined)
              setBarcode(event.target.value)
            }}
            onSubmitCallback={() => getAndSetUserOrLocationFromBarcode(barcode)}
            submitOnBlur={true} />
          {
            displayText(defaultType, defaultId)
          }
        </>
      }
      {
        tab === MenuOptions.USER &&
        <SemanticDropdownFormField index={4} label={''} value={defaultId} placeholder='select user ...'
          dropdownItemProps={userOptions} onChange={(_, data) => {
            updateUserOrLocation(SelectedType.USER, data.value as number, '')
          }} />
      }
      {
        tab === MenuOptions.LOCATION &&
        <SemanticDropdownFormField index={5} label={''} value={defaultId} placeholder='select location ...'
          dropdownItemProps={locationOptions} onChange={(_, data) => {
            updateUserOrLocation(SelectedType.LOCATION, data.value as number, '')
          }} />
      }
    </div>
  </>
}
