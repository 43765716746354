import { AxiosResponse } from 'axios'
import { request } from './common'
import { CreateWorkflowForm, Workflow } from '../interfaces/api/workflows'

const workflowsPath = 'workflows'

export const getWorkflows = async(): Promise<AxiosResponse<Workflow[]>> => {
  return await request({
    method: 'GET',
    path: workflowsPath
  })
}

export const getWorkflow = async(id: string): Promise<AxiosResponse<Workflow>> => {
  return await request({
    method: 'GET',
    path: `${workflowsPath}/${ id }`
  })
}

export const createWorkflow = async(body: CreateWorkflowForm): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: workflowsPath,
    body
  })
}
