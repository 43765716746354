import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { createEvidence, getEvidenceColumns } from '../../api/evidences/evidences'
import { Column } from '../../interfaces/all'
import { showToast } from '../../components/toast'
import SemanticForm, { getFormFieldsFromColumns } from '../../components/form/form'


function EvidencesCreatePage() {
  const [columns, setColumns] = useState<Column[]>([])
  const { caseId } = useParams()
  const navigate = useNavigate()

  useEffect(() => {
    getEvidenceColumns(caseId as string).then(response => setColumns(response))
  }, [caseId])

  async function create(body: any) {
    const { status } = await createEvidence({ ...body, caseId })

    if (status === 201) {
      showToast({ message: 'Evidence created' })
    }

    navigate(`/cases/${caseId}/evidences`)
  }

  const formFields = getFormFieldsFromColumns(columns)

  return <div style={{ maxWidth: '300px', margin: '30px auto' }}>
    <SemanticForm title={'Create evidence'} formFields={formFields}
      onCancel={() => navigate(`/cases/${caseId}/evidences`)} onSubmit={(data: any) => create(data)} />
  </div>


}

export default EvidencesCreatePage
