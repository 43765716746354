import { ReactNode } from 'react'
import { Menu, Sidebar, SidebarPushable, SidebarPusher } from 'semantic-ui-react'

import { useGuides } from '../hooks/guides'

export default function SidebarWrapperForPage({ page, sidebar }: { page: ReactNode, sidebar: ReactNode }) {
  const { displayGuides, setDisplayGuides } = useGuides()

  return <SidebarPushable style={{ display: 'flex'}}>
    <Sidebar
      as={Menu}
      animation='overlay'
      icon='labeled'
      onHide={() => setDisplayGuides(false)}
      vertical
      visible={displayGuides}
      width={'very wide'}
      direction='right'
    >
      {sidebar}
    </Sidebar>

    <SidebarPusher style={{ flexGrow: 1 }}>
      {page}
    </SidebarPusher>
  </SidebarPushable>
}
