import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function Forbidden() {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => navigate('/'), 3000)
  }, [navigate])

  return (
    <>
      <div style={{ textAlign: 'center', paddingTop: '50px' }}>
        <h2>Operation forbidden</h2>
        <h3>Your current groups and permissions does not allow this operation</h3>
      </div>
    </>
  )
}

export default Forbidden
