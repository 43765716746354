import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { Location } from '../../../interfaces/api/location'
import { getLocation, updateLocation } from '../../../api/locations'
import SemanticLoader from '../../../components/loader'
import SemanticForm, { FormField } from '../../../components/form/form'
import { ColumnType } from '../../../interfaces/all'
import { showErrorToast, showToast } from '../../../components/toast'

export default function LocationEditPage() {
  const { locationId } = useParams()
  const [location, setLocation] = useState<Location | undefined>()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (locationId) {
      getLocation(locationId).then(response => {
        setLocation(response.data)
        setLoading(false)
      })
    }

  }, [locationId])

  async function onSave(input: any) { 
    setLoading(true)

    const { status, data } = await updateLocation(locationId as string, { name: input['name'], barcode: input['barcode'] })

    if (status === 200) {
      showToast({ message: 'Location updated!' })
    } else {
      showErrorToast(data.message ?? 'Location not updated')
    }

    navigate('/admin/locations')
  }

  const formFields: FormField[] = [
    {
      key: 'name',
      type: ColumnType.TEXT,
      label: 'name',
      required: true,
      placeholder: 'name ...',
      defaultValue: location?.name
    },
    {
      key: 'barcode',
      type: ColumnType.TEXT,
      label: 'barcode',
      required: true,
      placeholder: 'barcode ...',
      defaultValue: location?.barcode
    }
  ]

  return <>
    <div style={{ maxWidth: '300px', margin: '30px auto' }}>
      {
        loading ? <SemanticLoader /> : <>
          <SemanticForm title='Edit location'
            formFields={formFields}
            onCancel={() => navigate('/admin/locations')}
            onSubmit={(data: any) => onSave(data)}
          />
        </>
      }
    </div></>
}
