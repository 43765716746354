import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { createExternalReport, deleteExternalReport, getExternalReports, updateExternalReport } from '../../api/external-reports'
import { ExternalReport } from '../../interfaces/api/external-report'
import AgGrid from '../../components/ag-grid/ag-grid'
import { getColumnsDefs } from '../../components/ag-grid/utils'
import { ColumnType } from '../../interfaces/all'
import { CellClickedEvent, CellEditingStartedEvent } from 'ag-grid-community'
import SemanticIcon from '../../components/icons/icon'
import { SemanticButton } from '../../components/buttons/buttons'
import SemanticLoader from '../../components/loader'
import { EMPTY_KEY } from '../../components/form/form'
import { showErrorToast, showToast } from '../../components/toast'
import SaveIcon from '../../components/icons/save'
import CancelIcon from '../../components/icons/cancel'
import EditIcon from '../../components/icons/edit'
import ConfirmationModal from '../../components/modals/confirmation-modal'
import DeleteIcon from '../../components/icons/delete'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import ReportsGuides from '../../guides/reports'


export default function ReportsPage() {
  const [externalReports, setExternalReports] = useState<ExternalReport[]>([])
  const [onEditModeRowId, setOnEditModeRowId] = useState(0)
  const [reportToBeDeleted, setReportToBeDeleted] = useState<ExternalReport | undefined>()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(true)
  const isEditingRow = onEditModeRowId !== 0

  useEffect(() => {
    getExternalReports().then(res => {
      setExternalReports(res.data as ExternalReport[])
      setLoading(false)
    })
  }, [])

  async function refreshGrid() {
    setLoading(true)

    getExternalReports().then(res => {
      setExternalReports(res.data as ExternalReport[])
      setLoading(false)
      setOnEditModeRowId(0)
    })
  }

  function addRow() {
    const newRowId = -1

    let temp = externalReports.length > 0 ? [...externalReports] : []
    temp.push({ id: newRowId, name: '', link: '' })

    setExternalReports(temp)

    setOnEditModeRowId(newRowId)
  }

  function saveRow(event: CellClickedEvent) {
    const { data } = event

    if (data.id.toString() === EMPTY_KEY) {
      delete data.id
      create(data)
    } else {
      update(data)
    }
  }

  async function create(body: any) {
    const { status, data } = await createExternalReport(body)

    if (status === 201) {
      showToast({ message: 'External report created' })
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'External report not created')
    }

    refreshGrid()
  }

  async function update(body: any) {
    const { status, data } = await updateExternalReport(body)

    if (status === 200) {
      showToast({ message: 'External report updated' })

      refreshGrid()
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'External report not updated')
    }
  }

  async function deleteRow(id: number) {
    const { status } = await deleteExternalReport(id)

    if (status === 204) {
      showToast({ message: 'External report deleted!' })
    }

    refreshGrid()
  }

  function openExternalReport(id: number) {
    navigate(`/reports/${id}`)
  }

  const deleteColumnDef = {
    pinned: 'left',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const disabled = !(onEditModeRowId === 0)

      return <DeleteIcon onClick={() => disabled ? undefined : setReportToBeDeleted(event.data)} disabled={disabled} />
    }
  }
  const editColumnDef = {
    pinned: 'left',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const enabled = onEditModeRowId === 0

      return <EditIcon onClick={() => enabled ? setOnEditModeRowId(event.data.id) : undefined}
        disabled={!enabled} />
    }
  }
  const cancelColumnDef = {
    pinned: 'left',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const enabled = event.data['id'] === onEditModeRowId

      return <CancelIcon onClick={() => enabled ? refreshGrid() : undefined} disabled={!enabled} />
    }
  }
  const saveColumnDef = {
    pinned: 'left',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const enabled = event.data['id'] === onEditModeRowId

      return <SaveIcon onClick={() => enabled ? saveRow(event) : undefined} disabled={!enabled} />
    },
  }
  const openLinkColumnDef = {
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const disabled = !(onEditModeRowId === 0)

      return <span title='link' onClick={() => disabled ? undefined : openExternalReport(event.data.id)}>
        <SemanticIcon color={'black'} name={'external alternate'} disabled={disabled}
        /></span>
    }
  }

  let columnDefs: any[] = []

  if(!isEditingRow) {
    columnDefs.push(deleteColumnDef)
    columnDefs.push(editColumnDef)
  } else {
    columnDefs.push(cancelColumnDef)
    columnDefs.push(saveColumnDef)
  }

  columnDefs.push(
    ...getColumnsDefs({
      columns: [{
        type: ColumnType.TEXT,
        name: 'name'
      }, {
        type: ColumnType.TEXT,
        name: 'link'
      }],
      editable: true
    })
  )

  if(!isEditingRow) {
    columnDefs.push(openLinkColumnDef)
  }

  const gridOptions = {
    onCellEditingStarted: (event: CellEditingStartedEvent) => setOnEditModeRowId(event.data.id)
  }

  const pageContent =
    <>
      <div style={{ paddingTop: '8px', height: '100%' }}>
        <div style={{ textAlign: 'center' }}>
          <h5>Reports</h5>
        </div>
        <div style={{ margin: '12px', height: '100%', display: 'flex', flexDirection: 'column'}}>
          <div style={{ marginBottom: '4px' }}>
            <span style={{ marginBottom: '4px' }}>
              <SemanticButton disabled={!!onEditModeRowId || loading} text={'Add a row'} onClick={addRow} />
            </span>
          </div>
          {
            loading ?
              <SemanticLoader /> :
              <div style={{ flex: 1}}>
                <AgGrid columnDefs={columnDefs} rowData={externalReports} gridOptions={gridOptions} />
              </div>
          }
        </div>

      </div>
      {
        reportToBeDeleted &&
        <ConfirmationModal header={reportToBeDeleted.name}
          content={'Are you sure you want to delete this report link?'}
          onCancel={() => setReportToBeDeleted(undefined)}
          onSaved={() => {
            setReportToBeDeleted(undefined)
            deleteRow(reportToBeDeleted.id)
          }} />
      }
    </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<ReportsGuides />} />
}
