import { AxiosResponse } from 'axios'

import { request } from './common'

const transferPath = 'transfers'

interface TransferFromAndToProps {
  from: {
    id: number,
    type: string
  },
  to: {
    id: number,
    type: string
  }
  pin: string
}
type CreateTransferProps = TransferFromAndToProps & {
  evidence_id: number
}
export const createTransfer = async (body: CreateTransferProps): Promise<AxiosResponse> => {
  return request({
    method: 'POST',
    path: transferPath,
    body
  })
}

export const validateTransfer = async (body: Omit<CreateTransferProps, 'pin'>): Promise<AxiosResponse> => {
  return request({
    method: 'POST',
    path: `${ transferPath }/validation`,
    body
  })
}

type CreateBulkTransferProps = TransferFromAndToProps & {
  evidences_ids: number[]
}
export const bulkTransfer = async (body: CreateBulkTransferProps): Promise<AxiosResponse> => {
  return request({
    method: 'POST',
    path: `${ transferPath }/bulk`,
    body
  })
}

export const validateBulkTransfer = async (body: Omit<CreateBulkTransferProps, 'pin'>): Promise<AxiosResponse> => {
  return request({
    method: 'POST',
    path: `${ transferPath }/bulk-validation`,
    body
  })
}

export const completeTransfer = async (id: number): Promise<AxiosResponse> => {
  return request({
    method: 'PUT',
    path: `${ transferPath }/${id}/complete`
  })
}
