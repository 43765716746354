import { AxiosResponse } from 'axios'

import { request } from './common'


const usersPath = 'users'

export const getUsers = async (): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: usersPath
  })
}


export const triggerResetPasswordEmail = async (email: string): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: `${ usersPath }/trigger-reset-password-email`,
    body: {
      email
    }
  })
}

interface ResetPasswordProps {
  guid: string
  password: string
}
export const resetPassword = async (body: ResetPasswordProps): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: `${ usersPath }/reset-password`,
    body
  })
}
