import { Segment } from 'semantic-ui-react'

export interface SegmentItem {
  text: string
  onClick?: any
}
interface SemanticSegmentProps {
  title: string
  segmentItems: SegmentItem[]
}
function SemanticSegment(props: SemanticSegmentProps) {
  const { title, segmentItems } = props

  return <>
    <Segment.Group>
      <Segment inverted>{ title }</Segment>
      {
        segmentItems && segmentItems.length > 0 ?
          segmentItems.map((item, index) =>
            <span key={index} style={{ cursor: 'pointer' }} onClick={item.onClick}><Segment>{item.text}</Segment></span>
          )
          : <Segment>No items to display</Segment>
      }
    </Segment.Group></>
}

export default SemanticSegment
