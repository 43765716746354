import { BuildLeftAlignedDiv, BuildTitle } from './utils/utils'

export default function CreateAccountPageGuides() {
  return (
    <>
      <div style={{ margin: '8px' }}>
        <BuildTitle title='Create account' />
        <BuildLeftAlignedDiv content={'Register for free. No credit card needed and no obligation.'} />
        <br/>
        <BuildLeftAlignedDiv content={'Create your organization and register yourself. The system will ask you to ' +
          'verify your contact info.'} />
        <br/>
        <BuildLeftAlignedDiv content={'If your organization is already registered, click on Join Organization and ' +
          'register. This will notify the organization admin that you want to join and will requires the admin approval. ' +
          'You can contact the admin directly to speed up the process, if you know your organization”s Infinite System admin.'} />
        <br/>
        <BuildLeftAlignedDiv content={'Your information will not be sold or shared with any 3rd parties for any purpose.'} />
      </div>
    </>
  )
}
