import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getEntities } from '../../../../api/entity'
import { DataType, Entity, Worksheet, WorksheetFormFields } from '../../../../interfaces/all'
import { SemanticButton } from '../../../../components/buttons/buttons'
import { getEntityWorksheets } from '../../../../api/entity-worksheet'
import WorksheetComponent from '../../../../components/worksheet'
import CreateWorksheet from '../../../../components/worksheet/create'
import SemanticList from '../../../../components/list'
import SemanticLoader from '../../../../components/loader'
import ImportExcelColumns from './import-excel-columns'
import SidebarWrapperForPage from '../../../../components/sidebar-wrapper-for-page'
import WorksheetGuides from '../../../../guides/worksheets'


export default function EntityEditPage() {
  const { entityId } = useParams()
  const [allEntities, setAllEntities] = useState<Entity[]>([])
  const [entity, setEntity] = useState<Entity>()
  const [entityWorksheets, setEntityWorksheets] = useState<Worksheet[]>()
  const [loading, setLoading] = useState(false)
  const [creatingWorksheet, setCreatingWorksheet] = useState(false)
  const [defaultCreatingWorksheetName, setDefaultCreatingWorksheetName] = useState<string | undefined>()
  const [defaultCreatingWorksheetFields, setDefaultCreatingWorksheetFields] = useState<WorksheetFormFields[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    async function getEntityAndWorksheets() {
      if (entityId) {
        setLoading(true)

        const [getEntitiesResponse, getEntityWorksheetsResponse] = await Promise.all([
          getEntities(),
          getEntityWorksheets(entityId)
        ])

        const allEntitiesResponseData = getEntitiesResponse.data as Entity[]

        setAllEntities(allEntitiesResponseData)
        setEntity(allEntitiesResponseData.find(entity => entity.id.toString() === entityId))
        setEntityWorksheets(getEntityWorksheetsResponse.data)
        setLoading(false)
      }
    }

    getEntityAndWorksheets()
  }, [entityId])

  async function onWorksheetCreated() {
    setCreatingWorksheet(false)
    getEntityWorksheets(entityId as string).then(response => setEntityWorksheets(response.data))
  }

  const entityWorksheetsElement = <div style={{ margin: '16px auto' }}>
    {
      loading ? <SemanticLoader /> :
        entityWorksheets && entityWorksheets.length > 0
          ? <SemanticList items={entityWorksheets?.map(worksheet => <>
            <div style={{ marginBottom: '12px' }}>
              <WorksheetComponent worksheet_id={worksheet.worksheet_id} worksheet_name={worksheet.worksheet_name}
                worksheet_fields={worksheet.worksheet_fields} entities={allEntities} />
              <SemanticButton text='edit worksheet fields' onClick={() => navigate(`/admin/entities/${entityId}/worksheets/${worksheet.worksheet_id}/edit`, { state: worksheet })} />

            </div>
          </>
          )} />
          : `${entity?.data_name} has not worksheets to display`
    }
  </div>

  function handleCreateNewWorksheet() {
    setDefaultCreatingWorksheetFields([])
    setCreatingWorksheet(true)
  }

  function importExcelColumnsCallback(worksheetName: string, worksheetFormFields: WorksheetFormFields[]) {
    setDefaultCreatingWorksheetName(worksheetName)
    setDefaultCreatingWorksheetFields(worksheetFormFields)
    setCreatingWorksheet(true)
  }

  function handleEditData() {
    if (entity?.data_type === DataType.DYNAMIC) {
      navigate(`/entity/${entity.id}`)
    } else if (entity?.data_type === DataType.CASE) {
      navigate('/cases')
    }
  }

  const displayEditDataButtom = entity?.data_type === DataType.DYNAMIC || entity?.data_type === DataType.CASE
  const displayImportCasesButton = entity?.data_type === DataType.CASE
  const displayImportEvidencesButton = entity?.data_type === DataType.EVIDENCE
  const displayImportServicesButton = entity?.data_type === DataType.SERVICE
  const displayImportDynamicButton = entity?.data_type === DataType.DYNAMIC

  const importButtons = <>
    {displayImportCasesButton &&
      <SemanticButton text={'Import cases'} color='blue' disabled={loading}
        onClick={() => navigate(`/admin/entities/${entityId}/importcases`)} />
    }
    {displayImportEvidencesButton &&
      <SemanticButton text={'Import evidences'} color='blue' disabled={loading}
        onClick={() => navigate(`/admin/entities/${entityId}/importevidences`)} />
    }
    {displayImportServicesButton &&
      <SemanticButton text={'Import services'} color='blue' disabled={loading}
        onClick={() => navigate(`/admin/entities/${entityId}/importservices`)} />}
    {displayImportDynamicButton &&
      <SemanticButton text={'Import dynamic'} color='blue' disabled={loading}
        onClick={() => navigate(`/admin/entities/${entityId}/importdynamic`)} />}
  </>

  const pageContent = <>
    <div style={{ maxWidth: '800px', margin: '4px auto', paddingBottom: '12px' }}>
      {
        entity && <h1>{`${entity.data_name} (${entity.data_type})`}</h1>
      }
      {
        creatingWorksheet
          ? <CreateWorksheet entityId={entityId as string} onCanceled={() => setCreatingWorksheet(false)} onCreated={() => onWorksheetCreated()}
            defaultWorksheetName={defaultCreatingWorksheetName} defaultWorksheetFields={defaultCreatingWorksheetFields} />
          : <>
            <SemanticButton text={'Return'} color='grey' onClick={() => navigate('/admin/entities')} />
            <SemanticButton text={'Create new worksheet'} color='blue' onClick={handleCreateNewWorksheet} disabled={loading} />
            <ImportExcelColumns callback={importExcelColumnsCallback} disabled={loading} />
            {importButtons}
            {
              displayEditDataButtom && <SemanticButton text={'Edit data'} color='blue' onClick={handleEditData} disabled={loading} />
            }
            {entityWorksheetsElement}
          </>
      }
    </div>
  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<WorksheetGuides />} />
}
