import { BuildLeftAlignedDiv, BuildList, BuildTitle } from './utils/utils';

export default function EditServicePageGuides() {
  return (
    <>
      <div style={{ margin: '8px' }}>
        <BuildTitle title='Editing Service Details' />
        <BuildLeftAlignedDiv content={'Selecting an existing service within the "Service" table and clicking "Edit" allows you to modify the details of that service. This typically opens a page with the same or similar fields as creating a new service, but pre-populated with the existing information. You can edit these details and:'} />
        <BuildList items={[
          'Edit Fingerprint Analysis (or Service Type Name): This option might be specific to editing services of a particular type and allows for modifications relevant to that service type.',
          'Agency List: View or update the agency assigned to the service.',
          'Actions: This section might offer additional actions specific to the service type (consult your system documentation for details).',
          'Cancel: Discard any changes and return to the service list.',
          'Save: Confirm any changes made to the service details.'
        ]} />
      </div>
    </>
  );
}
