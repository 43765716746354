import SemanticIcon from './icon'

interface SaveIconProps {
  onClick: any
  disabled?: boolean
}
export default function SaveIcon(props: SaveIconProps) {
  const { onClick, disabled } = props

  return <span title='save' onClick={onClick}>
    <SemanticIcon color={'blue'} name={'save'} disabled={disabled}
    /></span>
}
