import { Checkbox } from 'semantic-ui-react'

import SemanticModal from './modal'
import { Entity, Group } from '../../interfaces/all'
import SemanticList from '../list'
import { addOrRemoveFromList } from '../../utils/utils'
import { useState } from 'react'
import { showToast } from '../toast'
import { updateGroup } from '../../api/groups'


interface GroupEntitiesModalProps {
  group: Group & { id: string }
  allEntities: Entity[]
  onCancel: Function
  onSaved: Function
}

export default function GroupEntitiesModal(props: GroupEntitiesModalProps) {
  const { allEntities, group, onCancel, onSaved } = props
  const [entitiesIds, setEntitiesIds] = useState(group.entities.map(groupEntity => groupEntity.id))

  async function saveAndClose() {
    const newEntitiesIds = entitiesIds.filter(id => !group.entities.some(entity => entity.id === id))
    const removeEntitiesIds = group.entities.filter(entity => !entitiesIds.includes(entity.id))
      .map(groupEntity => groupEntity.id)

    const { status } = await updateGroup({ groupId: group.id, newEntitiesIds, removeEntitiesIds })

    if (status === 200) {
      showToast({ message: 'Group entities updated' })
    }

    onSaved()
  }

  function addOrRemoveEntityId(id: number): void {
    setEntitiesIds(addOrRemoveFromList(entitiesIds, id))
  }

  const content = <SemanticList items={
    allEntities.map(entity => {
      const defaultChecked = group.entities.some(groupEntity => groupEntity.id === entity.id)

      return (
        <div className='item' key={entity.id}>
          <Checkbox defaultChecked={defaultChecked} label={`${entity.data_name} (${entity.data_type})`}
            onChange={() => addOrRemoveEntityId(entity.id)} />
        </div>
      )
    })
  } />

  return <>
    <SemanticModal header={`Editing group: ${group.name}`}
      content={content}
      actions={[
        { color: 'grey', onClick: onCancel, text: 'Cancel' },
        { color: 'blue', onClick: saveAndClose, text: 'Save' }
      ]}
    /></>
}
