import { useNavigate } from 'react-router-dom'

import { createEntity } from '../../../api/entity'
import { showErrorToast } from '../../../components/toast'
import SemanticForm, { FormField } from '../../../components/form/form'
import { ColumnType } from '../../../interfaces/all'
import GlobalConfiguration from '../../../configuration/global'

export default function EntityCreatePage() {
  const navigate = useNavigate()
  const isCaseBased = new GlobalConfiguration().isCaseBased()

  async function create(body: any) {
    const { status, data } = await createEntity(body)

    if (status === 201) {
      navigate(`/admin/entities/${data}`)
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Entity not created')
    }
  }

  const entityTypeOptions = [
    { key: 'Case', value: 'Case', text: 'Case' },
    { key: 'Service', value: 'Service', text: 'Service' },
    { key: 'Evidence', value: 'Evidence', text: 'Evidence' },
    { key: 'Dynamic', value: 'Dynamic', text: 'Dynamic' }
  ]

  let formFields: FormField[] = [{
    key: 'data_name',
    type: ColumnType.TEXT,
    label: 'Entity name',
    placeholder: 'entity name',
    required: true
  }]

  if (isCaseBased) {
    formFields.push({
      key: 'data_type',
      type: ColumnType.PICKLIST,
      label: 'Entity type',
      required: true,
      dropdownItemProps: entityTypeOptions
    })
  }

  function handleSubmit(data: any) {
    const body = isCaseBased ? data : {...data, 'data_type': 'Dynamic' }

    create(body)
  }

  return <>
    <div style={{ maxWidth: '300px', margin: '30px auto' }}>
      <SemanticForm title={'Create new entity'} formFields={formFields}
        onCancel={() => navigate('/admin/entities')} cancelLabel={'Cancel'}
        onSubmit={(data: any) => handleSubmit(data)} submitLabel={'Create'}
      />
    </div>
  </>
}
