import { Permission, Permissions } from '../interfaces/all'

export const canEdit = (entity: number, permissions?: Permissions): boolean => {
  return permissions?.entities[entity]?.includes(Permission.EDIT) || false
}

export const canDelete = (entity: number, permissions?: Permissions): boolean => {
  return permissions?.entities[entity]?.includes(Permission.DELETE) || false
}

export const isSysEdit = (permissions?: Permissions): boolean => {
  return permissions?.global.includes(Permission.SYSTEM_EDIT)
    || permissions?.global.includes(Permission.SYSTEM_ADMIN)
    || false
}

export const isSysAdmin = (permissions?: Permissions): boolean => {
  return permissions?.global.includes(Permission.SYSTEM_ADMIN) || false
}
