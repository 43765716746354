import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { deleteDynamic, getDynamic, updateDynamic } from '../../api/dynamic/dynamics'
import { DataType, GenericGetResponse, Metadata, RawData, isListColumn } from '../../interfaces/all'
import { showToast } from '../../components/toast'
import { canDelete, canEdit, isSysEdit } from '../../utils/permissions'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import DynamicEditPageGuides from '../../guides/edit-dynamic'
import { Grid } from 'semantic-ui-react'
import SemanticForm, { getFormFieldsFromColumns } from '../../components/form/form'
import SemanticMenu from '../../components/menu'
import DynamicGrid from '../../components/ag-grid/dynamic-grid'
import { createDynamicDetails, getDynamicDetails } from '../../api/dynamic/dynamic-details'
import SemanticTooltip from '../../components/tooltips/generic'

function DynamicEditPage() {
  const { dynamicId } = useParams()
  const [dynamicData, setDynamicData] = useState<RawData | undefined>()
  const [metadata, setMetadata] = useState<Metadata>({ columns: [] })
  const [activeList, setActiveList] = useState<{ fieldName: string, fieldId: number } | undefined>()
  const [details, setDetails] = useState<GenericGetResponse | undefined>()
  const [savingDynamicData, setSavingDynamicData] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    if (dynamicId) {
      getDynamic(dynamicId as unknown as number).then(response => {
        setDynamicData(response.raw_data[0])
        setMetadata(response.meta)
      })
    }
  }, [dynamicId])

  async function reloadDynamicAndDetails() {
    getDynamic(dynamicId as unknown as number).then(response => {
      setDynamicData(response.raw_data[0])
      setMetadata(response.meta)
    })

    if (activeList?.fieldId) {
      getDynamicDetails(activeList.fieldId as unknown as string, dynamicId as string)
        .then(response => setDetails(response))
    }
  }

  useEffect(() => {
    if (activeList?.fieldId) {
      getDynamicDetails(activeList.fieldId as unknown as string, dynamicId as string)
        .then(response => setDetails(response))
    }
  }, [activeList, dynamicId])

  async function save(data: any) {
    setSavingDynamicData(true)

    const { status } = await updateDynamic({ ...data, id: dynamicId })

    if (status === 200) {
      showToast({ message: 'Row updated' })
    }

    setSavingDynamicData(false)
    reloadDynamicAndDetails()
  }

  async function createDetails(data: any) {
    const { status } = await createDynamicDetails(data, activeList?.fieldId?.toString() as string, dynamicId as string)

    if (status === 201) {
      showToast({ message: `${activeList?.fieldName} created` })
    }

    reloadDynamicAndDetails()
  }

  async function deleteDetails(dynamicId: number) {
    const { status } = await deleteDynamic(dynamicId as unknown as string)

    if (status === 204) {
      showToast({ message: `${activeList?.fieldName} deleted` })
    }

    reloadDynamicAndDetails()
  }

  async function updateDetails(data: any) {
    const { status } = await updateDynamic(data)

    if (status === 200) {
      showToast({ message: 'Row updated' })
    }

    reloadDynamicAndDetails()
  }

  const lists = metadata.columns.filter(isListColumn)
    .map(col => ({
      header: col.name,
      fieldId: col.field_id
    })) ?? []

  const formFields = getFormFieldsFromColumns(metadata?.columns)
    .map(formField => ({
      ...formField,
      defaultValue: dynamicData ? dynamicData[formField.key] : undefined
    }))

  const pageContent = <>
    <Grid columns={2} divided style={{ height: '100%' }}>
      <Grid.Row style={{ height: '100%', overflow: 'auto' }}>
        <Grid.Column width={5} style={{ height: '100%' }}>
          <div style={{ fontSize: '28px', fontWeight: 600, margin: '8px', textAlign: 'center' }}>
            {dynamicData ? `${dynamicData?.['Display Name']}` : 'loading ...'}
            {
              isSysEdit(metadata.permissions) && dynamicData && <span style={{ paddingLeft: '8px', cursor: 'pointer' }}>
                <SemanticTooltip text={'Modify Entity Structure'} iconProps={{ name: 'edit', color: 'blue', onClick: () => navigate(`/admin/entities/${dynamicData['entity_id']}`) }} />
              </span>
            }
          </div>
          <div style={{ padding: '16px'}}>
            <SemanticForm formFields={formFields}
              onCancel={() => navigate(`/entity/${dynamicData?.['entity_id']}`)} cancelLabel={'Return'}
              onSubmit={(data: any) => save(data)} submitDisabled={savingDynamicData} submitLoading={savingDynamicData} />
          </div>

        </Grid.Column>
        <Grid.Column width={11} style={{ height: '100%' }}>
          <div style={{ padding: '8px', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <SemanticMenu items={
              lists.map(list => ({
                name: list.header,
                onClick: () => setActiveList({
                  fieldName: list.header,
                  fieldId: list.fieldId as number
                })
              }))
            } />
            {
              details && details?.meta && details.raw_data &&
              <DynamicGrid dataType={DataType.DYNAMIC}
                rows={details.raw_data}
                columns={details.meta.columns}
                onDelete={(dynamicId) => deleteDetails(dynamicId)}
                onCancel={() => reloadDynamicAndDetails()}
                onEdit={(dynamicId) => {
                  setActiveList(undefined)
                  setDetails(undefined)
                  navigate(`/dynamic/${dynamicId}/edit`)
                }}
                onCreate={(data) => createDetails(data)}
                onUpdate={(data) => updateDetails(data)}
                canDelete={canDelete(dynamicData?.['entity_id'] as number, metadata.permissions)}
                canEdit={canEdit(dynamicData?.['entity_id'] as number, metadata.permissions)}
              />
            }
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<DynamicEditPageGuides />} />
}

export default DynamicEditPage
