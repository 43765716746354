function InternalError() {
  return (
    <>
      <div style={{ height: '100%', textAlign: 'center', paddingTop: '50px' }}>
        <h2>Sorry, something unexpected happened and we are working on a fix!</h2>
        <h3>Try again later and if the problem persists, please contact support.</h3>
      </div>
    </>
  )
}

export default InternalError
