import { BuildLeftAlignedDiv, BuildList, BuildSubTitle, BuildTitle } from './utils/utils';

export default function EditCasePageGuides() {
  return (
    <>
      <div style={{ margin: '8px' }}>
        <BuildTitle title='Editing a Case' data-value="\src\guides\edit-case.tsx" /> {/* Added data-value attribute */}
        <BuildList items={[
          '**Locate the Case:** Find the case you want to edit in the "All Cases" list.',
          '**Click "Edit":** Click the "Edit" button located within the desired case row. This will open a dedicated page for editing the case details.'
        ]} />
        <BuildSubTitle title='Common Sections:' />
        <BuildList items={[
          '**Top Navigation:** Menu bars with options: "Home," "Admin," "Packages," "Dynamic Data," "Transfers," and your username.',
          '**Case Information:** This section displays details like case number, case type, and other relevant fields defined in the worksheet associated with the case entity (e.g., location, date, investigator assigned).',
          '**Evidence:** This section displays a list of existing evidence items linked to the case. You might see buttons like "Add Evidence" to link additional evidence.',
          '**Service:** Similar to evidence, this section shows a list of existing services associated with the case. Click "Add Service" to assign new services.'
        ]} />
        <BuildSubTitle title='Cancel/Save:' />
        <BuildLeftAlignedDiv content={'Use these buttons to save any changes made to the case details or return to the previous view without saving.'} />
        <BuildSubTitle title='Top Navigation (Editing a Case):' />
        <BuildLeftAlignedDiv content={'When you click "Edit Case," a second menu bar appears below the original one. This new menu bar offers case-specific actions:'} />
        <BuildList items={[
          '**Evidence:** Opens a list of existing evidence items linked to the case.',
          '**Add Evidence:** Opens a page to create a new evidence item for this case.',
          '**Service:** Opens a list of existing services associated with the case.',
          '**Add Service:** Opens a page to create a new service for this case.'
        ]} />
      </div>
    </>
  );
}
