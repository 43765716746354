import { AxiosResponse } from 'axios'

import { request } from '../common'
import { GenericGetResponse, RawData } from '../../interfaces/all'

const dynamicPath = 'dynamics'

export const getDynamics = async (entityId: string): Promise<GenericGetResponse> => {
  const { data } = await request({
    method: 'GET',
    path: dynamicPath,
    queryParams: [{ key: 'entity_id', value: entityId }]
  })

  return data as GenericGetResponse
}

export const getDynamic = async (dynamicId: number): Promise<GenericGetResponse> => {
  const { data } = await request({
    method: 'GET',
    path: `${ dynamicPath }/${ dynamicId }`
  })

  return data as GenericGetResponse
}

export const createDynamic = async(body: object): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: dynamicPath,
    body
  })
}

export const updateDynamic = async(body: RawData): Promise<AxiosResponse> => {
  const dynamicId = body.id

  return await request({
    method: 'PUT',
    path: `${ dynamicPath }/${ dynamicId }`,
    body
  })
}

export const deleteDynamic = async(dynamicId: string): Promise<AxiosResponse> => {
  return await request({
    method: 'DELETE',
    path: `${ dynamicPath }/${ dynamicId }`
  })
}
