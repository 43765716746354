interface IFrameProps {
  title: string
  src: string
}
export default function IFrame(props: IFrameProps) {
  const { title, src } = props
  return <>
    <iframe title={title}
      width="1150" height="650" style={{ border: '0' }} allowFullScreen
      src={src}
      loading="lazy"></iframe>
  </>
}
