import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { CellClickedEvent, CellEditingStartedEvent } from 'ag-grid-community'

import { createCase, deleteCase, getCases, updateCase } from '../../api/cases/cases'
import { SemanticButton } from '../../components/buttons/buttons'
import { getColumnsDefs, buildExcelHeadersAndRows } from '../../components/ag-grid/utils'
import AgGrid from '../../components/ag-grid/ag-grid'
import { showErrorToast, showToast } from '../../components/toast'
import { canDelete, canEdit } from '../../utils/permissions'
import { DataType, Metadata, RawData } from '../../interfaces/all'
import { removeRecentCase, setRecentCase } from '../../utils/localStorage/recent-cases'
import { EMPTY_KEY } from '../../components/form/form'
import SemanticIcon from '../../components/icons/icon'
import ConfirmationModal from '../../components/modals/confirmation-modal'
import { getPinnedItems } from '../../api/me'
import { Pinned } from '../../interfaces/api/me'
import { addPinnedItem, removePinnedItem } from '../../api/pinned'
import { downloadAsExcel } from '../../utils/excel'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import CasesPageGuides from '../../guides/cases'
import { CaseItem } from '../../interfaces/api/cases'

function CasesIndexPage() {
  const [originalRowData, setOriginalRowData] = useState<CaseItem[] | undefined>()
  const [rowData, setRowData] = useState<RawData[]>([])
  const [metadata, setMetadata] = useState<Metadata>({ columns: [] })
  const [onEditModeRowId, setOnEditModeRowId] = useState(0)
  const [pinnedItems, setPinnedItems] = useState<Pinned[]>([])
  const [deleteCaseConfirmation, setDeleteCaseConfirmation] = useState<RawData | undefined>()
  const [changeCaseTypeConfirmation, setChangeCaseTypeConfirmation] = useState<RawData | undefined>()
  const [pinningUnpinning, setPinningUnpinning] = useState(false)
  const [loadingPage, setLoadingPage] = useState(true)
  const navigate = useNavigate()
  const isEditingRow = onEditModeRowId !== 0

  useEffect(() => {
    setLoadingPage(true)
    getCases().then(response => {
      setMetadata(response.meta)
      setOriginalRowData(response.raw_data)

      setLoadingPage(false)
    })
    getPinnedItems().then(res => setPinnedItems(res.data))
  }, [])

  async function refreshGrid() {
    setLoadingPage(true)
    getCases().then(response => {
      setMetadata(response.meta)
      setOriginalRowData(response.raw_data)

      setOnEditModeRowId(0)

      setLoadingPage(false)
    })
  }

  useEffect(() => {
    if (originalRowData) {
      setRowData(originalRowData.map(item => ({ ...item })))
    }

  }, [originalRowData])

  async function refreshPinnedItems() {
    getPinnedItems().then(res => {
      setPinnedItems(res.data)
      setPinningUnpinning(false)
    })
  }

  function addRow() {
    const newRowId = -1

    let temp = rowData.length > 0 ? [...rowData] : []
    temp.push({ id: newRowId })

    setRowData(temp)

    setOnEditModeRowId(newRowId)
  }

  function saveRow(event: CellClickedEvent) {
    const { data } = event

    if (data.id.toString() === EMPTY_KEY) {
      delete data.id
      create(data)
    } else {
      if (caseTypeHasChanged(data)) {
        setChangeCaseTypeConfirmation(data)
      } else {
        update(data)
      }
    }
  }

  async function create(body: any) {
    const { status, data } = await createCase(body)

    if (status === 201) {
      showToast({ message: 'Case created' })

      setRecentCase({ id: data, caseNumber: body['Case #'] })

      refreshGrid()
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Case not created')
    }
  }

  function caseTypeHasChanged(body: any): boolean {
    const originalRow = originalRowData?.find(x => x.id === body.id) as CaseItem

    return originalRow['Case Type'] !== body['Case Type']
  }

  async function update(body: any) {
    const { status, data } = await updateCase(body, body['id'] as string)

    if (status === 200) {
      showToast({ message: 'Case updated' })

      refreshGrid()
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Case not updated')
    }
  }

  async function deleteRow(id: number) {
    const { status } = await deleteCase(id)

    if (status === 204) {
      showToast({ message: 'Case deleted!' })
      removeRecentCase(id)
    }

    refreshGrid()
  }

  function cancelEditing() {
    setRowData(originalRowData?.map(item => ({ ...item })) ?? [])

    setOnEditModeRowId(0)
  }

  function getCustomColumnsDefs(): Array<any> {
    const columnsDefs = getColumnsDefs({ columns: metadata.columns, editable: true })

    return columnsDefs.map(colDefs => {
      return {
        ...colDefs,
        editable: (e: CellClickedEvent) => colDefs.editable && (e.data.id === onEditModeRowId || onEditModeRowId === 0),
        cellStyle: (_e: CellClickedEvent) => colDefs.editable ? undefined : { color: 'gray' }
      }
    }) ?? []
  }

  async function pinRow(event: CellClickedEvent) {
    const { data } = event

    setPinningUnpinning(true)

    const { status } = await addPinnedItem({
      data_id: data.id,
      data_type: DataType.CASE
    })

    if (status === 201) {
      showToast({ message: `${data['Case #']} was pinned` })
    }

    refreshPinnedItems()
  }

  async function unpinRow(event: CellClickedEvent) {
    const { data } = event

    const pinned = pinnedItems.find(pinned => pinned.data_id === data.id) as Pinned

    setPinningUnpinning(true)

    const { status } = await removePinnedItem(pinned.id)

    if (status === 204) {
      showToast({ message: `${data['Case #']} was unpinned` })
    }

    refreshPinnedItems()
  }

  const deleteColumnDef = {
    pinned: 'left',
    headerName: '',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const entity = event.data['Case Type']
      const enabled = !isEditingRow && canDelete(entity, metadata.permissions)

      return <span title='delete' onClick={() => enabled ? setDeleteCaseConfirmation(event.data) : undefined}>
        <SemanticIcon color='red' name='delete' disabled={!enabled} />
      </span>
    }
  }

  const pinRowColumnDef = {
    pinned: 'left',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const disabled = isEditingRow || pinningUnpinning
      const isPinned = pinnedItems.some(row => row.data_id === event.data.id && row.data_type === DataType.CASE)

      return <span title={isPinned ? 'unpin' : 'pin'} onClick={() => !disabled ? (isPinned ? unpinRow(event) : pinRow(event)) : undefined}>
        <SemanticIcon color={isPinned ? 'blue' : 'grey'} name='pin' disabled={disabled} />
      </span>
    }
  }

  const editColumnDef = {
    pinned: 'left',
    headerName: '',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const entity = event.data['Case Type']
      const enabled = !isEditingRow && canEdit(entity, metadata.permissions)

      return <span title='edit' onClick={() => enabled ? navigate(`/cases/${event.data.id}/edit`) : undefined}>
        <SemanticIcon color='blue' name='edit' disabled={!enabled} />
      </span>
    }
  }

  const cancelColumnDef = {
    pinned: 'left',
    headerName: '',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const rowId = event.data['id']
      const entity = event.data['Case Type']

      const enabled = rowId === onEditModeRowId && canEdit(entity, metadata.permissions)

      return <span title='cancel' onClick={() => enabled ? cancelEditing() : undefined}>
        <SemanticIcon color='grey' name='arrow left' disabled={!enabled} />
      </span>
    }
  }

  const saveColumnDef = {
    pinned: 'left',
    headerName: '',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const rowId = event.data['id']
      const entity = event.data['Case Type']

      const enabled = rowId === onEditModeRowId && canEdit(entity, metadata.permissions)

      return <span title='save' onClick={() => enabled ? saveRow(event) : undefined}>
        <SemanticIcon color={'blue'} name={'save'} disabled={!enabled}
        /></span>
    },
  }

  let columnDefs: any[] = []

  if(!isEditingRow) {
    columnDefs.push(deleteColumnDef)
    columnDefs.push(pinRowColumnDef)
    columnDefs.push(editColumnDef)
  } else {
    columnDefs.push(cancelColumnDef)
    columnDefs.push(saveColumnDef)
  }  

  columnDefs = [...columnDefs, ...getCustomColumnsDefs()]

  const gridOptions = {
    onCellEditingStarted: (event: CellEditingStartedEvent) => setOnEditModeRowId(event.data.id)
  }

  async function handleDownload() {
    downloadAsExcel(buildExcelHeadersAndRows(rowData, metadata.columns))
  }

  const pageContent = <>
    <div style={{ padding: '12px', height: '100%', display: 'flex', flexDirection: 'column' }}>
      <div style={{ paddingBottom: '4px' }}>
        <SemanticButton disabled={isEditingRow || loadingPage} text={'Add case'} onClick={addRow} />
        <span style={{ float: 'right' }}>
          <SemanticButton text={'Export'} onClick={handleDownload} disabled={isEditingRow || loadingPage} />
        </span>
      </div>
      <AgGrid columnDefs={columnDefs} rowData={rowData} gridOptions={gridOptions} />
    </div>
    {
      deleteCaseConfirmation &&
      <ConfirmationModal header={`Case: ${deleteCaseConfirmation['Case #']}`}
        content={'Are you sure you want to delete this case?'}
        onCancel={() => setDeleteCaseConfirmation(undefined)}
        onSaved={() => {
          setDeleteCaseConfirmation(undefined)
          deleteRow(deleteCaseConfirmation.id)
        }} />
    }
    {
      changeCaseTypeConfirmation &&
      <ConfirmationModal header={`Case ${changeCaseTypeConfirmation['Case #']}`}
        content={'Changing the case type might delete some data, are you sure you want to proceed?'}
        onCancel={() => setChangeCaseTypeConfirmation(undefined)}
        onSaved={() => {
          setChangeCaseTypeConfirmation(undefined)
          update(changeCaseTypeConfirmation)
        }} />
    }
  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<CasesPageGuides />} />
}

export default CasesIndexPage
