import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { AnimatedReturnButton } from '../../components/buttons/buttons'
import { getColumnsDefs } from '../../components/ag-grid/utils'
import { getService } from '../../api/services/services'
import AgGrid from '../../components/ag-grid/ag-grid'
import { GenericGetResponse } from '../../interfaces/all'

function ServicePage() {
  const { serviceId } = useParams()
  const [services, setServices] = useState<GenericGetResponse | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    getService(serviceId as string).then(response => setServices(response))
  }, [serviceId])

  const columnDefs = [
    {
      headerName: 'Return',
      width: 100,
      cellRenderer: AnimatedReturnButton,
      onCellClicked: () => navigate(-1),
    },
    ...getColumnsDefs({ columns: services?.meta.columns })
  ]
  const rowData = services?.raw_data

  return (
    <AgGrid columnDefs={columnDefs} rowData={rowData} />
  )
}

export default ServicePage
