import { Segment } from 'semantic-ui-react'
import SegmentWithIcon from './segment-with-icon'
import { SemanticIconProps } from './icons/icon'

export interface SegmentGroupWithIconsProps {
  title: string
  segmentItems: {
    text: string
    onClick?: any
    iconProps: SemanticIconProps
  }[]
}
export default function SegmentGroupWithIcons(props: SegmentGroupWithIconsProps) {
  const { title, segmentItems } = props

  return <>
    <Segment.Group>
      <Segment inverted>{title}</Segment>
      {
        segmentItems && segmentItems.length > 0 ?
          segmentItems.map((item, index) => {
            const { onClick, text, iconProps } = item

            return <SegmentWithIcon index={index} text={text}
              onClick={onClick}
              semanticIconProps={iconProps} />
          })
          : <Segment>No items to display</Segment>
      }
    </Segment.Group>
  </>
}
