import { AxiosResponse } from 'axios'
import { request } from './common'

const servicesEvidencesPath = 'service-evidences'

interface ServiceEvidences {
  id: number,
  service_id: number,
  evidence_id: number
}

export const getServiceEvidences = async (serviceId: number): Promise<ServiceEvidences[]> => {
  const { data } = await request({
    method: 'GET',
    path: servicesEvidencesPath,
    queryParams: [{ key: 'serviceId', value: serviceId.toString() }]
  })

  return data as ServiceEvidences[]
}

export const updateServiceEvidences = async (serviceId: number, evidencesIds: number[]): Promise<AxiosResponse> => {
  return await request({
    method: 'PUT',
    path: servicesEvidencesPath,
    body: {
      serviceId,
      evidencesIds
    }
  })
}
