import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { DataType } from '../../interfaces/all'
import { Pinned } from '../../interfaces/api/me'
import SegmentGroupWithIcons, { SegmentGroupWithIconsProps } from '../../components/segment-group-with-icon'
import { getPinnedItems } from '../../api/me'
import { removePinnedItem } from '../../api/pinned'
import { showErrorToast, showToast } from '../../components/toast'


export default function PinnedItems() {
  const [pinnedItems, setPinnedItems] = useState<Pinned[]>([])
  const [unpinningItemWithId, setUnpinningItemWithId] = useState<number | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    getPinnedItems().then(response => setPinnedItems(response.data))
  }, [])

  async function unpinItem(id: number): Promise<void> {
    setUnpinningItemWithId(id)

    const { status } = await removePinnedItem(id)

    if (status === 204) {
      showToast({ message: 'Item was unpinned' })
    } else {
      showErrorToast('Could not unpin item')
    }

    getPinnedItems().then(response => {
      setPinnedItems(response.data)
      setUnpinningItemWithId(undefined)
    })
  }

  const getPinnedItemRoute = (item: Pinned): string => {
    const { data_type, data_id, case_id, service_id } = item

    if (data_type === DataType.DYNAMIC) {
      return `/dynamic/${data_id}/edit`
    } else if (data_type === DataType.CASE) {
      return `/cases/${data_id}/edit`
    } else if (data_type === DataType.EVIDENCE) {
      if(case_id) {
        return `/cases/${case_id}/evidences/${data_id}/edit`
      } else {
        return `/evidences/${data_id}/edit`
      }
    } else if (data_type === DataType.SERVICE) {
      if(case_id) {
        return `/cases/${case_id}/services/${data_id}/edit`
      } else {
        return `/services/${data_id}/edit`
      }
    } else if (data_type === DataType.ACTION) {
      return `/cases/${case_id}/services/${service_id}/edit`
    } else {
      console.error(`Invalid data type: ${data_type}`)
      return ''
    }
  }

  const pinnedItemsProps: SegmentGroupWithIconsProps = {
    title: 'Pinned items',
    segmentItems: pinnedItems.map((item, index) => {
      const { text } = item

      return {
        text,
        onClick: () => navigate(getPinnedItemRoute(item)),
        iconProps: {
          color: 'blue',
          name: 'pin',
          onClick: () => unpinItem(item.id),
          disabled: unpinningItemWithId !== undefined,
          loading: unpinningItemWithId === item.id
        }
      }
    })
  }

  return <SegmentGroupWithIcons {...pinnedItemsProps} />
}
