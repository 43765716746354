import { useEffect, useState } from 'react'
import { RegistrationBasicInformation, getRegistrationBasicInformation } from '../../api/registration'
import { Form } from 'semantic-ui-react'
import SemanticTextFormField from '../../components/form/text-field'
import { SemanticButton } from '../../components/buttons/buttons'
import SemanticLoader from '../../components/loader'

interface EmailVerificationProps {
  guid: string
  submitCallback: (password: string) => void
}
export default function EmailVerification(props: EmailVerificationProps) {
  const [registrationBasicInformation, setRegistrationBasicInformation] = useState<RegistrationBasicInformation | undefined>()
  const [completingRegistration, setCompletingRegistration] = useState(false)
  const [loading, setLoading] = useState(true)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState<string | undefined>(undefined)
  const { guid, submitCallback } = props

  useEffect(() => {
    if (guid) {
      getRegistrationBasicInformation(guid).then(res => {
        setRegistrationBasicInformation(res.data)
        setLoading(false)
      })
    }

  }, [guid])

  function onChangePassword(value: string) {
    setPasswordError(undefined)
    setConfirmPasswordError(undefined)
    setPassword(value)
  }

  function onChangeConfirmPassword(value: string) {
    setPasswordError(undefined)
    setConfirmPasswordError(undefined)
    setConfirmPassword(value)
  }

  function validateAndSubmit() {
    if (!(password && password.length > 7)) {
      setPasswordError('Password must be at least 8 characters length')
      return
    }

    if (!(confirmPassword && confirmPassword.length > 7)) {
      setConfirmPasswordError('Password must be at least 8 characters length')
      return
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match')
      return
    }

    handleSubmit()
  }

  function handleSubmit() {
    setCompletingRegistration(true)
    submitCallback(password)
  }

  const buttonEnabled = !loading && guid && registrationBasicInformation && password && confirmPassword

  const completingRegistrationContent = <>
    <h2>We are completing your registration</h2>
    <h3>Please wait ...</h3>
  </>

  const completeRegistrationForm = <>
    <h3>Complete your registration</h3>
    <div>{`Organization name: ${registrationBasicInformation?.organization_name}`}</div>
    <div>{`Email: ${registrationBasicInformation?.email}`}</div>
    <div style={{ marginTop: '16px' }}>
      <Form>
        <SemanticTextFormField label='password' placeholder='password' value={password} required error={passwordError} index={0} type='password'
          onChange={(e) => onChangePassword(e.target.value)} />
        <SemanticTextFormField label='confirm password' placeholder='confirm password' value={confirmPassword} required error={confirmPasswordError} index={1} type='password'
          onChange={(e) => onChangeConfirmPassword(e.target.value)} />
        <SemanticButton text={'Confirm'} onClick={validateAndSubmit} disabled={!buttonEnabled} type='submit' />
      </Form>
    </div>
  </>

  return <><div style={{ maxWidth: '400px', margin: '30px auto' }}>
    {
      loading ?
        <SemanticLoader />
        : completingRegistration
          ? completingRegistrationContent
          : completeRegistrationForm
    }
  </div></>
}
