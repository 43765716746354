import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { getOrganizations } from '../../api/organizations'
import SemanticList from '../../components/list'
import { SemanticButton } from '../../components/buttons/buttons'


export default function PublicIndexPage() {
  const [organizaitonsNames, setOrganizationsNames] = useState<string[]>([])
  const navigate = useNavigate()

  useEffect(() => {
    getOrganizations().then(response => setOrganizationsNames(response.data))
  }, [])

  return <>
    <div style={{ maxWidth: '400px', margin: '0 auto', padding: '16px' }}>
      <h2>Public information</h2>

      <SemanticList items={organizaitonsNames.map(orgName => <>
        <SemanticButton size='mini' text='view' color='green' onClick={() => navigate(`/public/${orgName}`)} /> {orgName}
      </>)} />
    </div>
  </>
}
