import { useNavigate } from 'react-router-dom'
import { Menu } from 'semantic-ui-react'
import { useCase } from '../hooks/case'

function CaseSubMenu() {
  const { id: caseId, number: caseNumber } = useCase()
  const navigate = useNavigate()

  return <>
    <Menu style={{ margin: '0', borderRadius: '0'}} size='mini'>
      {
        caseId && caseNumber && <>
          <Menu.Item onClick={() => navigate(`/cases/${caseId}/edit`)}>Case #: { caseNumber }</Menu.Item>
          <Menu.Item onClick={() => navigate(`/cases/${caseId}/evidences`)}>Evidence</Menu.Item>
          <Menu.Item onClick={() => navigate(`/cases/${caseId}/evidences/create`)}>Add Evidence</Menu.Item>
          <Menu.Item onClick={() => navigate(`/cases/${caseId}/services`)}>Services</Menu.Item>
          <Menu.Item onClick={() => navigate(`/cases/${caseId}/services/create`)}>Add Service</Menu.Item>
        </>
      }
    </Menu>
  </>
}

export default CaseSubMenu
