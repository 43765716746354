import { Form } from 'semantic-ui-react'

interface SemanticLargeTextFormFieldProps {
  index: number
  value: string
  label: string
  placeholder: string
  required?: boolean
  error?: string
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void
}
export default function SemanticLargeTextFormField(props: SemanticLargeTextFormFieldProps) {
  const { label, placeholder, index, value, error, required, onChange } = props

  return <>
    <Form.TextArea required={required} key={index} fluid value={value}
      label={label} placeholder={placeholder ?? ''} error={error}
      onChange={onChange}
    /></>
}
