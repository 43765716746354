import { Button, Modal, SemanticCOLORS } from 'semantic-ui-react'

interface Action {
  color?: SemanticCOLORS
  onClick: any
  text: string
  disabled?: boolean
}
interface SemanticModalProps {
  header?: string
  content?: any
  actions?: Action[]
}
export default function SemanticModal(props: SemanticModalProps) {
  const { header, content, actions } = props

  return (
    <Modal size='mini' open={true} style={{ borderRadius: '8px'}}> {/* open=true as caller component is the one opening/closing the modal by rendering it or not*/}
      {
        header && <Modal.Header>{header}</Modal.Header>
      }
      {
        content && <Modal.Content>{content}</Modal.Content>
      }
      {
        actions && actions.length > 0 && <Modal.Actions>
          {
            actions.map((action, index) => <Button key={index} color={action.color}
              onClick={action.onClick} disabled={action.disabled}>{action.text}</Button>)
          }
        </Modal.Actions>
      }
    </Modal>
  )
}
