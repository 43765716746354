import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import UploadExcelFileWithWorksheetHandler from '../../../../components/upload-file-with-worksheet-handler'
import { ColumnType, Worksheet, WorksheetFields, WorksheetFormFields } from '../../../../interfaces/all'
import { getFileName } from '../../../../utils/excel'
import { getEntityWorksheets } from '../../../../api/entity-worksheet'
import { isFormDestination, isFormNewField, Mode, NewFieldOrDestinationFormField } from '../../../../interfaces/api/worksheet'
import { Form } from 'semantic-ui-react'
import SemanticTextFormField from '../../../../components/form/text-field'
import SemanticBooleanFormField from '../../../../components/form/boolean-field'
import SemanticDropdownFormField from '../../../../components/form/dropdown-field'
import { SemanticButton } from '../../../../components/buttons/buttons'
import { showErrorToast, showToast } from '../../../../components/toast'
import { importDynamicFromExcel } from '../../../../api/excel-imports'
import { HttpStatusCode } from '../../../../interfaces/status-codes'

export default function EntityImportDynamic() {
  const { entityId } = useParams()
  const navigate = useNavigate()
  const [worksheetName, setWorksheetName] = useState('')
  const [existentEntityWorksheetFields, setExistentEntityWorksheetFields] = useState<WorksheetFields[]>([])
  const [file, setFile] = useState<File | undefined>()
  const [formFields, setFormFields] = useState<NewFieldOrDestinationFormField[]>([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (entityId) {
      getEntityWorksheets(entityId).then(res => setExistentEntityWorksheetFields(((res.data) as Worksheet[]).flatMap(x => x.worksheet_fields)))
    }

  }, [entityId])

  function validateInputsAndSubmit() {
    if (!worksheetName) {
      showErrorToast('Worksheet name is required')
      return
    }

    for (let i = 0; i < formFields.length; i++) {
      const field = formFields[i]

      if (field.mode === Mode.NEW_FIELD) {
        if (!field.field_name) {
          showErrorToast(`Field name is required, check row ${i + 1}`)
          return
        }

        if (field.field_name === 'id') {
          showErrorToast('Field name cannot be "id"')
          return
        }

        if (!field.field_label) {
          showErrorToast(`Field label is required, check row ${i + 1}`)
          return
        }
      } else {
        if(!field.destination) {
          showErrorToast(`Destination has to be selected, check row ${i + 1}`)
          return
        }
      }
    }

    const fieldNames = formFields.map(x => x.mode === Mode.DESTINATION ? x.destination : x.field_name)
    if (fieldNames.length !== (new Set(fieldNames)).size) {
      showErrorToast('Field names cannot have duplicates')
      return
    }

    if(!fieldNames.some(x => x ==='Display Name')) {
      showErrorToast('Select a column to be the "Display Name"')
      return
    }
    
    importDynamic()
  }

  async function importDynamic() {
    setLoading(true)

    const { status, data } = await importDynamicFromExcel(
      file as File,
      entityId as string,
      worksheetName,
      formFields.filter(isFormNewField),
      Object.fromEntries(formFields.filter(isFormDestination).map(field => [field.source, field.destination]))
    )

    if(status === HttpStatusCode.CREATED) {
      showToast({ message: data?.message ?? 'Dynamic imported' })
      navigate(`/entity/${ entityId }`)
    } else {
      showErrorToast(data?.message ?? 'Dynamic could not be imported, please try again later')
    }
  }

  function handleCallback(file: File, excelFields: WorksheetFormFields[]) {
    setWorksheetName(getFileName(file))
    setFile(file)

    const formFieldsFromExcel: NewFieldOrDestinationFormField[] = excelFields.map(excelField => {
      const { field_name, field_label, field_type, required, display_order_sequence } = excelField

      if (existentEntityWorksheetFields.some(existentField => existentField.field_name.toLowerCase() === field_name.toLowerCase())) {
        return {
          mode: Mode.DESTINATION,
          source: field_name,
          destination: field_name
        }
      }

      return {
        mode: Mode.NEW_FIELD,
        source: field_name,
        field_name: field_name,
        field_label: field_label,
        field_type: field_type,
        required: required,
        display_order_sequence: display_order_sequence
      }
    })

    setFormFields(formFieldsFromExcel)
  }

  function handleWorksheetFieldChange(index: number, key: string, value: string | number | boolean) {
    const copy = [...formFields]

    // @ts-ignore
    copy[index][key] = value

    setFormFields(copy)
  }

  const existentEntityWorksheetFieldsOptions = [
    ...existentEntityWorksheetFields.map(x => x.field_name),
    'Display Name'
  ].map(x => ({ key: x, value: x, text: x }))

  const fieldTypeOptions = [
    ColumnType.TEXT,
    ColumnType.LARGE_TEXT,
    ColumnType.NUMBER,
    ColumnType.DATE,
    ColumnType.DATETIME
  ].map((x) => ({ key: x, value: x, text: x }))

  const formFieldsList = formFields.map((field, index) => <Form.Group widths={'equal'} key={index}>
    <SemanticBooleanFormField index={0} value={field.mode === Mode.NEW_FIELD} label={'Create new?'}
      onChange={(_e, data) => handleWorksheetFieldChange(index, 'mode', data.checked ? Mode.NEW_FIELD : Mode.DESTINATION)}
    />
    <SemanticTextFormField index={1} value={field.source} readonly label='Excel column' placeholder={'Excel column'} />
    {
      field.mode === Mode.DESTINATION ?
        <>
          <SemanticDropdownFormField index={2} value={field.destination} label={'System column'}
            dropdownItemProps={existentEntityWorksheetFieldsOptions} required
            onChange={(_event, data) => handleWorksheetFieldChange(index, 'destination', data.value as string)}
          />
        </> : <>
          <SemanticTextFormField index={2} value={field.field_name} required
            label='Field name' placeholder={'field name'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleWorksheetFieldChange(index, 'field_name', e.target.value)}
          />

          <SemanticTextFormField index={3} value={field.field_label} required
            label='Field label' placeholder={'field label'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleWorksheetFieldChange(index, 'field_label', e.target.value)}
          />

          <SemanticDropdownFormField index={4} value={field.field_type} label={'Field type'}
            dropdownItemProps={fieldTypeOptions} required
            onChange={(_event, data) => handleWorksheetFieldChange(index, 'field_type', data.value as string)}
          />

          <SemanticBooleanFormField index={5} value={field.required} label={'Required'}
            onChange={(_e, data) => handleWorksheetFieldChange(index, 'required', data.checked ?? false)}
          />
        </>
    }
  </Form.Group>)

  const formButtons = <div style={{ margin: '16px auto', textAlign: 'center' }}>
    <SemanticButton color='grey' text={'Cancel'} onClick={() => navigate(`/admin/entities/${entityId}`)} disabled={loading} />
    <SemanticButton text={'Create'} onClick={validateInputsAndSubmit} disabled={!(worksheetName) || loading} />
  </div>

  return <>
    <div style={{ maxWidth: '800px', margin: '12px auto' }}>
      <h2>
        <span style={{ paddingRight: '120px'}}>Import dynamic</span>
        <span style={{ float: 'right' }}>
          <UploadExcelFileWithWorksheetHandler disabled={false} callback={handleCallback} />
        </span>
      </h2>
      {
        file && <Form>
          <SemanticTextFormField index={0} value={worksheetName ?? ''} label={'Worksheet name'} required
            placeholder={'worksheet name'} onChange={(e) => setWorksheetName(e.target.value)}
          />
          {formFieldsList}
          {formButtons}
        </Form>
      }
    </div>
  </>
}
