import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SegmentGroupWithIcons, { SegmentGroupWithIconsProps } from '../../../components/segment-group-with-icon'
import { TransferPendingAcceptance } from '../../../interfaces/api/me'
import { getTransfersPendingAcceptance } from '../../../api/me'
import { completeTransfer } from '../../../api/transfer'
import { showErrorToast, showToast } from '../../../components/toast'


export default function TransfersPendingAcceptance() {
  const [transfersPendingAcceptance, setTransfersPendingAcceptance] = useState<TransferPendingAcceptance[]>([])
  const [acceptingTransferWithId, setAcceptingTransferWithId] = useState<number | undefined>()
  const navigate = useNavigate()
  
  useEffect(() => {
    getTransfersPendingAcceptance().then(res => setTransfersPendingAcceptance(res.data))
  }, [])

  async function acceptPendingTransfer(id: number) {
    setAcceptingTransferWithId(id)

    const { status } = await completeTransfer(id)

    if (status === 200) {
      showToast({ message: 'Transfer completed!' })
    } else {
      showErrorToast('Could not complete transfer')
    }

    getTransfersPendingAcceptance().then(res => {
      setTransfersPendingAcceptance(res.data)
      setAcceptingTransferWithId(undefined)
    })
  }

  function getEvidencePath(transfer: TransferPendingAcceptance) {
    if(transfer.case_id) {
      return `/cases/${transfer.case_id}/evidences/${transfer.evidence_id}/edit`
    }

    return `/evidences/${transfer.evidence_id}/edit`
  }

  const transfersPendingAcceptanceProps: SegmentGroupWithIconsProps = {
    title: 'Transfers pending acceptance',
    segmentItems: transfersPendingAcceptance.map(transfer => ({
      text: transfer.display_text,
      onClick: () => navigate(getEvidencePath(transfer)),
      iconProps: {
        color: 'green',
        name: 'check',
        onClick: () => acceptPendingTransfer(transfer.id),
        disabled: acceptingTransferWithId !== undefined,
        loading: acceptingTransferWithId === transfer.id
      }
    }))
  }

  return <SegmentGroupWithIcons {...transfersPendingAcceptanceProps} />
}
