import { useEffect, useState } from 'react'
import { useLocation, useNavigate, useParams } from 'react-router-dom'

import { SemanticButton } from '../../components/buttons/buttons'
import {
  getPackage,
  getImportedPackages as getImportedPackagesAPICall,
  deletePackage as deletePackageApiCall,
  importPackage as importPackageApiCall
} from '../../api/packages'
import { Package, Permissions } from '../../interfaces/all'
import SemanticList from '../../components/list'
import WorksheetComponent from '../../components/worksheet'
import { showErrorToast, showToast } from '../../components/toast'
import { isSysAdmin } from '../../utils/permissions'
import { getPermissions } from '../../api/me'
import ConfirmationModal from '../../components/modals/confirmation-modal'
import InformationModal from '../../components/modals/information-modal'
import { useAuth } from '../../hooks/auth'
import { HttpStatusCode } from '../../interfaces/status-codes'


export default function PackagePage() {
  const { packageId } = useParams()
  const { state } = useLocation()
  const [packageData, setPackageData] = useState<Package | undefined>()
  const [permissions, setPermissions] = useState<Permissions | undefined>()
  const [loading, setLoading] = useState(false)
  const [displayImportPackageConfirmationModal, setDisplayImportPackageConfirmationModal] = useState(false)
  const [displayDeletePackageConfirmationModal, setDisplayDeletePackageConfirmationModal] = useState(false)
  const [importingPackage, setImportingPackage] = useState(false)
  const [deletingPackage, setDeletingPackage] = useState(false)
  const [displayPackageImportedModal, setDisplayPackageImportedModal] = useState(false)
  const [importedPackages, setImportedPackages] = useState<number[]>([])
  const { organizationName } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    async function getImportedPackages() {
      if (state) {
        return state
      } else {
        return getImportedPackagesAPICall().then(res => res.data)
      }
    }

    async function loadData() {
      setLoading(true)

      const [getPackageResponse, getImportedPackagesResponse, getPermissionsResponse] = await Promise.all([
        getPackage(packageId as unknown as number),
        getImportedPackages(),
        getPermissions()
      ])

      setPackageData(getPackageResponse.data)
      setImportedPackages(getImportedPackagesResponse)
      setPermissions(getPermissionsResponse.data)
      setLoading(false)
    }

    loadData()
  }, [packageId, state])

  async function importPackage(packageId: string) {
    setDisplayImportPackageConfirmationModal(false)
    setImportingPackage(true)

    const { status, data } = await importPackageApiCall(packageId)

    if (status === 201) {
      showToast({ message: 'Package imported!' })
      setDisplayPackageImportedModal(true)
    } else {
      showErrorToast(data?.message ?? 'Error when importing a package')
    }

    setImportingPackage(false)
  }

  async function deletePackage(packageId: string) {
    setDisplayDeletePackageConfirmationModal(false)
    setDeletingPackage(true)

    const { status, data } = await deletePackageApiCall(packageId)

    if (status === HttpStatusCode.NO_CONTENT) {
      showToast({ message: 'Package deleted!' })
      navigate('/packages')
    } else {
      showErrorToast(data?.message ?? 'Error when deleting the package')
    }

    setDeletingPackage(false)
  }

  const packageContent = packageData && <>
    <SemanticList items={packageData?.entities.map(entity => <>
      <div style={{ marginBottom: '12px' }}>
        <h3 style={{ margin: 'auto' }}>{`${entity.data_name} (${entity.data_type})`}</h3>
        <SemanticList items={entity.worksheets.map(worksheet => <>
          <WorksheetComponent worksheet_id={worksheet.worksheet_id} worksheet_name={worksheet.worksheet_name}
            worksheet_fields={worksheet.worksheet_fields} entities={packageData.entities} />
        </>)} />
      </div>
    </>)} />
  </>

  const getEntityNamesList = () => {
    const entities = packageData?.entities ?? []

    let str = ''

    for (let i = 0; i < entities.length; i++) {
      if (i === entities.length - 1) {
        str += entities[i].data_name
      } else {
        str += `${entities[i].data_name}, `
      }
    }

    return str
  }

  const packageAlreadyImported = importedPackages.some(p => p.toString() === packageId)

  return <>
    <div style={{ maxWidth: '600px', margin: '30px auto' }}>
      <div style={{ display: 'flex' }}>
        <div>
          {
            packageData && <>
              <h1>{`${packageData.package_name}`}</h1>
              <h5>{`Created by ${ packageData?.created_by_organization_name}`}</h5>
            </>
          }
        </div>
        <div style={{ marginLeft: 'auto' }}>
          <SemanticButton text={'Return'} color='grey' onClick={() => navigate('/packages')} disabled={importingPackage} />
          <SemanticButton text={packageAlreadyImported ? 'Imported' : 'Import'} onClick={() => setDisplayImportPackageConfirmationModal(true)} loading={importingPackage}
            disabled={(!(isSysAdmin(permissions)) || importingPackage || packageAlreadyImported)} />
          {
            packageData && organizationName && packageData.created_by_organization_name === organizationName &&
            <SemanticButton text={'Delete'} color={'red'} loading={deletingPackage}
              onClick={() => setDisplayDeletePackageConfirmationModal(true)} />
          }
        </div>

      </div>

      <div style={{ marginTop: '16px' }}>
        {
          loading ? 'loading package details...' : packageContent
        }
      </div>
    </div>
    {
      displayImportPackageConfirmationModal && <ConfirmationModal header={`Import package ${packageData?.package_name}`}
        content={'Are you sure you want to import this package? It would create the listed entities as well as their worksheets with the same names and fields.'}
        onCancel={() => setDisplayImportPackageConfirmationModal(false)} onSaved={() => importPackage(packageId as string)} />
    }
    {
      displayPackageImportedModal && <InformationModal
        message={`Great! You've imported the package ${packageData?.package_name} and entities ${getEntityNamesList()} are ready to be used. If you need more assistance, please open the guides section on the top-right corner of this page`}
        onClick={() => {
          navigate('/packages')
          setDisplayPackageImportedModal(false)
        }} />
    }
    {
      displayDeletePackageConfirmationModal && <ConfirmationModal header={packageData?.package_name ?? ''} content={'Are you sure you want to delete this package'}
        onCancel={() => setDisplayDeletePackageConfirmationModal(false)} onSaved={() => deletePackage(packageId as string)} />
    }
  </>
}
