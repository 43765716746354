import React, { useEffect, useState } from 'react'
import jwtDecode from 'jwt-decode'

import { getAccessToken, removeAccessToken, setAccessToken } from '../utils/sessionStorage'

interface AuthContextProps {
  isLoggedIn: boolean
  tokenLoaded: boolean
  username: string | null
  organizationName: string | null
  onLogin: any
  onLogout: any
}
export const AuthContext = React.createContext<AuthContextProps>({} as AuthContextProps)

export const AuthProvider = ({ children }: any) => {
  const [token, setToken] = React.useState<string | null>(null)
  const [username, setUsername] = useState<string | null>(null)
  const [organizationName, setOrganizationName] = useState<string | null>(null)
  const [tokenLoaded, setTokenLoaded] = useState(false)

  useEffect(() => {
    setToken(getAccessToken())
    setTokenLoaded(true)
  }, [])

  useEffect(() => {
    if(token) {
      const { username, organization_name } = jwtDecode(token) as { username: string, organization_name: string }
  
      setUsername(username)
      setOrganizationName(organization_name)
      setTokenLoaded(true)
    }
  }, [token])

  const handleLogin = (token: string) => {
    setAccessToken(token)
    setToken(token)
  }

  const handleLogout = () => {
    removeAccessToken()
    setToken(null)
  }

  const value = {
    isLoggedIn: !!token,
    tokenLoaded,
    username,
    organizationName,
    onLogin: handleLogin,
    onLogout: handleLogout
  }

  return (
    <AuthContext.Provider value={value} >
      {children}
    </AuthContext.Provider>
  )
}
