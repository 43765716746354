import { List } from 'semantic-ui-react'

interface SemanticListProps {
  bulleted?: boolean
  items: any[]
}
export default function SemanticList(props: SemanticListProps) {
  const { bulleted = false, items } = props

  return <>
    <List bulleted={bulleted}>
      {items.map((item, index) => <List.Item key={index}>{item}</List.Item>)}
    </List>
  </>
}
