import { Checkbox, CheckboxProps, Form } from 'semantic-ui-react'

import { getDisplayedBooleanValue } from '../../interfaces/worksheet'

interface SemanticBooleanFormFieldProps {
  index: number
  label: string
  value: boolean
  required?: boolean
  disabled?: boolean
  onChange: (event: React.FormEvent<HTMLInputElement>, data: CheckboxProps) => void
}
export default function SemanticBooleanFormField(props: SemanticBooleanFormFieldProps) {
  const { index, label, required, value, disabled, onChange } = props

  return <>
    <Form.Field required={required} key={index}>
      <label>{label}</label>

      <Checkbox checked={value} toggle label={getDisplayedBooleanValue(value)} onChange={onChange} disabled={disabled} />
    </Form.Field>
  </>
}
