import { BuildLeftAlignedDiv, BuildDiv, BuildTitle } from './utils/utils';

export default function Users() {
  return (
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Manage Users' />
      <BuildLeftAlignedDiv content='**Invite New Users**' />
      <BuildDiv content='- Enter the email address of the user you want to invite.' />
      <BuildDiv content='- Click the "Invite" button.' />
      <br />
      <BuildLeftAlignedDiv content='**Manage Existing Users**' />
      <BuildDiv content='A table displays a list of current users with their email addresses and statuses (Active, Invited, Awaiting Admin Approval). You can approve or reject users using the checkboxes or "x" buttons.' />
      <p>**Return to Admin Hub**</p>
    </div>
  );
}
