import { useEffect, useState } from 'react'
import { Button, Modal, Checkbox } from 'semantic-ui-react'
import { showToast } from '../toast'
import { getCaseEvidences } from '../../api/evidences/evidences'
import { useParams } from 'react-router-dom'
import { getServiceEvidences, updateServiceEvidences } from '../../api/service-evidences'
import LinePlaceHolder from '../line-placeholder'

interface Evidences {
  id: number
  number: string
}
interface Service {
  id: number
  number: string
}
interface ServiceEvidencesModalProps {
  close: Function
  service: Service
}
export default function ServiceEvidencesModal(props: ServiceEvidencesModalProps) {
  const { close, service } = props
  const [allCaseEvidences, setAllCaseEvidences] = useState<Evidences[]>([])
  const [newEvidenceIds, setNewEvidenceIds] = useState<number[]>([])
  const { caseId } = useParams()
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    async function fetch() {
      setLoading(true)

      const [caseEvidencesResponse, serviceEvidencesResponse] = await Promise.all([
        getCaseEvidences(caseId as string),
        getServiceEvidences(service.id)
      ])

      const caseEvidences = caseEvidencesResponse.raw_data.map(evidence => ({ id: evidence.id, number: evidence['Evidence #'] }))
      setAllCaseEvidences(caseEvidences)

      const serviceEvidencesIds = serviceEvidencesResponse.map(serviceEvidence => serviceEvidence.evidence_id)
      setNewEvidenceIds(serviceEvidencesIds)

      setLoading(false)
    }

    fetch()
  }, [caseId, service.id])

  function addOrRemoveEvidenceId(evidenceId: number) {
    const index = newEvidenceIds.indexOf(evidenceId)

    if (index > -1) {
      setNewEvidenceIds(newEvidenceIds.filter(id => id !== evidenceId))
    } else {
      setNewEvidenceIds([...newEvidenceIds, evidenceId])
    }
  }

  async function saveAndClose() {
    const { status } = await updateServiceEvidences(service.id, newEvidenceIds)

    if(status === 200) {
      showToast({ message: 'Related evidences saved' })
    }

    close()
  }

  const allCaseEvidencesCheckBoxes = <div className="ui list">
    {allCaseEvidences.map(evidence => {
      const defaultChecked = newEvidenceIds.includes(evidence.id)

      return <div className="item" key={evidence.id}>
        <Checkbox defaultChecked={defaultChecked} label={evidence.number}
          onChange={() => addOrRemoveEvidenceId(evidence.id)} />
      </div>
    })}
  </div>

  const modalContent = allCaseEvidences?.length > 0 ? allCaseEvidencesCheckBoxes : 'No evidences to select'

  return (
    <Modal size='mini' open={true}> {/* open=true as caller component is the one opening/closing the modal */}
      <Modal.Header>Select service "{service.number}" evidences:</Modal.Header>
      <Modal.Content>
        { loading ? <LinePlaceHolder/> : modalContent}
      </Modal.Content>
      <Modal.Actions>
        <Button color='grey' onClick={() => close()}>Cancel</Button>
        <Button color='blue' onClick={saveAndClose}>Save</Button>
      </Modal.Actions>
    </Modal>
  )
}
