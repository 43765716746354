import { Buffer } from 'buffer'

import ExcelJS from 'exceljs'


export const getFileHeaders = async(file: File): Promise<string[] | undefined> => {
  try {
    const workbook = new ExcelJS.Workbook()
    const arrayBuffer = await file.arrayBuffer()
    const buffer = Buffer.from(arrayBuffer)

    await workbook.xlsx.load(buffer)

    const fileHeaders: string[] = []

    const worksheet = workbook.worksheets[0]

    if(!worksheet) {
      return []
    }

    const row = worksheet.getRow(1)
  
    if (row === null || !row.values || !row.values.length) return []

    //@ts-ignore
    for (let i = 1; i < row.values.length; i++) {
      const cell = row.getCell(i)
      fileHeaders.push(cell.text)
    }

    return fileHeaders
  } catch (err) {
    console.error(err)
    return undefined
  }
}

export const getFileName = (file: File): string => {
  try {
    return file.name.split('.xlsx')[0]
  } catch (err) {
    console.error(err)
    return ''
  }
}


interface DownloadAsExcelProps {
  headers: string[]
  rows: (string | number)[][]
}
export const downloadAsExcel = async (props: DownloadAsExcelProps): Promise<void> =>  {
  const { headers, rows } = props

  const workbook = new ExcelJS.Workbook()
  const worksheet = workbook.addWorksheet('sheet1')
  
  worksheet.columns = headers.map(header => ({ header: header, key: header }))

  rows.forEach(row => worksheet.addRow(row))

  const buffer = await workbook.xlsx.writeBuffer()

  const link = document.createElement('a')
  link.href = window.URL.createObjectURL(new Blob([buffer]))
  link.download = 'infinite_system.xlsx'
  link.click()
}
