import { Icon, SemanticCOLORS, SemanticICONS } from 'semantic-ui-react'

export interface SemanticIconProps {
  onClick?: any
  color: SemanticCOLORS
  name: SemanticICONS
  disabled?: boolean
  loading?: boolean
  overrideStyle?: any
}
export default function SemanticIcon(props: SemanticIconProps) {
  const { onClick: onClickInput, color, name, disabled = false, loading = false, overrideStyle } = props

  const pointerStyle = disabled ? {} : { cursor: 'pointer' }
  const style = { ...pointerStyle, ...overrideStyle }
  const onClick = disabled ? undefined : onClickInput

  return <>
    <span onClick={onClick} style={style}>
      <Icon color={color} name={name} disabled={disabled} loading={loading}/>
    </span>
  </>
}
