import { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { useNavigate, useParams } from 'react-router-dom'

import { importCasesFromExcel } from '../../../../api/excel-imports'
import { showErrorToast, showToast } from '../../../../components/toast'
import { HttpStatusCode } from '../../../../interfaces/status-codes'
import SemanticTextFormField from '../../../../components/form/text-field'
import { ColumnType, Worksheet, WorksheetFields, WorksheetFormFields } from '../../../../interfaces/all'
import SemanticDropdownFormField from '../../../../components/form/dropdown-field'
import SemanticBooleanFormField from '../../../../components/form/boolean-field'
import { SemanticButton } from '../../../../components/buttons/buttons'
import { getEntityWorksheets } from '../../../../api/entity-worksheet'
import { NewFieldOrDestinationFormField, Mode, isFormDestination, isFormNewField } from '../../../../interfaces/api/worksheet'
import { getFileName } from '../../../../utils/excel'
import UploadExcelFileWithWorksheetHandler from '../../../../components/upload-file-with-worksheet-handler'



export default function EntityImportCases() {
  const { entityId } = useParams()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [file, setFile] = useState<File | undefined>()
  const [existentEntityWorksheetFields, setExistentEntityWorksheetFields] = useState<WorksheetFields[]>([])
  const [worksheetName, setWorksheetName] = useState('')
  const [formFields, setFormFields] = useState<NewFieldOrDestinationFormField[]>([])

  useEffect(() => {
    if (entityId) {
      getEntityWorksheets(entityId).then(res => setExistentEntityWorksheetFields(((res.data) as Worksheet[]).flatMap(x => x.worksheet_fields)))
    }

  }, [entityId])

  function validateInputsAndSubmit() {
    if (!worksheetName) {
      showErrorToast('Worksheet name is required')
      return
    }

    for (let i = 0; i < formFields.length; i++) {
      const field = formFields[i]

      if (field.mode === Mode.NEW_FIELD) {
        if (!field.field_name) {
          showErrorToast(`Field name is required, check row ${i + 1}`)
          return
        }

        if(field.field_name === 'id') {
          showErrorToast('Field name cannot be "id"')
          return
        }

        if(field.field_name === 'Case #') { 
          showErrorToast('New field name cannot be "Case #", select the system column instead')
          return
        }

        if (!field.field_label) {
          showErrorToast(`Field label is required, check row ${i + 1}`)
          return
        }
      } else {
        if(!field.destination) {
          showErrorToast(`Destination has to be selected, check row ${i + 1}`)
          return
        }
      }
    }

    const fieldNames = formFields.map(x => x.mode === Mode.DESTINATION ? x.destination : x.field_name)
    if (fieldNames.length !== (new Set(fieldNames)).size) {
      showErrorToast('Field names cannot have duplicates')
      return
    }

    const formDestinationFields = formFields.filter(isFormDestination)
    if(!formDestinationFields.some(x => x.destination === 'Case #')) {
      showErrorToast('A column has to map to the "Case #"')
      return
    }

    importCases()
  }

  async function importCases() {
    setLoading(true)

    const { status, data } = await importCasesFromExcel(
      file as File,
      entityId as string,
      worksheetName,
      formFields.filter(isFormNewField),
      Object.fromEntries(formFields.filter(isFormDestination).map(field => [field.source, field.destination]))
    )

    if (status === HttpStatusCode.CREATED) {
      showToast({ message: data?.message ?? 'Cases imported' })
      navigate('/cases')
    } else {
      showErrorToast(data?.message ?? 'Cases could not be imported, please try again later')
    }

    setLoading(false)
  }

  async function handleCallback(file: File, excelFields: WorksheetFormFields[]) {
    setWorksheetName(getFileName(file))
    setFile(file)

    const formFieldsFromExcel: NewFieldOrDestinationFormField[] = excelFields.map(excelField => {
      const { field_name, field_label, field_type, required, display_order_sequence } = excelField
    
      const caseNumberGuesses = ['case #', 'casenumber', 'case number', 'case_number', 'case-number', 'case id', 'case_id']
      if (caseNumberGuesses.includes(field_name.toLowerCase())) {
        return {
          mode: Mode.DESTINATION,
          source: field_name,
          destination: 'Case #'
        }
      } else if (existentEntityWorksheetFields.some(existentField => existentField.field_name.toLowerCase() === field_name.toLowerCase())) {
        return {
          mode: Mode.DESTINATION,
          source: field_name,
          destination: field_name
        }
      }

      return {
        mode: Mode.NEW_FIELD,
        source: field_name,
        field_name: field_name,
        field_label: field_label,
        field_type: field_type,
        required: required,
        display_order_sequence: display_order_sequence
      }
    })

    setFormFields(formFieldsFromExcel)
  }

  function handleWorksheetFieldChange(index: number, key: string, value: string | number | boolean) {
    const copy = [...formFields]

    // @ts-ignore
    copy[index][key] = value

    setFormFields(copy)
  }

  const existentEntityWorksheetFieldsOptions = [
    ...existentEntityWorksheetFields.map(x => x.field_name),
    'Case #'
  ].map(x => ({ key: x, value: x, text: x }))

  const fieldTypeOptions = [
    ColumnType.TEXT,
    ColumnType.LARGE_TEXT,
    ColumnType.NUMBER,
    ColumnType.DATE,
    ColumnType.DATETIME
  ].map((x) => ({ key: x, value: x, text: x }))

  const formFieldsList = formFields.map((field, index) => <Form.Group widths={'equal'} key={index}>
    <SemanticBooleanFormField index={0} value={field.mode === Mode.NEW_FIELD} label={'Create new?'}
      onChange={(_e, data) => handleWorksheetFieldChange(index, 'mode', data.checked ? Mode.NEW_FIELD : Mode.DESTINATION)}
    />
    <SemanticTextFormField index={1} value={field.source} readonly label='Excel column' placeholder={'Excel column'} />
    {
      field.mode === Mode.DESTINATION ?
        <>
          <SemanticDropdownFormField index={2} value={field.destination} label={'System column'}
            dropdownItemProps={existentEntityWorksheetFieldsOptions} required
            onChange={(_event, data) => handleWorksheetFieldChange(index, 'destination', data.value as string)}
          />
        </> : <>
          <SemanticTextFormField index={2} value={field.field_name} required
            label='Field name' placeholder={'field name'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleWorksheetFieldChange(index, 'field_name', e.target.value)}
          />

          <SemanticTextFormField index={3} value={field.field_label} required
            label='Field label' placeholder={'field label'}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleWorksheetFieldChange(index, 'field_label', e.target.value)}
          />

          <SemanticDropdownFormField index={4} value={field.field_type} label={'Field type'}
            dropdownItemProps={fieldTypeOptions} required
            onChange={(_event, data) => handleWorksheetFieldChange(index, 'field_type', data.value as string)}
          />

          <SemanticBooleanFormField index={5} value={field.required} label={'Required'}
            onChange={(_e, data) => handleWorksheetFieldChange(index, 'required', data.checked ?? false)}
          />
        </>
    }
  </Form.Group>)

  const formButtons = <div style={{ margin: '16px auto', textAlign: 'center' }}>
    <SemanticButton color='grey' text={'Cancel'} onClick={() => navigate(`/admin/entities/${entityId}`)} disabled={loading} />
    <SemanticButton text={'Create'} onClick={validateInputsAndSubmit} disabled={!(worksheetName) || loading} />
  </div>

  return <>
    <div style={{ maxWidth: '800px', margin: '12px auto' }}>
      <h2>
        <span style={{ paddingRight: '120px'}}>Import cases</span>
        <span style={{ float: 'right' }}>
          <UploadExcelFileWithWorksheetHandler disabled={false} callback={handleCallback} />
        </span>
      </h2>

      {
        file && <Form>
          <SemanticTextFormField index={0} value={worksheetName ?? ''} label={'Worksheet name'} required
            placeholder={'worksheet name'} onChange={(e) => setWorksheetName(e.target.value)}
          />
          <div style={{ marginBottom: '8px' }}>Here you should map which column in the spreadsheet links to which field in the system. For instance, the case number should be mapped to "Case #". If the column in the spreadsheet does not exist in the system yet, you can create a new one.</div>
          {formFieldsList}
          {formButtons}
        </Form>
      }
    </div>
  </>
}
