import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import { getService, updateService } from '../../api/services/services'
import { showErrorToast, showToast } from '../../components/toast'
import { Column, DataType, GenericGetResponse, Metadata, RawData, isDictionaryColumn, isListColumn } from '../../interfaces/all'
import SemanticForm, { getFormFieldsFromColumns } from '../../components/form/form'
import SemanticMenu from '../../components/menu'
import { createServiceDetails, deleteServiceDetails, getServiceDetails } from '../../api/services/service-details'
import DynamicGrid from '../../components/ag-grid/dynamic-grid'
import { updateDynamic } from '../../api/dynamic/dynamics'
import { canDelete, canEdit, isSysEdit } from '../../utils/permissions'
import Actions from '../../components/actions'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import EditServicePageGuides from '../../guides/edit-service'
import SemanticTooltip from '../../components/tooltips/generic'

export default function CaseServiceEditPage() {
  const { caseId, serviceId } = useParams()
  const [serviceData, setServiceData] = useState<RawData | undefined>()
  const [metadata, setMetadata] = useState<Metadata>({ columns: [] })
  const [activeList, setActiveList] = useState<{ fieldName: string, fieldId: number } | undefined>()
  const [actionsTabSelected, setActionsTabSelected] = useState<boolean>(false)
  const [details, setDetails] = useState<GenericGetResponse | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    getService(serviceId as string).then(response => {
      setServiceData(response.raw_data[0])
      setMetadata(response.meta)
    })
  }, [serviceId])

  useEffect(() => {
    if (activeList?.fieldId) {
      getServiceDetails(serviceId as string, activeList.fieldId as unknown as string)
        .then(response => setDetails(response))
    }
  }, [serviceId, activeList])

  async function reloadServiceAndDetails() {
    getService(serviceId as string).then(response => {
      setServiceData(response.raw_data[0])
      setMetadata(response.meta)
    })

    if (activeList?.fieldId) {
      getServiceDetails(serviceId as string, activeList.fieldId as unknown as string)
        .then(response => setDetails(response))
    }
  }

  async function save(body: any) {
    const { status, data } = await updateService({
      'Service #': serviceData?.['Service #'],
      'Service Type': serviceData?.['Service Type'],
      ...body
    }, serviceId as string)

    if (status === 200) {
      showToast({ message: 'Service updated', position: 'top-left' })

      reloadServiceAndDetails()
    } else {
      showErrorToast(data?.message ?? 'Service not updated')
    }
  }

  let formFields = getFormFieldsFromColumns(metadata.columns.filter(col => col.name !== 'Service #' && col.name !== 'Service Type') ?? [])
  formFields = formFields.map(formField => ({
    ...formField,
    defaultValue: serviceData ? serviceData[formField.key] : undefined
  }))

  const lists = metadata.columns.filter(isListColumn)
    .map(col => ({
      header: col.name,
      fieldId: col.field_id
    })) ?? []

  async function deleteDetails(dynamicId: number) {
    const { status } = await deleteServiceDetails(dynamicId as unknown as string, serviceId as string, activeList?.fieldId.toString() as string)

    if (status === 204) {
      showToast({ message: `${activeList?.fieldName} deleted` })
    }

    reloadServiceAndDetails()
  }

  async function createDetails(data: any) {
    const { status } = await createServiceDetails(serviceId as string, activeList?.fieldId.toString() as string, data)

    if (status === 201) {
      showToast({ message: `${activeList?.fieldName} created` })
    }

    reloadServiceAndDetails()
  }

  async function updateDetails(data: any) {
    const { status } = await updateDynamic(data as RawData)

    if (status === 200) {
      showToast({ message: `${activeList?.fieldName} updated` })
    }

    reloadServiceAndDetails()
  }

  const userCanDelete = canDelete(serviceData?.['Service Type'] as number, metadata.permissions)
  const userCanEdit = canEdit(serviceData?.['Service Type'] as number, metadata.permissions)

  function getServiceType(columns: Column[], service: RawData | undefined): string | undefined {
    const options = columns.filter(isDictionaryColumn).find(col => col.name === 'Service Type')?.options

    return service && options && options[service['Service Type'] as number]
  }

  const pageContent = <>
    <Grid columns={2} divided style={{ height: '100%', paddingTop: '8px' }}>
      <Grid.Row style={{ height: '100%', overflow: 'auto' }}>
        <Grid.Column width={5}>
          <div style={{ fontSize: '28px', fontWeight: 600, marginBottom: '4px', textAlign: 'center' }}>
            {serviceData ? `${serviceData?.['Service #'] ?? ''} (${getServiceType(metadata.columns, serviceData)})` : 'loading service ...'}
            {
              isSysEdit(metadata.permissions) && serviceData && <span style={{ paddingLeft: '8px', cursor: 'pointer' }}>
                <SemanticTooltip text={'Modify Entity Structure'} iconProps={{ name: 'edit', color: 'blue', onClick: () => navigate(`/admin/entities/${serviceData['Service Type']}`) }} />
              </span>
            }
          </div>
          <div style={{ padding: '16px' }}>
            <SemanticForm formFields={formFields}
              onCancel={() => navigate(`/cases/${caseId}/services`)} onSubmit={(data: any) => save(data)} />
          </div>
        </Grid.Column>
        <Grid.Column width={11}>
          <div style={{ padding: '8px', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <SemanticMenu items={
              [
                ...lists.map(list => ({
                  name: list.header,
                  onClick: () => {
                    setActiveList({
                      fieldName: list.header,
                      fieldId: list.fieldId as number
                    })
                    setActionsTabSelected(false)
                  }
                })),
                {
                  name: 'actions',
                  onClick: () => {
                    setActiveList(undefined)
                    setActionsTabSelected(true)
                  }
                }]
            } />
            {
              activeList && details && details?.meta && details.raw_data &&
              <DynamicGrid dataType={DataType.DYNAMIC}
                rows={details.raw_data}
                columns={details.meta.columns}
                onDelete={(dynamicId) => deleteDetails(dynamicId)}
                onCancel={() => reloadServiceAndDetails()}
                onCreate={(data) => createDetails(data)}
                onUpdate={(data) => updateDetails(data)}
                canDelete={userCanDelete}
                canEdit={userCanEdit} />
            }
            {
              actionsTabSelected && <Actions
                serviceId={serviceId as string}
                canDelete={userCanDelete}
                canEdit={userCanEdit}
              />
            }
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
  return <SidebarWrapperForPage page={pageContent} sidebar={<EditServicePageGuides />} />
}
