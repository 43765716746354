import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import PinnedItems from '../pinned-items'
import SidebarWrapperForPage from '../../../components/sidebar-wrapper-for-page'
import AllDynamicHomePageGuides from '../../../guides/home/all-dynamic'
import RecentActivities from './recent-activities'
import Hint from '../hint'
import { getRecentActivities } from '../../../utils/localStorage/recent-activities'
import { SemanticButton } from '../../../components/buttons/buttons'


export default function AllDynamicHomePage() {
  const navigate = useNavigate()
  const recentActivities = getRecentActivities()
  const displayHint = !(recentActivities && recentActivities.length > 0)

  const pageContent = <>
    <div style={{ padding: '8px', height: '100%' }}>
      <Grid columns={2} divided>
        <Grid.Row>
          <Grid.Column>
            {displayHint && <Hint text={'Hint: Start by building new data structures (Entity) in the Admin Hub in the menu above'} />}
            <div style={{ margin: '0px', textAlign: 'center', display: 'flex' }}>
              <div style={{ margin: '0 12px' }}>
                <SemanticButton onClick={() => navigate('/dynamic')} text='All dynamic entities' />
              </div>
              <div style={{ margin: '0 12px' }}>
                <SemanticButton onClick={() => navigate('/admin/entities/create')} text='Create dynamic data entity' />
              </div>
            </div>
            <RecentActivities />
          </Grid.Column>
          <Grid.Column>
            <PinnedItems />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>

  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<AllDynamicHomePageGuides />} />
}
