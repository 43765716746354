import { Form } from 'semantic-ui-react'

import { SemanticButton } from '../../components/buttons/buttons'
import { useState } from 'react'
import GenericConfirmation from '../../components/confirmation'
import { triggerResetPasswordEmail } from '../../api/users'
import { showErrorToast } from '../../components/toast'
import { validateEmail } from '../../utils/utils'
import { useNavigate } from 'react-router-dom'


export default function ForgotPasswordPage() {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState<string | undefined>()
  const [loading, setLoading] = useState(false)
  const [submited, setSubmited] = useState(false)
  const navigate = useNavigate()

  async function handleSubmit() {
    if(!validateEmail(email)) {
      setEmailError('Email is not valid')
      return
    }

    setLoading(true)

    const { status, data } = await triggerResetPasswordEmail(email)

    if(status === 202) {
      setSubmited(true)
    } else {
      showErrorToast(data?.message ?? 'Failed to submit your request')
    }

    setLoading(false)
  }

  const forgotPasswordForm = <div style={{ maxWidth: '300px', margin: '30px auto' }}>
    <h1>Forgot password</h1>
    <Form>
      <Form.Input fluid label='Email' placeholder='enter your email ...' value={email} onChange={(e) => {
        setEmail(e.target.value)
        setEmailError(undefined)
      }} required error={emailError}/>
      <SemanticButton text={'Cancel'} onClick={() => navigate('/home')} disabled={loading} type='button' color='grey'/>
      <SemanticButton text={'Submit'} onClick={handleSubmit} disabled={!email} type='submit' loading={loading} />
    </Form>
  </div>

  const passwordSubmitedConfirmation = <GenericConfirmation title='Please check your email!'
    link={{
      path: '/home',
      message: 'Go to login page'
    }} />

  return <>{submited ? passwordSubmitedConfirmation : forgotPasswordForm}</>
}
