import React, { useState } from 'react'
import { setRecentCase } from '../utils/localStorage/recent-cases'

interface CaseContextProps {
  id: number | undefined
  number: string | undefined
  selectCase: (id: number, number: string) => void
}
export const CaseContext = React.createContext<CaseContextProps>({} as CaseContextProps)

export const CaseProvider = ({ children }: any) => {
  const [selectedCase, setSelectedCase] = useState<{ id: number, number: string } | null>(null)

  function selectCase(id: number, number: string) {
    setSelectedCase({ id, number })
    setRecentCase({ id, caseNumber: number })
  }

  const value = {
    id: selectedCase?.id,
    number: selectedCase?.number,
    selectCase
  }

  return (
    <CaseContext.Provider value={value} >
      {children}
    </CaseContext.Provider>
  )
}
