import { request } from '../common'
import { Column, GenericGetResponse, GenericGridResponse } from '../../interfaces/all'
import { AxiosResponse } from 'axios'
import { EvidenceItem } from '../../interfaces/api/evidences'

const evidencesPath = 'evidences'

export const getEvidences = async (): Promise<GenericGridResponse<EvidenceItem[]>> => {
  const { data } = await request({
    method: 'GET',
    path: `${ evidencesPath }`
  })

  return data as GenericGridResponse<EvidenceItem[]>
}

export const getCaseEvidences = async (caseId: string): Promise<GenericGridResponse<EvidenceItem[]>> => {
  const { data } = await request({
    method: 'GET',
    path: `${ evidencesPath }?caseId=${caseId}&`
  })
 
  return data as GenericGridResponse<EvidenceItem[]>
}

export const getEvidence = async(evidenceId: string): Promise<GenericGetResponse> => {
  const { data } = await request({
    method: 'GET',
    path: `${evidencesPath}/${ evidenceId }`
  }) 

  return data as GenericGetResponse
}

export const getEvidenceNumbersAndBarcodes = async (): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: `${evidencesPath}/numbers_and_barcodes`
  })
}

// TODO - there should be an API for that
// {...}/evidences/columns
export const getEvidenceColumns = async(caseId: string): Promise<Column[]> => {
  const { meta } = await getCaseEvidences(caseId)

  return meta.columns
}

export const createEvidence = async (body: object): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: evidencesPath,
    body
  })
}

export const updateEvidence = async (body: object, evidenceId: string): Promise<AxiosResponse> => {
  return await request({
    path: `${evidencesPath}/${ evidenceId }`,
    method: 'PUT',
    body
  })
}

export const deleteEvidence = async (evidenceId: number): Promise<AxiosResponse> => {
  return await request({
    path: `${evidencesPath}/${evidenceId}`,
    method: 'DELETE'
  })
}

export const getEvidenceChainOfCustody = async (evidenceId: string): Promise<AxiosResponse> => {
  return await request({
    path: `${evidencesPath}/${evidenceId}/chain-of-custody`,
    method: 'GET'
  })
}
