import { AxiosResponse } from 'axios'
import { request } from './common'
import { Pinned, Profile, TransferPendingAcceptance } from '../interfaces/api/me'
import { Assignment } from '../interfaces/api/assignment'

const mePath = 'me'

export const getPinnedItems = async (): Promise<AxiosResponse<Pinned[]>> => {
  return request({
    method: 'GET',
    path: `${ mePath }/pinned`
  })
}

export const getProfile = async (): Promise<AxiosResponse<Profile>> => {
  return request({
    method: 'GET',
    path: `${ mePath }/profile`
  })
}

interface UpdateProfileProps {
  barcode?: string
  pin?: string
}
export const updateProfile = async(body: UpdateProfileProps): Promise<AxiosResponse> => {
  return request({
    method: 'PUT',
    path: `${ mePath }/profile`,
    body
  })
}

export const getTransfersPendingAcceptance = async (): Promise<AxiosResponse<TransferPendingAcceptance[]>> => {
  return request({
    method: 'GET',
    path: `${ mePath }/transfers/pending-acceptance`
  })
}

export const getPermissions = async(): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: `${ mePath }/permissions`
  })
}

export const getPendingAssignments = async (): Promise<Assignment[]> => {
  const { data } = await request({
    method: 'GET',
    path: `${ mePath }/pending-assignments`
  })

  return data as Assignment[]
}
