import { useEffect, useState } from 'react'
import { ICellRendererParams } from 'ag-grid-community'

import { dateTimeFormatter } from '../../utils/datetime'


export const DatetimeCellRenderer: React.FC<ICellRendererParams> = (props) => {
  const value = props.value

  const formattedDatetime = typeof(value) === 'string' && value !== '' ? dateTimeFormatter(value) : ''

  return <div>{formattedDatetime}</div>
}

export const DatetimeEditor = (props: any) => {
  const [value, setValue] = useState(props.value)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const onValueChange = (event: any) => {
    const newValue = event.target.value

    setValue(newValue)
  
    props.node.setDataValue(props.column.colId, newValue)
  }

  return (
    <div>
      <input
        type='datetime-local'
        value={value}
        onChange={onValueChange}
      />
    </div>
  )
}
