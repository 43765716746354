import { ColumnType, WorksheetFormFields } from '../interfaces/all'
import { getFileHeaders } from '../utils/excel'
import { showErrorToast } from './toast'
import UploadFileHandler from './upload-file-handler'

interface UploadExcelFileWithWorksheetHandlerProps {
  disabled: boolean
  callback: (file: File, worksheetFormFields: WorksheetFormFields[]) => void
}
export default function UploadExcelFileWithWorksheetHandler(props: UploadExcelFileWithWorksheetHandlerProps) {
  const { callback, disabled } = props

  async function handleCallback(file: File) {
    const headers = await getFileHeaders(file)

    if(!headers) {
      showErrorToast('At least one column with header is required')
      return
    }

    if(headers?.includes('id')) {
      showErrorToast('"id" cannot be used as a header')
      return
    }

    const worksheetFormFields: WorksheetFormFields[] = headers.map((header, index) => ({
      field_name: header,
      field_label: header,
      field_type: ColumnType.TEXT,
      required: false,
      display_order_sequence: index + 1
    }))

    callback(file, worksheetFormFields)
  }

  return <UploadFileHandler buttonText={'Select file'} buttonDisabled={disabled}
    callback={(file) => handleCallback(file)} />
}
