import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import { getGroup } from '../../../api/groups'
import SemanticTable, { SemanticTableRow } from '../../../components/table'
import { SemanticButton } from '../../../components/buttons/buttons'
import GroupUsersModal from '../../../components/modals/group-users-modal'
import { Entity, Group } from '../../../interfaces/all'
import GroupPermissionsModal from '../../../components/modals/group-permissions-modal'
import GroupEntitiesModal from '../../../components/modals/group-entities-modal'
import { getEntities } from '../../../api/entity'
import { getDisplayName } from '../../../utils/utils'


export default function GroupEditPage() {
  const { groupId } = useParams()
  const [group, setGroup] = useState<Group>()
  const [entities, setEntities] = useState<Entity[]>([])
  const [editingMembers, setEditingMembers] = useState(false)
  const [editingGroupPermissions, setEditinGroupPermissions] = useState(false)
  const [editingGroupEntities, setEditinGroupEntities] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getGroup(groupId as string).then(res => setGroup(res.data))
    getEntities().then(res => setEntities(res.data))
  }, [groupId])

  async function refreshGroup() {
    setEditingMembers(false)
    setEditinGroupPermissions(false)
    setEditinGroupEntities(false)

    getGroup(groupId as string).then(res => setGroup(res.data))
  }

  const groupMembersTable = group?.members && group.members?.length > 0
    ? <>
      <SemanticTable headers={['Members']}
        rows={group.members.map(member => {
          return {
            cells: [getDisplayName(member)]
          }
        })} />
    </>
    : <div style={{ marginTop: '12px ' }}>{'Group has no members yet'}</div>

  const groupPermissionsTable = group?.permissions && group.permissions.global.length > 0
    ? <><SemanticTable headers={['Permissions']} rows={group.permissions.global.map(permission => ({
      cells: [permission]
    }))}/></>
    : <div style={{ marginTop: '12px ' }}>{'Group has no permissions yet'}</div>

  function getGroupEntitiesTableRows(group: Group, allEntities: Entity[]): SemanticTableRow[] {
    return Object.entries(group?.permissions.entities).map(entityPermissions => {
      const entity = allEntities.find(entity => entity.id.toString() === entityPermissions[0])

      return {
        cells: [`${entity?.data_name} (${entity?.data_type})`]
      }
    })
  }

  const groupEntitiesTable = group && Object.keys(group.permissions.entities).length > 0
    ? <><SemanticTable headers={['Entities']}
      rows={getGroupEntitiesTableRows(group, entities)}
    /></>
    : <div style={{ marginTop: '12px ' }}>{'Group has no entities yet'}</div>

  const editingMembersModal = group && <GroupUsersModal group={{ ...group, id: groupId as string }}
    onSaved={() => refreshGroup()}
    onCancel={() => setEditingMembers(false)} />

  const editingGroupPermissionsModal = group && <GroupPermissionsModal group={{ ...group, id: groupId as string }}
    onCancel={() => setEditinGroupPermissions(false)} onSaved={() => refreshGroup()} />

  const editingGroupEntitiesModal = group && <GroupEntitiesModal group={{ ...group, id: groupId as string }} allEntities={entities}
    onCancel={() => setEditinGroupEntities(false)} onSaved={() => refreshGroup()} />

  return <>
    <div style={{ margin: '30px 30px' }}>
      <h1 style={{ textAlign: 'center' }}> {`Group: ${group?.name}`}</h1>
      <div style={{ marginBottom: '12px' }}>
        <SemanticButton text={'Return'} color='grey' onClick={() => navigate('/admin/groups')} />
      </div>

      <Grid columns={2}>
        <Grid.Row>
          <Grid.Column>
            <SemanticButton text={'Edit members'} onClick={() => setEditingMembers(true)} />
            {groupMembersTable}
          </Grid.Column>
          <Grid.Column>
            <Grid.Row>
              <SemanticButton text={'Edit permissions'} onClick={() => setEditinGroupPermissions(true)} />
              {groupPermissionsTable}
            </Grid.Row>
            <span style={{ margin: '16px' }}></span>
            <Grid.Row>
              <SemanticButton text={'Edit entities'} onClick={() => setEditinGroupEntities(true)} />
              {groupEntitiesTable}
            </Grid.Row>
          </Grid.Column>
        </Grid.Row>
      </Grid>
      {editingMembers && editingMembersModal}
      {editingGroupPermissions && editingGroupPermissionsModal}
      {editingGroupEntities && editingGroupEntitiesModal}
    </div>
  </>
}
