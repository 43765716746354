import { AgGridReact, AgGridReactProps } from 'ag-grid-react'

import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-alpine.css'


export default function AgGrid(agGridProps: AgGridReactProps & { gridRef?: any }) {
  const { columnDefs, rowData, gridOptions, quickFilterText, gridRef } = agGridProps

  const paginationProps = {
    pagination: true,
    paginationPageSize: 20,
    paginationPageSizeSelector: [10, 20, 100]
  }

  return <AgGridReact className={'ag-theme-alpine'} gridOptions={gridOptions} {...paginationProps}
    columnDefs={columnDefs} rowData={rowData}
    quickFilterText={quickFilterText} ref={gridRef} // Ref for accessing Grid's API
  ></AgGridReact>
}
