import { AxiosResponse } from 'axios'
import { request } from '../common'
import { GenericGetResponse } from '../../interfaces/all'

const caseDetailsPath = 'casedetails'

export const getCaseDetails = async (caseId: string, fieldId: string): Promise<GenericGetResponse> => {
  const { data } = await request({
    method: 'GET',
    path: `${caseDetailsPath}/${ caseId }`,
    queryParams: [{ key: 'field_id', value: fieldId }]
  })

  return data as GenericGetResponse
}

export const createCaseDetails = async(caseId: string, fieldId: string, updatedData: object): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: caseDetailsPath,
    body: {
      metadata: {
        case_id: caseId,
        field_id: fieldId
      },
      updatedData
    }
  })
}

export const deleteCaseDetails = async(dynamicId: string, caseId: string, fieldId: string): Promise<AxiosResponse> => {
  return await request({
    path: `${caseDetailsPath}/${dynamicId}`,
    method: 'DELETE',
    body:{
      metadata: {
        case_id: caseId,
        field_id: fieldId
      }
    }
  })
}
