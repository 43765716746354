import { AxiosResponse } from 'axios'

import { request } from '../common'
import { ActionType } from '../../interfaces/all'

const actionTypesPath = 'actiontypes'

export const getActionTypes = async (): Promise<AxiosResponse<ActionType[]>> => {
  return await request({
    method: 'GET',
    path: actionTypesPath,
  })
}

export const createActionType = async (actionTypeName: string): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: actionTypesPath,
    body: {
      name: actionTypeName
    }
  })
}
