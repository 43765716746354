import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import SemanticSegment from '../../../components/segment'
import { getRecentCases } from '../../../utils/localStorage/recent-cases'
import { Case } from '../../../interfaces/all'


export default function RecentCases() {
  const [recentCases, setRecentCases] = useState<Case[]>([])
  
  const navigate = useNavigate()

  useEffect(() => {
    setRecentCases(getRecentCases())
  }, [])
  
  const recentCaseItems = recentCases.map(item => ({
    text: `Case ${item.caseNumber}`,
    onClick: () => navigate(`/cases/${item.id}/edit`)
  }))

  return <SemanticSegment title='Recent cases' segmentItems={recentCaseItems} />
}
