import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

function NotFound() {
  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => navigate('/'), 3000)
  }, [navigate])

  return (
    <>
      <div style={{ textAlign: 'center', paddingTop: '50px' }}>
        <h2>Not found</h2>
        <h3>Sorry, we haven't found this page ... redirecting to Home page</h3>
      </div>
    </>
  )
}

export default NotFound
