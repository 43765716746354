export enum Mode {
  DESTINATION = 'destination',
  NEW_FIELD = 'new_field'
}

export interface FormDestination {
  mode: Mode.DESTINATION
  source: string
  destination: string
}

export interface FormNewField {
  mode: Mode.NEW_FIELD
  source: string
  field_name: string
  field_label: string
  field_type: string
  required: boolean
}

export type NewFieldOrDestinationFormField = FormDestination | FormNewField

export const isFormDestination = (field: NewFieldOrDestinationFormField): field is FormDestination => field.mode === Mode.DESTINATION
export const isFormNewField = (field: NewFieldOrDestinationFormField): field is FormNewField => field.mode === Mode.NEW_FIELD
