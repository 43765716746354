import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { getPublicDasboardsByOrganization } from '../../api/pubic'
import QuicksightIFrame from '../../components/quicksight-iframe'
import { HttpStatusCode } from '../../interfaces/status-codes'
import { showErrorToast } from '../../components/toast'

export default function OrganizationPublicPage() {
  const { organizaitonName } = useParams()
  const [url, setUrl] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getPublicDasboardsByOrganization(organizaitonName ?? '').then(response => {
      const { status, data } = response

      if(status === HttpStatusCode.OK) {
        setUrl(data.url)
      } else {
        showErrorToast(data?.message ?? 'Could not get dashboards for this organization')
      }

      setLoading(false)
    })
  }, [organizaitonName])

  return <>
    <div style={{ textAlign: 'center', height: '100%', width: '100%' }}>
      {
        loading ? 'Loading ... ' : <h2>{`Public information provided by organization ${ organizaitonName }`}</h2>
      }
      {
        (loading || url) &&<QuicksightIFrame src={url}/>
      }
    </div></>
}
