import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'

import { SemanticButton } from '../../components/buttons/buttons'
import { getImportedPackages, getPackages } from '../../api/packages'
import { DataType, Package, Permissions } from '../../interfaces/all'
import SemanticList from '../../components/list'
import { isSysAdmin } from '../../utils/permissions'
import SemanticLoader from '../../components/loader'
import GlobalConfiguration from '../../configuration/global'
import { getPermissions } from '../../api/me'
import { useDocumentation } from '../../hooks/documentation'
import TitleWithDocumentation from '../../components/title-with-documentation'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import PackagesGuides from '../../guides/packages'
import SemanticIcon from '../../components/icons/icon'
import { Grid } from 'semantic-ui-react'


export default function PackagesIndexPage() {
  const [allPackages, setAllPackages] = useState<Package[]>([])
  const [importedPackages, setImportedPackages] = useState<number[]>([])
  const [permissions, setPermissions] = useState<Permissions | undefined>()
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const isCaseBased = new GlobalConfiguration().isCaseBased()
  const documentation = useDocumentation()

  useEffect(() => {
    setLoading(true)

    getPackages().then(res => {
      setAllPackages(res.data)
      setLoading(false)
    })

    getImportedPackages().then(res => setImportedPackages(res.data))

    getPermissions().then(res => setPermissions(res.data))
  }, [])

  const displayedPackages = isCaseBased ? allPackages : allPackages.filter(p => p.entities.every(entity => entity.data_type === DataType.DYNAMIC))

  const packagesContent = <>
    <SemanticList items={displayedPackages.map(p => <>
      <SemanticButton onClick={() => navigate(`/packages/${p.id}`, { state: importedPackages })}
        text={'Details'} size='mini' color='green' /> {`${p.package_name} `}
      {
        importedPackages.includes(p.id) && <SemanticIcon name='download' color={'grey'} disabled={true} />
      }

    </>)} />
  </>

  const loader = <div style={{ maxWidth: '300px', margin: '30px auto' }}>
    <SemanticLoader />
  </div>

  const packagesListColumn = <div style={{ maxWidth: '300px', margin: '8px auto' }}>
    <TitleWithDocumentation title='Packages' documentationTooltipProps={{ text: documentation.packages }} />

    <SemanticButton text={'Create new package'} onClick={() => navigate('/packages/create')}
      disabled={!(isSysAdmin(permissions))} />
    <div style={{ marginTop: '12px' }}>
      {
        loading ? loader : displayedPackages?.length > 0 ? packagesContent : 'no packages to display'
      }
    </div>
  </div>

  const pageContent = <>
    <Grid columns={2}>
      <Grid.Row>
        <Grid.Column>{packagesListColumn}</Grid.Column>
        <Grid.Column>
          <div style={{ textAlign: 'center' }}>
            <br />
            <br />
            <br/>
            <iframe width="400" height="260"
              src="https://www.youtube.com/embed/e6CsOhl2Wmc"
              title="Infinite System Packages"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              referrerPolicy="strict-origin-when-cross-origin"
              allowFullScreen>
            </iframe>
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>

  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<PackagesGuides />} />
}
