import { useEffect, useState } from 'react'
import { Form } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

import { SemanticButton } from '../../../components/buttons/buttons'
import SemanticTextFormField from '../../../components/form/text-field'
import { CreateWorkflowForm } from '../../../interfaces/api/workflows'
import { getActionTypes } from '../../../api/actions/types'
import { ActionType } from '../../../interfaces/all'
import SemanticDropdownFormField from '../../../components/form/dropdown-field'
import SemanticButtonIcon from '../../../components/button-icon'
import SemanticNumberFormField from '../../../components/form/number-field'
import SemanticIcon from '../../../components/icons/icon'
import { createWorkflow } from '../../../api/workflows'
import { showErrorToast, showToast } from '../../../components/toast'
import SemanticLoader from '../../../components/loader'


export default function WorkflowCreatePage() {
  const [workflowName, setWorkflowName] = useState<string>('')
  const [workflowActionTypes, setWorkflowActionTypes] = useState<CreateWorkflowForm['action_types']>([])
  const [actionTypes, setActionTypes] = useState<ActionType[]>([])
  const [creatingWorkflow, setCreatingWorkflow] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    getActionTypes().then(response => setActionTypes(response.data))
  }, [])

  async function submit() {
    setCreatingWorkflow(true)

    const { status, data } = await createWorkflow({
      name: workflowName,
      action_types: workflowActionTypes
    })

    if (status === 201) {
      showToast({ message: 'Workflow created' })
      navigate('/admin/workflows')
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Workflow not created')
    }


    setCreatingWorkflow(false)
  }

  function addNewField() {
    const copy = [...workflowActionTypes, {
      id: actionTypes[0].id,
      order_sequence: workflowActionTypes.length + 1
    }]

    setWorkflowActionTypes(copy)
  }

  function removeField(index: number) {
    const copy = [...workflowActionTypes]
    copy.splice(index, 1)

    setWorkflowActionTypes(copy)
  }

  function handleChange(index: number, key: string, value: string | number) {
    const copy = [...workflowActionTypes]

    // @ts-ignore
    copy[index][key] = value

    setWorkflowActionTypes(copy)
  }

  const actionTypeOptions = actionTypes.map(actionType => ({
    key: actionType.id,
    value: actionType.id,
    text: actionType.name
  }))

  const buttons = <>
    <div style={{ textAlign: 'left', marginTop: '12px' }}>
      <SemanticButtonIcon buttonProps={{ labelPosition: 'left', color: 'black', onClick: () => addNewField(), disabled: actionTypes.length === 0 }}
        iconProps={{ color: 'yellow', name: 'plus' }} text='Add new action type' />
    </div>

    <div style={{ margin: '16px auto', textAlign: 'center' }}>
      <SemanticButton color='grey' text={'Cancel'} onClick={() => navigate('/admin/workflows')} />
      <SemanticButton text={'Create'} onClick={submit} disabled={!(workflowName && workflowActionTypes.length > 0)} />
    </div></>

  return <>
    <div style={{ maxWidth: '400px', margin: '30px auto', paddingBottom: '12px' }}>
      <Form>
        <SemanticTextFormField index={0} value={workflowName ?? ''} label={'Workflow name'} required
          placeholder={'workflow name'} onChange={(e) => setWorkflowName(e.target.value)} disabled={creatingWorkflow}
        />
        {
          workflowActionTypes.map((field, index) =>
            <Form.Group widths='equal' key={index}>
              <SemanticDropdownFormField index={0} value={field.id} label={'Action type'}
                dropdownItemProps={actionTypeOptions} disabled={creatingWorkflow}
                onChange={(_event, data) => {
                  handleChange(index, 'id', data.value as number)
                }} />
              
              <SemanticNumberFormField index={1} value={field.order_sequence} min={1} disabled={creatingWorkflow}
                label={'Order'} onChange={(value) => handleChange(index, 'order_sequence', value)}
                error={field.order_sequence === 0 ? 'Order must be above 0' : undefined} required />
              
              <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <SemanticIcon name='delete' color='red' onClick={() => removeField(index)} />
              </span>
            </Form.Group>
          )
        }
      </Form>
      {
        creatingWorkflow ? <SemanticLoader /> : buttons
      }
    </div>
  </>
}
