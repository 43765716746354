import { AxiosResponse } from 'axios'

import { GenericGetResponse } from '../../interfaces/all'
import { request } from '../common'

const dynamicdetailspath = 'dynamicdetails'

export const getDynamicDetails = async (fieldId: string, parentId: string): Promise<GenericGetResponse> => {
  const { data } = await request({
    method: 'GET',
    path: `${dynamicdetailspath}/${parentId}`,
    queryParams: [{ key: 'field_id', value: fieldId }]
  })

  return data as GenericGetResponse
}

export const createDynamicDetails = async (updatedData: object, fieldId: string, parentId: string): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: dynamicdetailspath,
    body: {
      metadata: {
        parent_id: parentId,
        field_id: fieldId
      },
      updatedData
    }
  })
}
