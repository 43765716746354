import SemanticModal from './modal'


export interface ConfirmationModalProps {
  header: string
  content: any
  onCancel: Function
  onSaved: Function
}
export default function ConfirmationModal(props: ConfirmationModalProps) {
  const { header, content, onCancel, onSaved } = props

  return <>
    <SemanticModal header={header} content={content}
      actions={[
        { color: 'grey', onClick: onCancel, text: 'Cancel' },
        { color: 'blue', onClick: onSaved, text: 'Confirm' }
      ]}
    /></>
}
