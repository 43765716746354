export interface Barcode {
  id: number
  barcode: string
  type: BarcodeType
}

export enum BarcodeType {
  USER = 'user',
  LOCATION = 'location',
  EVIDENCE = 'evidence'
}
