import { Link } from 'react-router-dom'

interface GenericConfirmationPageProps {
  title: string
  subTitle?: string
  link?: {
    path: string
    message: string
  }
}
export default function GenericConfirmation(props: GenericConfirmationPageProps) {
  const { title, subTitle, link } = props

  return <>
    <div style={{ textAlign: 'center', paddingTop: '50px' }}>
      <h2>{title}</h2>
      {
        subTitle && <h2>{subTitle}</h2>
      }
      {
        link && <span>
          <Link to={link.path}>{link.message}</Link>
        </span>
      }
    </div>
  </>
}
