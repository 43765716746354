import { AxiosResponse } from 'axios'

import { request } from './common'
import { ExternalReport } from '../interfaces/api/external-report'

const externalReportsPath = 'external-reports'

export const getExternalReports = async (): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: externalReportsPath
  })
}

export const getExternalReport = async (id: string): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: `${ externalReportsPath }/${ id }`
  })
}

export const createExternalReport = async (body: Omit<ExternalReport, 'id'>): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: externalReportsPath,
    body
  })
}

export const updateExternalReport = async (props: ExternalReport): Promise<AxiosResponse> => {
  const { id, name, link } = props

  return await request({
    method: 'PUT',
    path: `${ externalReportsPath }/${ id }`,
    body: {
      name,
      link
    }
  })
}

export const deleteExternalReport = async (id: number): Promise<AxiosResponse> => {
  return await request({
    method: 'DELETE',
    path: `${ externalReportsPath }/${ id }`
  })
}
