import { AxiosResponse } from 'axios'
import { request } from './common'


const organizationsPath = 'organizations'

export const getOrganizations = async(): Promise<AxiosResponse<string[]>> => {
  return request({
    method: 'GET',
    path: organizationsPath
  })
}

interface CreateOrganizationProps {
  guid: string
  password: string
}
export const createOrganization = async(body: CreateOrganizationProps): Promise<AxiosResponse> => {
  return request({
    method: 'POST',
    path: organizationsPath,
    body
  })
}

interface JoinOrganizationProps {
  organizationName: string
  firstName: string
  lastName: string
  email: string
  username: string
  recaptchaToken: string
}
export const joinOrganization = async (props: JoinOrganizationProps): Promise<AxiosResponse> => {
  const { organizationName, firstName, lastName, email, username, recaptchaToken } = props

  return request({
    method: 'POST',
    path: `${ organizationsPath }/join`,
    body: {
      organization_name: organizationName,
      first_name: firstName,
      last_name: lastName,
      email,
      username,
      recaptcha_token: recaptchaToken
    }
  })
}

interface JoinOrganizationViaLinkProps {
  guid: string
  firstName: string
  lastName: string
  username: string
  password: string
  phone?: string
}
export const joinOrganizationViaLink = async(props: JoinOrganizationViaLinkProps): Promise<AxiosResponse> => {
  const { guid, firstName, lastName, username, password, phone } = props

  return request({
    method: 'POST',
    path: `${ organizationsPath }/join-via-link`,
    body: {
      guid,
      first_name: firstName,
      last_name: lastName,
      username,
      password,
      phone
    }
  })
}

export const inviteUser = async (email: string): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: `${ organizationsPath }/invite-user`,
    body: {
      email
    }
  })
}
