import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import ConfirmationButton from '../../components/buttons/confirmation-button'
import { SelectedType, BulkTransferValidationResponse } from '../../interfaces/transfer'
import BarcodeForm from '../../components/form/barcode-form'
import { User } from '../../interfaces/all'
import { getUsers } from '../../api/users'
import { Location } from '../../interfaces/api/location'
import { getLocations } from '../../api/locations'
import { getBarcodes } from '../../api/barcode'
import { showErrorToast, showToast } from '../../components/toast'
import { Barcode, BarcodeType } from '../../interfaces/api/barcode'
import SemanticList from '../../components/list'
import { getEvidenceNumbersAndBarcodes } from '../../api/evidences/evidences'
import { EvidenceNumberAndBarcode } from '../../interfaces/api/evidences'
import SemanticLoader from '../../components/loader'
import { bulkTransfer, validateBulkTransfer } from '../../api/transfer'
import UserOrLocationSelector from './user-location-selector'
import ConfirmationWithPinModal from '../../components/modals/confirmation-with-pin-modal'
import LoadingModal from '../../components/modals/loading-modal'
import BrokenChainWarning from '../../components/broken-chain-warning'
import TitleWithDocumentation from '../../components/title-with-documentation'
import { useDocumentation } from '../../hooks/documentation'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import TransfersGuides from '../../guides/transfers'

interface BarcodeId {
  barcode: string,
  id?: number
}

export default function TransafersIndexPage() {
  const [users, setUsers] = useState<User[]>([])
  const [locations, setLocations] = useState<Location[]>([])
  const [evidences, setEvidences] = useState<EvidenceNumberAndBarcode[]>([])
  const [allBarcodes, setAllBarcodes] = useState<Barcode[]>([])
  const [loadingPage, setLoadingPage] = useState(true)
  const [fromBarcode, setFromBarcode] = useState('')
  const [fromType, setFromType] = useState<SelectedType | undefined>()
  const [fromId, setFromId] = useState<number | undefined>()
  const [toBarcode, setToBarcode] = useState('')
  const [toType, setToType] = useState<SelectedType | undefined>()
  const [toId, setToId] = useState<number | undefined>()
  const [displayValidationModal, setDisplayValidationModel] = useState(false)
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)
  const [barcodesBreakingChainOfCustody, setBarcodesBreakingChainOfCustody] = useState<string[]>([])
  const [barcodeEvidencesIdsList, setBarcodeEvidencesIdsList] = useState<BarcodeId[]>([{ barcode: '' }])
  const navigate = useNavigate()
  const documentaiton = useDocumentation()

  useEffect(() => {
    async function load() {
      const [usersResponse, locationsResponse, evidenceNumbersAndBarcodesResponse, barcodesResponse] = await Promise.all([
        getUsers(),
        getLocations(),
        getEvidenceNumbersAndBarcodes(),
        getBarcodes()
      ])

      setUsers(usersResponse.data)
      setLocations(locationsResponse.data)
      setEvidences(evidenceNumbersAndBarcodesResponse.data)
      setAllBarcodes(barcodesResponse.data)
      setLoadingPage(false)
    }

    load()
  }, [])

  async function validateAndOpenConfirmationModal() {
    setDisplayValidationModel(true)

    const { status, data } = await validateBulkTransfer({
      from: {
        type: fromType as SelectedType,
        id: fromId as number
      },
      to: {
        type: toType as SelectedType,
        id: toId as number
      },
      evidences_ids: getEvidencesToTransfer().map(x => x.id as number),
    })

    setDisplayValidationModel(false)

    if (status === 200) {
      const evidenceIds = (data as BulkTransferValidationResponse)
        .filter(x => x.breaks_chain)
        .map(x => x.evidence_id)

      const evidenceBarcodes = evidenceIds.map(id => (barcodeEvidencesIdsList.find(x => x.id === id) as BarcodeId).barcode)

      setBarcodesBreakingChainOfCustody(evidenceBarcodes)
      setDisplayConfirmationModal(true)
    } else {
      showErrorToast(data?.message ?? 'Could not validate transfers')
    }
  }

  function getEvidencesToTransfer(): BarcodeId[] {
    const evidencesToTransfer = [...barcodeEvidencesIdsList]

    if (evidencesToTransfer[evidencesToTransfer.length - 1].barcode === '') {
      evidencesToTransfer.pop()
    }

    return evidencesToTransfer
  }


  async function transfer(pin: string) {
    const { status, data } = await bulkTransfer({
      from: {
        type: fromType as SelectedType,
        id: fromId as number
      },
      to: {
        type: toType as SelectedType,
        id: toId as number
      },
      evidences_ids: getEvidencesToTransfer().map(x => x.id as number),
      pin
    })

    if (status === 201) {
      showToast({ message: 'Transfers completed!' })
      navigate('/transfers/bulk-confirmation')
    } else {
      showErrorToast(data.message ?? 'Could not execute transfers')
      setDisplayConfirmationModal(false)
    }
  }

  const userOptions = users.map(user => ({ key: user.id, value: user.id, text: user.username }))
  const locationOptions = locations.map(location => ({ key: location.id, value: location.id, text: location.name }))

  const loader = <div style={{ maxWidth: '500px', margin: 'auto' }}>
    <SemanticLoader />
  </div>

  const pageContent = <>
    <div style={{ textAlign: 'center', paddingTop: '8px' }}>
      <TitleWithDocumentation title={'Transfers'} documentationTooltipProps={{ text: documentaiton.transfers }} />
      {
        loadingPage ? loader : <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <h4>Select a source</h4>
              <UserOrLocationSelector
                allBarcodes={allBarcodes}
                allUsers={users}
                allLocations={locations}
                defaultBarcode={fromBarcode}
                defaultType={fromType}
                defaultId={fromId}
                userOptions={userOptions}
                locationOptions={locationOptions}
                updateUserOrLocation={(type, id, barcode) => {
                  setFromBarcode(barcode)
                  setFromType(type)
                  setFromId(id)
                }} />
            </Grid.Column>
            <Grid.Column>
              <h4>Select a destination</h4>
              <UserOrLocationSelector
                allBarcodes={allBarcodes}
                allUsers={users}
                allLocations={locations}
                defaultBarcode={toBarcode}
                defaultType={toType}
                defaultId={toId}
                userOptions={userOptions}
                locationOptions={locationOptions}
                updateUserOrLocation={(type, id, barcode) => {
                  setToBarcode(barcode)
                  setToType(type)
                  setToId(id)
                }} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row columns={1}>
            <Grid.Column>
              <h3>Evidences</h3>
              <div style={{ maxWidth: '400px', margin: '12px auto' }}>
                <SemanticList items={
                  barcodeEvidencesIdsList.map((evidence, index) => <>
                    <EvidenceBarcodeForm index={index} autoFocus={true}
                      defaultBarcode={evidence.barcode}
                      defaultEvidenceId={evidence.id}
                      allEvidences={evidences}
                      allBarcodes={allBarcodes}
                      updateEvidenceIdCallback={(barcode, evidenceId) => {
                        if (barcodeEvidencesIdsList.some((item, i) => i !== index && item.barcode === barcode)) {
                          showErrorToast('Barcode already scanned')
                          return
                        }

                        const temp = [...barcodeEvidencesIdsList]

                        if (temp.length === index + 1) {
                          temp.push({ barcode: '' })
                        }

                        setBarcodeEvidencesIdsList(temp.map((item, i) => i === index ? { barcode, id: evidenceId } : item))
                      }}
                      onRemoveCallback={() => {
                        if (barcodeEvidencesIdsList.length === 1) {
                          showErrorToast('There should be at least one evidence')
                          return
                        }
                        const temp = [...barcodeEvidencesIdsList]
                        temp.splice(index, 1)
                        setBarcodeEvidencesIdsList(temp)
                      }} />
                  </>
                  )
                } />
              </div>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div style={{ maxWidth: '100px', margin: 'auto' }}>
              <ConfirmationButton text={'Transfer'}
                disabled={!(fromType && fromId && toType && toId && barcodeEvidencesIdsList.length > 0 && barcodeEvidencesIdsList[0].barcode !== '')}
                onClick={validateAndOpenConfirmationModal} />
            </div>
          </Grid.Row>
        </Grid>
      }
    </div>
    {
      displayValidationModal &&
      <LoadingModal title={'Validating transfers ...'} />
    }
    {
      displayConfirmationModal &&
      <ConfirmationWithPinModal onCancel={() => setDisplayConfirmationModal(false)} onConfirmed={(pin) => transfer(pin)}
        warning={barcodesBreakingChainOfCustody.length > 0 ? <BrokenChainWarning barcodes={barcodesBreakingChainOfCustody} /> : undefined} />
    }
  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<TransfersGuides />} />
}

interface EvidenceBarcodeFormProps {
  allEvidences: EvidenceNumberAndBarcode[]
  allBarcodes: Barcode[]
  defaultBarcode: string
  defaultEvidenceId?: number
  index: number
  autoFocus?: boolean
  updateEvidenceIdCallback: (barcode: string, evidenceId: number) => void
  onRemoveCallback?: () => void
}
function EvidenceBarcodeForm(props: EvidenceBarcodeFormProps) {
  const { allEvidences, allBarcodes, defaultBarcode, defaultEvidenceId, index, autoFocus, onRemoveCallback, updateEvidenceIdCallback } = props

  const [barcode, setBarcode] = useState(defaultBarcode)
  const [error, setError] = useState<string | undefined>()

  function handleOnSubmit() {
    const barcodeObject = allBarcodes.find(x => x.barcode === barcode)

    if (barcodeObject && barcodeObject.type === BarcodeType.EVIDENCE) {
      const evidenceObject = allEvidences.find(x => x.barcode === barcodeObject.id)

      if (evidenceObject) {
        updateEvidenceIdCallback(barcode, evidenceObject.id)
        setError(undefined)
      } else {
        setError('evidence not found')
      }
    } else {
      setError('evidence not found')
    }
  }

  const evidence = allEvidences.find(x => x.id === defaultEvidenceId)

  return <div style={{ marginBottom: '20px' }}>
    <BarcodeForm index={index} value={barcode} label='' error={error} autoFocus={autoFocus}
      onChangeCallback={(event) => setBarcode(event.target.value)} onTab={handleOnSubmit}
      onSubmitCallback={handleOnSubmit}
      submitOnBlur={true}
      onRemoveCallback={onRemoveCallback} />
    {
      evidence && `Evidence: ${evidence.evidence_number}`
    }
  </div>
}
