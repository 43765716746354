import { AxiosResponse } from 'axios'
import { Column, GenericGetResponse, GenericGridResponse } from '../../interfaces/all'
import { request } from '../common'
import { ServiceItem } from '../../interfaces/api/services'

const servicesPath = 'services'

export const getServices = async (): Promise<GenericGridResponse<ServiceItem[]>> => {
  const { data } = await request({
    method: 'GET',
    path: `${servicesPath}`
  })

  return data as GenericGridResponse<ServiceItem[]>
}

export const getCaseServices = async (caseId: string): Promise<GenericGridResponse<ServiceItem[]>> => {
  const { data } = await request({
    method: 'GET',
    path: `${servicesPath}?caseId=${caseId}&`
  })

  return data as GenericGridResponse<ServiceItem[]>
}

export const getService = async (serviceId: string): Promise<GenericGetResponse> => {
  const { data } = await request({
    method: 'GET',
    path: `${servicesPath}/${ serviceId }`
  })

  return data as GenericGetResponse
}

// TODO - there should be an API for that
// {...}/services/columns
export const getServiceColumns = async (caseId: string): Promise<Column[]> => {
  const { meta } = await getCaseServices(caseId)

  return meta.columns
}

export const createService = async (body: object): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: servicesPath,
    body
  })
}

export const updateService = async (body: object, serviceId: string): Promise<AxiosResponse> => {
  return await request({
    path: `${servicesPath}/${ serviceId }`,
    method: 'PUT',
    body
  })
}

export const deleteService = async (serviceId: number): Promise<AxiosResponse> => {
  return await request({
    path: `${servicesPath}/${ serviceId }`,
    method: 'DELETE'
  })
}
