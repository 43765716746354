import { ToastContainer, ToastOptions, ToastPosition, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

interface ShowToastProps {
  message: string
  position?: ToastPosition
}
export const showToast = (props: ShowToastProps) => {
  const { message, position } = props
  const options: ToastOptions<{}> = {
    autoClose: 2000,
    hideProgressBar: true,
    theme: 'colored',
    position
  }

  toast.success(message, options)
}

export const showErrorToast = (message: string, autoClose = 3000) => {
  const options: ToastOptions<{}> = {
    autoClose: autoClose ?? 3000,
    hideProgressBar: true,
    theme: 'colored'
  }

  toast.error(message, options)
}

export default function Toast() {
  return (
    <ToastContainer pauseOnFocusLoss={false}/>
  )
}
