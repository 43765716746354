import { showErrorToast } from '../../../../components/toast'
import UploadFileHandler from '../../../../components/upload-file-handler'
import { ColumnType, WorksheetFormFields } from '../../../../interfaces/all'
import { getFileHeaders, getFileName } from '../../../../utils/excel'

interface ImportExcelColumnsProps {
  disabled: boolean
  callback: (worksheetName: string, worksheetFormFields: WorksheetFormFields[]) => void
}
export default function ImportExcelColumns(props: ImportExcelColumnsProps) {
  const { callback, disabled } = props

  async function handleCallback(file: File | undefined) {
    const importFileErrorMessage = 'Failed to import file, please check if the file and data format are correct'

    if (!file) {
      showErrorToast(importFileErrorMessage)
      return
    }

    const headers = await getFileHeaders(file)

    if (!headers) {
      showErrorToast(importFileErrorMessage)
      return
    }

    const worksheetFields: WorksheetFormFields[] = headers.map((header, index) => ({
      field_name: header,
      field_label: header,
      field_type: ColumnType.TEXT,
      required: false,
      display_order_sequence: index + 1
    }))

    callback(getFileName(file), worksheetFields)
  }

  return <>
    <UploadFileHandler buttonText='Build worksheet from Excel'
      callback={(file) => handleCallback(file)}
      buttonDisabled={disabled}/>
  </>
}
