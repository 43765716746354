import { BuildTitle } from './utils/utils';

export default function AllServicesPageGuides() {
  return (
    <>
      <div style={{ margin: '8px' }}>
        <BuildTitle title='Managing Services' />
      </div>
    </>
  );
}
