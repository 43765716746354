import { RecentActivity } from '../../interfaces/local-storage'

const recentActivitiesKey = 'recentActivities'
const activitiesNumberLimit = 5

export const getRecentActivities = (): RecentActivity[] => {
  const activities = localStorage.getItem(recentActivitiesKey)

  return activities ? JSON.parse(activities) : []
}

export const setRecentActivity = (recentActivity: RecentActivity): void => {
  let recentActivities = getRecentActivities()

  recentActivities = recentActivities.filter(a => a.id !== recentActivity.id)

  if(recentActivities.length >= activitiesNumberLimit) {
    recentActivities.pop()
  }

  recentActivities.unshift(recentActivity)

  localStorage.setItem(recentActivitiesKey, JSON.stringify(recentActivities))
}
