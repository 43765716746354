import axios, { RawAxiosRequestHeaders, AxiosResponse } from 'axios'

import { getAccessToken, removeAccessToken } from '../utils/sessionStorage'
import GlobalConfiguration from '../configuration/global'
import { HttpStatusCode } from '../interfaces/status-codes'

const apiRoute = `${ new GlobalConfiguration().host }/api`

axios.defaults.validateStatus = (status: number): boolean => {
  // interceptor won't catch 400 so caller takes care of Bad Requests and Not Found
  return [
    HttpStatusCode.OK,
    HttpStatusCode.CREATED,
    HttpStatusCode.ACCEPTED,
    HttpStatusCode.NO_CONTENT,
    HttpStatusCode.BAD_REQUEST,
    HttpStatusCode.NOT_FOUND
  ].includes(status)
}

axios.interceptors.response.use((resp) => resp, async (error) => {
  const errorStatus = error?.response?.status

  if (errorStatus === HttpStatusCode.UNAUTHORIZED) {
    removeAccessToken()
    return window.location.href = '/'
  } else if (errorStatus === HttpStatusCode.FORBIDDEN) {
    return window.location.href = '/forbidden'
  } else if(errorStatus === HttpStatusCode.INTERNAL_SERVER_ERROR) {
    return window.location.href = '/internal-error'
  } else {
    return Promise.reject(error)
  }
})

interface RequestProps {
  path: string,
  queryParams?: {
    key: string
    value: string
  }[],
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE',
  body?: object
}
export const request = async(requestProps: RequestProps): Promise<AxiosResponse> => {
  const { path, method, body, queryParams } = requestProps

  let params = ''
  if(queryParams && queryParams.length > 0) {
    params = '?'
    queryParams.forEach(queryParam => {
      params = params + `${queryParam.key}=${queryParam.value}&`
    })
  }
 
  const completePath = `${ apiRoute }/${ path }/${params}`

  const headers: RawAxiosRequestHeaders = {}
  const token = getAccessToken()

  if(token) {
    headers['Authorization'] = `Bearer ${ token }`
  }

  return await axios({
    method,
    url: completePath,
    headers,
    data: body
  })
}

// Find a way to use Axios but not the default interceptor for this call
export const loginRequest = async(body: string): Promise<Response> => {
  return fetch(`${ apiRoute }/token/`, {
    method: 'POST',
    headers: {
      'Content-type': 'application/json'
    },
    body
  })
}
