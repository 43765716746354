import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SemanticButton } from '../../../components/buttons/buttons'
import SemanticList from '../../../components/list'
import { getGroups } from '../../../api/groups'
import SemanticLoader from '../../../components/loader'
import TitleWithDocumentation from '../../../components/title-with-documentation'
import { useDocumentation } from '../../../hooks/documentation'
import SidebarWrapperForPage from '../../../components/sidebar-wrapper-for-page'
import GroupsGuides from '../../../guides/groups'

interface Group {
  id: number
  name: string
}

export default function GroupsIndexPage() {
  const [groups, setGroups] = useState<Group[]>()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const documentation = useDocumentation()

  useEffect(() => {
    async function getEntitiesList() {
      const { data } = await getGroups()

      setGroups(data)
      setLoading(false)
    }

    getEntitiesList()
  }, [])

  const groupsList = groups && <SemanticList items={groups.map(group => <>
    <SemanticButton size='mini' text='edit' color='green' onClick={() => navigate(`/admin/groups/${group.id}`)} />
    {group.name}
  </>)} />

  const loader = <div style={{ maxWidth: '300px', margin: '30px auto' }}>
    <SemanticLoader />
  </div>

  const pageContent = <>
    <div style={{ maxWidth: '300px', margin: '4px auto' }}>
      <TitleWithDocumentation title={'Groups'} documentationTooltipProps={{ text: documentation.groups }} />

      <SemanticButton text={'Return'} color='grey' onClick={() => navigate('/admin')} />
      <SemanticButton text={'Create new group'} onClick={() => navigate('/admin/groups/create')} />
      {
        loading ? loader : groupsList
      }

    </div></>

  return <SidebarWrapperForPage page={pageContent} sidebar={<GroupsGuides />} />
}
