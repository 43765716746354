import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { Grid } from 'semantic-ui-react'

import { RawData, User } from '../../interfaces/all'
import { getEvidence } from '../../api/evidences/evidences'
import ConfirmationButton from '../../components/buttons/confirmation-button'
import { getUsers } from '../../api/users'
import { getLocations } from '../../api/locations'
import { Location } from '../../interfaces/api/location'
import { showErrorToast, showToast } from '../../components/toast'
import { Barcode } from '../../interfaces/api/barcode'
import { createTransfer, validateTransfer } from '../../api/transfer'
import { SelectedType, TransferValidationResponse } from '../../interfaces/transfer'
import UserOrLocationSelector from '../transfers/user-location-selector'
import { getBarcodes } from '../../api/barcode'
import SemanticLoader from '../../components/loader'
import ConfirmationWithPinModal from '../../components/modals/confirmation-with-pin-modal'
import LoadingModal from '../../components/modals/loading-modal'
import BrokenChainWarning from '../../components/broken-chain-warning'


export default function EvidenceTransferPage() {
  const { evidenceId } = useParams()
  const { state } = useLocation()
  const [evidenceData, setEvidenceData] = useState<RawData | undefined>()
  const [users, setUsers] = useState<User[]>([])
  const [locations, setLocations] = useState<Location[]>([])
  const [allBarcodes, setAllBarcodes] = useState<Barcode[]>([])
  const [loadingPage, setLoadingPage] = useState(true)
  const [fromBarcode, setFromBarcode] = useState('')
  const [fromType, setFromType] = useState<SelectedType | undefined>()
  const [fromId, setFromId] = useState<number | undefined>()
  const [toBarcode, setToBarcode] = useState('')
  const [toType, setToType] = useState<SelectedType | undefined>()
  const [toId, setToId] = useState<number | undefined>()
  const [transfering, setTransfering] = useState(false)
  const [displayConfirmationModal, setDisplayConfirmationModal] = useState(false)
  const [displayValidationModal, setDisplayValidationModel] = useState(false)
  const [displayBrokenChainOfCustodyWarning, setDisplayBrokenChainOfCustodyWarning] = useState(false)

  useEffect(() => {
    async function getEvidenceCall() {
      if (state) return state

      const { raw_data } = await getEvidence(evidenceId as string)

      return raw_data[0]
    }

    async function load() {
      const [
        getUsersResponse,
        getLocationsResponse,
        getEvidenceResponse,
        barcodesResponse,
      ] = await Promise.all([
        getUsers(),
        getLocations(),
        getEvidenceCall(),
        getBarcodes(),
      ])

      setUsers(getUsersResponse.data)
      setLocations(getLocationsResponse.data)
      setEvidenceData(getEvidenceResponse)
      setAllBarcodes(barcodesResponse.data)
      setLoadingPage(false)
    }
    load()
  }, [evidenceId, state])

  const userOptions = users.map(user => ({ key: user.id, value: user.id, text: user.username }))
  const locationOptions = locations.map(location => ({ key: location.id, value: location.id, text: location.name }))

  function buildTransferPart() {
    return {
      from: {
        type: fromType as SelectedType,
        id: fromId as number
      },
      to: {
        type: toType as SelectedType,
        id: toId as number
      },
      evidence_id: parseInt(evidenceId as string),
    }
  }

  async function validateAndOpenConfirmationModal() {
    setDisplayValidationModel(true)

    const { status, data } = await validateTransfer(buildTransferPart())

    setDisplayValidationModel(false)

    if (status === 200) {
      const validation = data as TransferValidationResponse

      setDisplayBrokenChainOfCustodyWarning(validation.breaks_chain)
      setDisplayConfirmationModal(true)
    } else {
      showErrorToast(data?.message ?? 'Could not validate transfer')
    }
  }

  async function transfer(pin: string) {
    setTransfering(true)

    const { status, data } = await createTransfer({
      ...buildTransferPart(),
      pin
    })

    if (status === 201) {
      showToast({ message: 'Transfer created' })
    } else {
      showErrorToast(data?.message ?? 'Transfer not created')
    }

    setTransfering(false)
    setDisplayConfirmationModal(false)
  }

  const transferEnabled = fromType && fromId && toType && toId

  const loader = <div style={{ maxWidth: '500px', margin: 'auto' }}>
    <SemanticLoader />
  </div>

  return <>
    <div style={{ textAlign: 'center', paddingTop: '24px' }}>
      <h2>Transfer evidence: {evidenceData?.['Evidence #'] ?? ''}</h2>
      {
        loadingPage ? loader : <Grid columns={2}>
          <Grid.Row>
            <Grid.Column>
              <h4>Select a source</h4>
              <UserOrLocationSelector
                allBarcodes={allBarcodes}
                allUsers={users}
                allLocations={locations}
                userOptions={userOptions}
                locationOptions={locationOptions}
                defaultBarcode={fromBarcode}
                defaultType={fromType}
                defaultId={fromId}
                updateUserOrLocation={(type, id, barcode) => {
                  setFromBarcode(barcode)
                  setFromType(type)
                  setFromId(id)
                }} />
            </Grid.Column>
            <Grid.Column>
              <h4>Select a destination</h4>
              <UserOrLocationSelector
                allBarcodes={allBarcodes}
                allUsers={users}
                allLocations={locations}
                userOptions={userOptions}
                locationOptions={locationOptions}
                defaultBarcode={toBarcode}
                defaultType={toType}
                defaultId={toId}
                updateUserOrLocation={(type, id, barcode) => {
                  setToBarcode(barcode)
                  setToType(type)
                  setToId(id)
                }} />
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <div style={{ maxWidth: '100px', margin: 'auto' }}>
              <ConfirmationButton text={'Transfer'} onClick={validateAndOpenConfirmationModal} disabled={!transferEnabled || loadingPage || transfering} />
            </div>
          </Grid.Row>
        </Grid>
      }
    </div>
    {
      displayValidationModal &&
      <LoadingModal title={'Validating transfer ...'} />
    }
    {
      displayConfirmationModal &&
      <ConfirmationWithPinModal onCancel={() => setDisplayConfirmationModal(false)} onConfirmed={(pin) => transfer(pin)}
        warning={displayBrokenChainOfCustodyWarning ? <BrokenChainWarning/> : undefined}/>
    }
  </>
}
