import { Form } from 'semantic-ui-react'

interface SemanticDateTimeFormFieldProps {
  index: number
  value: string
  label: string
  required?: boolean
  error?: string
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}
export default function SemanticDateTimeFormField(props: SemanticDateTimeFormFieldProps) {
  const { label, index, value, error, required, onChange } = props

  return <>
    <Form.Input type='datetime-local' required={required} key={index} fluid value={value}
      label={label} error={error}
      onChange={onChange}
    />
  </>
}
