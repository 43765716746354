import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SemanticButton } from '../../../components/buttons/buttons'
import SemanticLoader from '../../../components/loader'
import { getWorkflows } from '../../../api/workflows'
import SemanticList from '../../../components/list'
import { Workflow } from '../../../interfaces/api/workflows'
import TitleWithDocumentation from '../../../components/title-with-documentation'
import { useDocumentation } from '../../../hooks/documentation'
import WorkflowsGuides from '../../../guides/workflows'
import SidebarWrapperForPage from '../../../components/sidebar-wrapper-for-page'


export default function WorkflowIndexPage() {
  const [workflows, setWorkflows] = useState<Workflow[]>()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const documentation = useDocumentation()

  useEffect(() => {
    getWorkflows().then(response => {
      setWorkflows(response.data)
      setLoading(false)
    })
  }, [])

  const workflowsList = workflows && <SemanticList items={workflows.map((workflow: any) => <>
    <SemanticButton size='mini' text='view' color='green' onClick={() => navigate(`/admin/workflows/${workflow.id}`)} />
    {workflow.name}
  </>)} />

  const pageContent = <div style={{ maxWidth: '400px', margin: '4px auto' }}>

    <TitleWithDocumentation title={'Workflows'} documentationTooltipProps={{ text: documentation.workflows }} />
    <div style={{ marginTop: '8px' }}>
      <SemanticButton text={'Return'} color='grey' onClick={() => navigate('/admin')} />
      <SemanticButton text={'Create new workflow'} onClick={() => navigate('/admin/workflows/create')} disabled={loading} />
      <SemanticButton text={'Manage Actions'} onClick={() => navigate('/admin/actiontypes')}/>
    </div>
    {
      loading ? <SemanticLoader /> : workflowsList
    }
  </div>

  return <SidebarWrapperForPage page={pageContent} sidebar={<WorkflowsGuides />} />
}
