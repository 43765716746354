import { Icon } from 'semantic-ui-react'
import { SemanticIconProps } from './icons/icon'

interface IconWithTextProps {
  text: string
  iconProps: SemanticIconProps
  onClick?: any
}
export default function IconWithText(props: IconWithTextProps) {
  const { iconProps, text, onClick: onClickInput } = props

  const style = onClickInput ? { cursor: 'pointer' } : {}

  return <>
    <span onClick={onClickInput} style={style}>
      <Icon {...iconProps}/>
      {text}
    </span>
  </>
}
