import { BuildLeftAlignedDiv, BuildList, BuildTitle } from './utils/utils';

export default function Groups() {
  return (
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Manage Groups' />
      <BuildLeftAlignedDiv content='Manage user groups for access control to data Entities.' />
      <BuildLeftAlignedDiv content='There is no right or wrong way to define a group. It can be a lab, or a team or simply a way to limit access to certain data' />
      <br />
      <BuildLeftAlignedDiv content='**How to Create a New Group**' />
      <BuildList items={[
        'Click the "Create New Group" button.',
        'Enter the name of the group.',
        'Configure group permissions and entity access (through the separate options).'
      ]} />
      <br />
      <p>**Return to Admin Panel**</p>
    </div>
  );
}
