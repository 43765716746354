import { BuildLeftAlignedDiv, BuildList, BuildOrderedList, BuildSubTitle, BuildTitle } from './utils/utils'

export default function AdminPageGuides() {
  return <>
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Understanding the Admin Hub: Your Creation Hub' />
      <BuildLeftAlignedDiv content={'The Admin Hub is your control center for building and managing your system. It is where you will create and manage the essential elements that make up your system, such as entities, worksheets, groups, actions, and users.'} />
      <BuildSubTitle title='Manage Entities' />
      <BuildLeftAlignedDiv content={' Entities are the building blocks of your system. They represent the different types of data you will be working with, such as cases, services, and evidence. To manage entities:'} />
      <BuildList items={[
        'Go to the Admin Hub and click on "Manage Entities".',
        'Here you’ll see a list of existing entities. Each entity has an "Edit" button next to it.',
        'Clicking the "Edit" button for an entity allows you to view and modify its details, such as field names, field labels, field types, and linked fields.'
      ]}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Hint: Write down the parts of your system in order. For example a Case will need agencies, and you want dropdown selection of your agencies, your agencies need a list of counties to select for location. Then build it backward. First Counties/Locations Entity and Worksheet, Then Agency Entity and Worksheet, Then your case Entities and Worksheets.'} />
      <br></br>
      <BuildLeftAlignedDiv content={'Worksheets define the structure of your entities. They specify what information should be captured for each entity and how it should be formatted. To create a new worksheet:'} />
      <BuildList items={[
        'In the Admin Hub, click on "Create New Worksheet".',
        'You\'ll be prompted to enter a name for the worksheet.',
        'After naming the worksheet, you can add fields to it. Each field captures a specific piece of information about the entity.',
        'For each field, you\'ll need to specify details like the field name, field label, field type, and display order. You can also specify if the field is linked to another field.' 
      ]}/>
      <BuildSubTitle title='Manage Groups:' />
      <BuildLeftAlignedDiv content={'Groups allow you to manage user permissions within your system. Only admins have the permission to create and manage groups. There are 2 groups created by default:'}/>
      <BuildList items={[
        'Admin: View, edit and delete everything by default. Can also invite new users, change permissions and access the Admin site for extra control. By default Admin group will have permission to all new Entities, however the creator and Admin can change that behavior on a one-by-one basis',
        'Member: By default can view and edit all Entities, but not delete. The creator of the Entity and Admin can change that so a new Entity is not viewable, editable or deletable by this group.'
      ]}/>
      <BuildLeftAlignedDiv content={' You can use groups to give access to specific set of data or restrict access to some parts or certain actions. For example, you can establish a group named "DNA Unit" and only allow the members of this unit access to specific services or evidence types or cases or even Dynamic types such as Serology Result.'}/>
      <BuildSubTitle title='Manage Users'/>
      <BuildLeftAlignedDiv content={'The "Manage Users" section allows you to invite new users to your system and manage existing users. You can invite new users by entering their email addresses and sending them an invitation. You can also view and manage the status of existing users.'}/>
      <BuildSubTitle title='Manage Actions and Manage Workflows'/>
      <BuildLeftAlignedDiv content={'   Actions represent the different operations that can be performed on your Services. An action is a way to assign a service to a user and track the progress. You can create your own workflows by grouping a bunch of actions together. You can use the same actions and workflows for every unit and service in your organization or create custom workflows for each unit and service. For example: You can create 5 actions for your Firearms unit that are specific to that unit:'}/>
      <BuildOrderedList items={[
        'Render firearm safe',
        'Examine firearm',
        'Enter Findings',
        'Tech Review Report',
        'Release Report'
      ]}/>
      <BuildLeftAlignedDiv content={'Then group them together in a Workflow named Firearms Unit Workflow and apply it to Firearm services.'}/>
      <BuildSubTitle title='Manage Locations (storage)'/>
      <BuildLeftAlignedDiv content={'Storage Locations are for transfers. You can setup and edit your locations. We recommend that you do not delete locations but deactivate them.'}/>
    </div>
  </>
}
