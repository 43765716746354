import { BuildLeftAlignedDiv, BuildList, BuildTitle, BuildDiv } from './utils/utils';

export default function location() {
  return (
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Manage Locations' />
      <BuildLeftAlignedDiv content='Manage locations for item transfer (e.g. evidence lockers, refrigerators, storage location, labs, offices, agencies).' />
      <br />
      <BuildLeftAlignedDiv content='**How to Create a New Location**' />
      <BuildList items={[
        'Enter the name of the location.',
        '(Optional) Enter a barcode for the location.',
        'Click the "Save" button (located elsewhere in the interface).'
      ]} />
      <br />
      <BuildDiv content='2. Enter the name of the group.' />
      <BuildDiv content='A list displays existing locations with Edit and Delete buttons (if applicable).' />
      <p>**Return to Admin Hub**</p>
    </div>
  );
}
