import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { RegistrationBasicInformation, getRegistrationBasicInformation } from '../../api/registration'
import SemanticLoader from '../../components/loader'
import { Form } from 'semantic-ui-react'
import SemanticTextFormField from '../../components/form/text-field'
import { SemanticButton } from '../../components/buttons/buttons'
import GenericConfirmation from '../../components/confirmation'
import { showErrorToast } from '../../components/toast'
import { resetPassword } from '../../api/users'


export default function ResetPasswordPage() {
  const { guid } = useParams()
  const [registrationBasicInformation, setRegistrationBasicInformation] = useState<RegistrationBasicInformation | undefined>()
  const [loading, setLoading] = useState(true)
  const [resetingPassword, setResetingPassword] = useState(false)
  const [submited, setSubmited] = useState(false)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [confirmPassword, setConfirmPassword] = useState('')
  const [confirmPasswordError, setConfirmPasswordError] = useState<string | undefined>(undefined)

  useEffect(() => {
    if (guid) {
      getRegistrationBasicInformation(guid).then(res => {
        setRegistrationBasicInformation(res.data)
        setLoading(false)
      })
    }

  }, [guid])

  function onChangePassword(value: string) {
    setPasswordError(undefined)
    setConfirmPasswordError(undefined)
    setPassword(value)
  }

  function onChangeConfirmPassword(value: string) {
    setPasswordError(undefined)
    setConfirmPasswordError(undefined)
    setConfirmPassword(value)
  }

  function validateAndSubmit() {
    if (!(password && password.length > 7)) {
      setPasswordError('Password must be at least 8 characters length')
      return
    }

    if (!(confirmPassword && confirmPassword.length > 7)) {
      setConfirmPasswordError('Password must be at least 8 characters length')
      return
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match')
      return
    }

    submit()
  }

  async function submit() {
    setResetingPassword(true)

    const { status, data } = await resetPassword({ guid: guid as string, password })

    if(status === 201) {
      setSubmited(true)
    } else {
      showErrorToast(data?.message ?? 'Failed to reset password')
    }

    setResetingPassword(false)
  }

  const resetPasswordForm = loading ?
    <SemanticLoader />
    : <><h3>{`Organization: ${registrationBasicInformation?.organization_name}`}</h3>
      <Form>
        <SemanticTextFormField label='password' placeholder='password' value={password} required error={passwordError} index={0} type='password'
          onChange={(e) => onChangePassword(e.target.value)} />
        <SemanticTextFormField label='confirm password' placeholder='confirm password' value={confirmPassword} required error={confirmPasswordError} index={1} type='password'
          onChange={(e) => onChangeConfirmPassword(e.target.value)} />
        <SemanticButton text={'Confirm'} onClick={validateAndSubmit} disabled={!(password && confirmPassword) || resetingPassword} type='submit' loading={resetingPassword} />
      </Form></>

  const passwordSubmitedConfirmation = <GenericConfirmation title='Password reset!'
    link={{
      path: '/home',
      message: 'Go to login page'
    }} />

  return <><div style={{ maxWidth: '400px', margin: '30px auto' }}>
    {
      submited ? passwordSubmitedConfirmation : resetPasswordForm
    }
  </div></>
}
