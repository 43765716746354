import { BuildLeftAlignedDiv, BuildList, BuildDiv, BuildTitle } from './utils/utils'

export default function EntitiesGuides() {
  return <>
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Creating New Elements: Cases, Services, Evidence and Dynamic'/>
      <BuildDiv content=' Once you have set up your organization and understood the Admin Panel, you can start creating new elements for your system. These elements represent the different types of data you will be working with.'/>
      <br></br>
      <BuildDiv content='There are 4 types of Entities:'/>
      <BuildList items={['Case', 'Service', 'Evidence', 'Dynamic']}/>
      <BuildLeftAlignedDiv content={<span>What is a <b>Dynamic</b> type Entity?</span>}/>
      <BuildLeftAlignedDiv content={<span>Anything you want it to be. Here you can create define and replicate any type of Excel worksheet and/or database structure. You can also relate any Entity you want to other Entities from the 4 types of elements above. We recommend only relating to Dynamic Entities and "users" to keep your structures simple and easier to manage.</span>}/>
      <BuildList items={[
        'Go to the Admin Hub and click on "Manage Entities"',
        'Click on "Create New Worksheet" to start building a new entity.',
        'Give your entity a name that clearly reflects its purpose (e.g., "Drug Case").',
        'Add fields to capture relevant information about the entity (e.g., "Case #", "Case Type", "Date of Incident").',
        ' Use the "Linked To" and "Linked Field" properties to establish relationships between different entities, ensuring data consistency and easy navigation.'
      ]}/>
      <BuildLeftAlignedDiv content={'This system is configured for Case management. The 3 main parts, Case, Service and Evidence only have the most basic data and hold relationships to each other. Everything else can be custom built into it.'}/>
      <BuildList items={[
        'Case is the parent and holds many Services and Evidences',
        'Evidence is by default related to a Case and can be related to any service in the case',
        'Service is related to a Case and can have multiple related Evidences'
      ]}/>
      <BuildLeftAlignedDiv content={'Create a new Entity of type Case, Service or Evidence when you want to create a new type or category of each one. For example if you think all your cases will have the same basic info then only create one type of Case. But if you think Cases from a different jurisdiction need special data/fields then create another Entity of type Case and name it accordingly; For example Polk County Case.'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Same for Services and Evidences. You can create Evidence Entity named firearm, and another named shell casing and another named fingerprint card and each one can have their own data set.'}/>
    </div>
  </>
}
