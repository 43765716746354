import { useEffect, useState } from 'react'
import { Dropdown, DropdownProps } from 'semantic-ui-react'
import { getCaseNumbers } from '../api/cases/cases'
import { Case } from '../interfaces/all'
import { useNavigate } from 'react-router-dom'

interface SemanticUIDropdownOptions {
  key: string
  value: string
  text: string
}

interface CasesSearchProps {
  selectedCaseId: string
}

function CasesSearch(casesSearchProps: CasesSearchProps) {
  const [caseOptions, setCaseOptions] = useState<Case[]>([])
  const navigate = useNavigate()
  
  useEffect(() => {
    getCaseNumbers().then(response => setCaseOptions(response))
  }, [])

  function onChange(_event: React.SyntheticEvent<HTMLElement>, data: DropdownProps) {
    const dropdownSelectedValue = data.value as string
    const selectedCase = caseOptions.find(option => option.caseNumber === dropdownSelectedValue) as Case

    navigate(`/cases/${ selectedCase.id }/edit`)
  }

  const selectedCase = caseOptions.find(option => option.id.toString() === casesSearchProps.selectedCaseId)

  const options: SemanticUIDropdownOptions[] = caseOptions.map(option => ({
    key: (option.id).toString(),
    value: option.caseNumber,
    text: option.caseNumber
  }))

  return <Dropdown
    placeholder={selectedCase?.caseNumber ?? 'Select case'}
    fluid
    search
    selection // TODO - check console error
    onChange={onChange}
    options={options}
  />
}

export default CasesSearch
