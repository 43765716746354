import { useNavigate, useParams } from 'react-router-dom'

import { showErrorToast } from '../../components/toast'
import { createOrganization } from '../../api/organizations'
import EmailVerification from './email-verification'

export default function AdminEmailVerificationPage() {
  const navigate = useNavigate()
  const { guid } = useParams()

  async function createOrganizationHandler(password: string) {
    const { status, data } = await createOrganization({
      guid: guid as string,
      password
    })

    if (status === 201) {
      navigate('/email-verification-confirmation')
    } else {
      showErrorToast(data?.message ?? 'Could not verify email')
    }
  }

  return <EmailVerification submitCallback={createOrganizationHandler} guid={guid as string}/>
}
