import SemanticLoader from './loader'

interface QuicksightIFrameProps {
  src?: string
}
export default function QuicksightIFrame(props: QuicksightIFrameProps) {
  const { src } = props

  if (!src) {
    return <div style={{ padding: '40px'}}>
      <SemanticLoader />
    </div>
  }

  return <>
    <iframe width='100%' height="900"
      src={src}
      title='Quicksight dashboards'
      frameBorder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerPolicy="strict-origin-when-cross-origin"
      allowFullScreen></iframe>
  </>
}
