import { useEffect, useState } from 'react'
import { DropdownItemProps, Grid } from 'semantic-ui-react'

import { SemanticButton } from '../../../components/buttons/buttons'
import { getEntities } from '../../../api/entity'
import { DataType, Entity } from '../../../interfaces/all'
import SemanticList from '../../../components/list'
import { useNavigate } from 'react-router-dom'
import SemanticInput from '../../../components/ag-grid/input'
import SemanticDropdownFormField from '../../../components/form/dropdown-field'
import SemanticLoader from '../../../components/loader'
import GlobalConfiguration from '../../../configuration/global'
import { getEntityDisplayName } from '../../../utils/utils'
import { useDocumentation } from '../../../hooks/documentation'
import TitleWithDocumentation from '../../../components/title-with-documentation'
import SidebarWrapperForPage from '../../../components/sidebar-wrapper-for-page'
import EntitiesGuides from '../../../guides/entities'


export default function EntitiesIndexPage() {
  const [entities, setEntities] = useState<Entity[]>()
  const [loading, setLoading] = useState(true)
  const [textFilter, setTextFilter] = useState('')
  const [dropdownFilter, setDropdownFilter] = useState('')
  const navigate = useNavigate()
  const isCaseBased = new GlobalConfiguration().isCaseBased()
  const documentation = useDocumentation()

  useEffect(() => {
    async function getEntitiesList() {
      const { data } = await getEntities()

      setEntities(data)
      setLoading(false)
    }

    getEntitiesList()
  }, [])

  const dropdownDataTypes = [DataType.CASE, DataType.SERVICE, DataType.EVIDENCE, DataType.DYNAMIC]
  const dropdownFilterOptions: DropdownItemProps[] = [
    {
      key: '',
      value: '',
      text: 'Select data type ...'
    },
    ...dropdownDataTypes.map(dataType => ({
      key: dataType,
      value: dataType,
      text: dataType
    }))
  ]

  const displayedEntities = entities?.filter(entity => {
    if (!dropdownFilter) return true

    return entity.data_type === dropdownFilter
  }).filter(entity => {
    if (!textFilter) return true

    return entity.data_name.toLowerCase().includes(textFilter.toLowerCase())
  })

  const entitiesList = displayedEntities && <SemanticList items={displayedEntities.map(entity => <>
    <SemanticButton size='mini' text='edit' color='green' onClick={() => navigate(`/admin/entities/${entity.id}`)} />
    {getEntityDisplayName(entity)}
  </>)} />

  const loader = <div style={{ maxWidth: '300px', margin: '30px auto' }}>
    <SemanticLoader />
  </div>

  const filters = <>
    <div>
      <SemanticInput value={textFilter} onChange={(_e, data) => setTextFilter(data.value)} />
    </div>
    {
      isCaseBased && <div style={{ marginTop: '8px' }}>
        <SemanticDropdownFormField index={0} label={''} value={dropdownFilter} placeholder='Select data type ...'
          dropdownItemProps={dropdownFilterOptions} onChange={(_event, data) => setDropdownFilter(data.value as string)} />
      </div>
    }
  </>

  const actionButtons = <div style={{ marginTop: '8px' }}>
    <SemanticButton text={'Return'} color='grey' onClick={() => navigate('/admin')} />
    <SemanticButton text={'Create new entity'} onClick={() => navigate('/admin/entities/create')} disabled={loading} />
  </div>

  const pageContent = <>
    <div style={{ maxWidth: '600px', margin: '30px auto' }}>
      <div style={{ textAlign: 'center' }}>
        <TitleWithDocumentation title={'Entities'} documentationTooltipProps={{ text: documentation.entities }} />

      </div>

      <Grid columns={2} divided>
        <Grid.Row>
          <Grid.Column>
            {actionButtons}
            {
              loading ? loader : entitiesList
            }
          </Grid.Column>
          <Grid.Column>
            <div style={{ marginTop: '4px' }}>
              <h4>Search</h4>
              {filters}
            </div>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </div>
  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<EntitiesGuides />} />
}
