import { BuildLeftAlignedDiv, BuildTitle } from './utils/utils'

export default function PackagesGuides() {
  return <>
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Packages'/>
      <BuildLeftAlignedDiv content={'Here you can use existing premade (packaged) entities and worksheets or create your own package so others can benefit from your innovation. The Packages are public and are meant to help the community. The packages may have repeating Entities and Worksheets. A package named "New Mexico CODIS" may contain only the CODIS related entities and worksheets while another package named "New Mexico DNA" can contain the CODIS package items plus other service and evidence types for DNA work.'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Please publish your entities and worksheets on the Packages section so others can benefit from your work and innovation.'}/>
    </div>
  </>
}
