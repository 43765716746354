import IconWithText from './icon-with-text'
import SemanticList from './list'

interface BrokenChainWarningProps {
  barcodes?: string[]
}
export default function BrokenChainWarning({ barcodes }: BrokenChainWarningProps) {
  function buildItem(message: string) {
    return <IconWithText text={message} iconProps={{ color: 'red', name: 'x'}}/>
  }

  if (!barcodes) return buildItem('This transfer will break chain of custody!')


  return <SemanticList items={barcodes.map(barcode => buildItem(`${barcode} breaks chain of custody!`))} />
}
