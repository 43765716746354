import { Form } from 'semantic-ui-react'
import SemanticDropdownFormField from '../form/dropdown-field'
import SemanticModal from './modal'
import { useEffect, useState } from 'react'
import { Workflow } from '../../interfaces/api/workflows'
import { getWorkflows } from '../../api/workflows'
import SemanticLoader from '../loader'
import { ActionType } from '../../interfaces/all'
import { getActionTypes } from '../../api/actions/types'
import SemanticList from '../list'
import { createBulkActions } from '../../api/actions/actions'
import { showErrorToast, showToast } from '../toast'

interface AddWorkflowModalProps {
  serviceId: string
  onCancel: Function
  onSaved: Function
}
export default function AddWorkflowModal(props: AddWorkflowModalProps) {
  const { serviceId, onCancel, onSaved } = props
  const [workflows, setWorkflows] = useState<Workflow[]>([])
  const [actionTypes, setActionTypes] = useState<ActionType[]>([])
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | undefined>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function getWorkflowsAndActionTypes() {
      const [getWorkflowsResponse, getActionTypesResponse] = await Promise.all([
        getWorkflows(),
        getActionTypes()
      ])

      setWorkflows(getWorkflowsResponse.data)
      setActionTypes(getActionTypesResponse.data)
      setLoading(false)
    }

    getWorkflowsAndActionTypes()
  }, [])

  async function saveAndClose() {
    setLoading(true)

    const { status, data } = await createBulkActions({
      workflow_id: selectedWorkflow?.id as number,
      service_id: serviceId
    })

    if(status === 201) {
      showToast({ message: 'Workflow added successfully' })
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Workflow not added')
    }

    setLoading(false)
    onSaved()
  }

  const options = workflows.map(workflow => ({
    key: workflow.id,
    value: workflow.id,
    text: workflow.name
  }))

  function getActionTypeName(id: number): string {
    return actionTypes.find(actionType => actionType.id === id)?.name ?? ''
  }

  const displayedActionsTypes = selectedWorkflow?.action_types
    .sort((a, b) => a.order_sequence - b.order_sequence)
    ?.map(actionType => `${actionType.order_sequence.toString()} - ${getActionTypeName(actionType.id)}`) ?? []

  const content = <>
    <div style={{ marginBottom: '24px' }}>
      <Form>
        <SemanticDropdownFormField index={0} value={selectedWorkflow?.id} label={'Workflow'}
          dropdownItemProps={options}
          disabled={false} placeholder='Select a workflow'
          onChange={(_event, data) => {
            const workflow = workflows.find(workflow => workflow.id === data.value)

            setSelectedWorkflow(workflow)
          }} />
      </Form>
    </div>
    <div>
      <h5>Actions list</h5>
      <SemanticList items={[displayedActionsTypes.map(displayedActionsType => <>
        <div>
          {displayedActionsType}
        </div>
      </>)]} />
    </div>
  </>

  return <>
    <SemanticModal header={'Add workflow'}
      content={loading ? <SemanticLoader /> : (workflows.length > 0 ? content : 'No workflows available')}
      actions={[
        { color: 'grey', onClick: onCancel, text: 'Cancel' },
        { color: 'blue', onClick: saveAndClose, text: 'Confirm', disabled: loading || workflows.length === 0 || !selectedWorkflow }
      ]}
    /></>
}
