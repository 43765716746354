import { BuildList, BuildTitle } from './utils/utils';

export default function EditEvidencePageGuides() {
  return (
    <>
      <div style={{ margin: '8px' }}>
        <BuildTitle title='Edit Evidence Details' />
        <BuildList items={[
          'All standard and custom fields for the type of evidence you selected will appear on the right hand side.',
          'All Lists (field type List) you created will appear on the right hand side.'
        ]} />
      </div>
    </>
  );
}
