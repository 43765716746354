import { AxiosResponse } from 'axios'
import { request } from '../common'
import { GenericGetResponse } from '../../interfaces/all'

const actionsPath = 'actions'

export const getActionsByService = async (serviceId: string): Promise<GenericGetResponse> => {
  const { data } = await request({
    method: 'GET',
    path: actionsPath,
    queryParams: [{ key: 'service_id', value: serviceId }]
  })

  return data as GenericGetResponse
}

export const createAction = async (body: Object): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: actionsPath,
    body
  })
}

interface CreateBulkActionsInput {
  workflow_id: number
  service_id: string
}
export const createBulkActions = async (body: CreateBulkActionsInput): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: `${actionsPath}/bulk`,
    body
  })
}

export const updateAction = async (actionId: string, body: Object): Promise<AxiosResponse> => {
  return await request({
    method: 'PUT',
    path: `${ actionsPath}/${ actionId }`,
    body
  })
}

export const updateActionOrder = async (newOrderedList: number[]): Promise<AxiosResponse> => {
  return await request({
    method: 'PATCH',
    path: `${ actionsPath}/order`,
    body: {
      'ids_in_order': newOrderedList
    }
  })
}

export const deleteAction = async (actionId: string): Promise<AxiosResponse> => {
  return await request({
    method: 'DELETE',
    path: `${ actionsPath}/${ actionId }`
  })
}
