import { BuildLeftAlignedDiv, BuildSubTitle, BuildTitle } from '../utils/utils'

export default function HomePageGuides() {
  return <>
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Getting Started' />
      <BuildLeftAlignedDiv content={'If this is your first login to the system, do not panic if you see a mostly blank page.'} />
      <BuildLeftAlignedDiv content={'If you are an Admin or trying to build the system, start from the Admin menu (link here) and read the guide on how to build new Entities, Worksheets and other things.'} />
      <BuildLeftAlignedDiv content={'You can also look at existing ready-made Packages (link here) to jump start building your system.'} />
      <br></br>
      <BuildLeftAlignedDiv content={'Here is a breakdown of what you see on the page:'}/>
      <BuildSubTitle title='Top Menus'/>
      <BuildLeftAlignedDiv content={'Admin: If you are an Admin or super user you can create Entities, worksheets, fields, alter anything, assign user rights and many other things from here'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Packages: Select from a list of ready made Packages to kickstart building your entities and fields for all types of work efficiency screens'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Dynamic Data: In addition to Cases, Services and Evidences, you can build anything you wish in the Dynamic Data menu. These could be Lab related information like test results and data related info, to administrative things like timesheets, Crime Scene vehicle logs, equipment log, etc.'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Transfers: Transfer items/evidence if you are not in the evidence section already as well as bulk transfer from here'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Reports: Link to reporting and dashboards'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Your username and organization name: Link to your profile and logout button.'}/>
      <BuildSubTitle title='On the page'/>
      <BuildLeftAlignedDiv content={'All cases: Take you to a list of all cases and you can start by clicking on each case to edit'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Create case: Quick way to create a new case'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Select case dropdown (below the 2 blue buttons): Quick search by case number.'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Recent Cases: After you visit a few cases, this list will show your 5 most recent cases'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'My action items: Services assigned to you will display here'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Transfers pending acceptance: If there was a transfer request, you can accept it from this list'}/>
      <br></br>
      <BuildLeftAlignedDiv content={'Pinned items: You can pin anything (cases, evidence, dynamic type data, etc.) to your home page so you can return to that specific record with just a click. You can also unpin it from here.'}/>
    </div>
  </>
}
