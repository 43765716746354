import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Form } from 'semantic-ui-react'

import { SemanticButton } from '../../../components/buttons/buttons'
import { showErrorToast } from '../../../components/toast'
import { createGroup } from '../../../api/groups'

export default function GroupCreatePage() {
  const [name, setName] = useState<string | undefined>(undefined)
  const navigate = useNavigate()

  async function onSubmit() {
    const { status, data } = await createGroup(name as string)

    if (status === 201) {
      navigate(`/admin/groups/${data}`)
    } else if(status === 400) {
      showErrorToast(data?.message ?? 'Group not created')
    }
  }

  return <>
    <div style={{ maxWidth: '300px', margin: '30px auto' }}>
      <h1>Create new group</h1>
      <Form>
        <Form.Input fluid label='Group name' placeholder='group name' onChange={(e) => setName(e.target.value)} />
        <SemanticButton text={'Create'} onClick={onSubmit} disabled={!(name)} />
      </Form>
    </div>
  </>
}
