import { Container, Segment } from 'semantic-ui-react'

import { getYear } from '../utils/datetime'

export default function Footer() {
  return (
    <Segment inverted vertical style={{ padding: '1em 0em' }}>
      <Container textAlign='center'>
        <p style={{ fontSize: '1.2em', margin: 0 }}>
          &copy; {getYear()} 2nd Logic - Forensics Technology
        </p>
        <p style={{ fontSize: '1.2em', margin: 0 }}>
          <a href='/privacy' style={{ color: 'white' }}>Privacy Policy</a> |
          <a href='/terms' style={{ color: 'white' }}> Terms of Service</a>
        </p>
      </Container>
    </Segment>
  )
}
