import SemanticModal from './modal'

interface InformationModalProps {
  message: string
  onClick: Function
}
export default function InformationModal(props: InformationModalProps) {
  const { message, onClick } = props

  return <>
    <SemanticModal header={undefined} content={message} actions={[
      {
        text: 'ok',
        onClick,
        color: 'blue'
      }
    ]}/></>
}
