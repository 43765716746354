import { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import { CellClickedEvent, CellEditingStartedEvent, GridOptions } from 'ag-grid-community'

import { SemanticButton } from '../buttons/buttons'
import AgGrid from './ag-grid'
import { ColumnType, Column, RawData, DataType, isDictionaryColumn, isListColumn } from '../../interfaces/all'
import { getColumnsDefs, buildExcelHeadersAndRows, getNonfulfilledRequiredCustomFields } from './utils'
import { EMPTY_KEY } from '../form/form'
import SemanticIcon from '../icons/icon'
import SemanticInput from './input'
import { showErrorToast, showToast } from '../toast'
import ConfirmationModal from '../modals/confirmation-modal'
import { Pinned } from '../../interfaces/api/me'
import { getPinnedItems } from '../../api/me'
import { addPinnedItem, removePinnedItem } from '../../api/pinned'
import { downloadAsExcel } from '../../utils/excel'


interface DynamicGridProps {
  dataType: DataType // TODO - Type all data types so it can be used within RawData as RawData<Evidence[]>[]
  entityId?: string // Dynamic might have an entityId, but action will not
  rows: RawData[]
  columns: Column[]
  onDelete: (dynamicId: number) => void
  onCancel: () => void
  onEdit?: (dynamicId: string) => void
  onCreate: (data: any) => void
  onUpdate: (data: any) => void
  onAddWorkflowClick?: () => void
  dragActionEndCallback?: (actionId: number, toIndex: number) => void
  canDelete?: boolean
  canEdit?: boolean
  displayAddRowAndFilters?: boolean
}

export default function DynamicGrid(props: DynamicGridProps) {
  const [rawData, setRawData] = useState<RawData[]>([])
  const [onEditModeRowId, setOnEditModeRowId] = useState(0)
  const [quickFilterText, setQuickFilterText] = useState('')
  const [pinnedItems, setPinnedItems] = useState<Pinned[]>([])
  const [pinningUnpinning, setPinningUnpinning] = useState(false)
  const [confirmationModalDynamicOrAction, setConfirmationModalDynamicOrAction] = useState<RawData | undefined>()
  const ref = useRef<AgGridReact>()
  const navigate = useNavigate()

  const { rows, columns, onCreate, onEdit, onUpdate, onDelete, onCancel, onAddWorkflowClick, canDelete = false, canEdit = false, dataType, displayAddRowAndFilters = true, entityId, dragActionEndCallback } = props

  const isActionDataType = dataType === DataType.ACTION
  const isEditingRow = onEditModeRowId !== 0

  useEffect(() => {
    setRawData(rows)
    setOnEditModeRowId(0)
    getPinnedItems().then(res => setPinnedItems(res.data))
  }, [rows])

  async function refreshPinnedItems() {
    getPinnedItems().then(res => {
      setPinnedItems(res.data)
      setPinningUnpinning(false)
    })
  }

  function deleteRow(id: number) {
    onDelete(id)
  }

  function saveRow(event: CellClickedEvent) {
    const { data } = event

    const missingRequiredField = getNonfulfilledRequiredCustomFields(data, columns)
    if (missingRequiredField) {
      showErrorToast(`${missingRequiredField} is required`)
      return
    }

    if (data.id.toString() === EMPTY_KEY) {
      delete data.id
      onCreate(data)
    } else {
      onUpdate(data)
    }
  }

  function addRow() {
    const newRowId = -1

    let temp = rawData.length > 0 ? [...rawData] : []

    const booleans: { [key: string]: boolean } = {}
    const actionOrder: { [key: string]: number } = {}

    columns.forEach(col => {
      if (col.type === ColumnType.BOOLEAN) {
        booleans[col.name] = false
      }

      if (dataType === DataType.ACTION && col.name === 'order') {
        actionOrder[col.name] = temp.length + 1
      }
    })

    temp.push({ id: newRowId, ...booleans, ...actionOrder })

    setRawData(temp)

    setOnEditModeRowId(newRowId)
  }

  function getCustomColumnsDefs(): Array<any> {
    const columnsDefs = getColumnsDefs({
      columns: columns.filter(col => col.type !== ColumnType.LIST),
      editable: canEdit
    })

    return columnsDefs.map(colDefs => {
      return {
        ...colDefs,
        rowDrag: dataType === DataType.ACTION && colDefs.headerName === 'Type' && !isEditingRow,
        editable: (e: CellClickedEvent) => canEdit && colDefs.editable && (e.data.id === onEditModeRowId || !isEditingRow),
        cellStyle: (_e: CellClickedEvent) => colDefs.editable ? undefined : { color: 'gray' }
      }
    }) ?? []
  }

  //Hey Andre, I did not know how you handle details. I was thinking of
  //Adding like dynamic/id/parent/parentid and have the field_id as data or something
  function navigateTo(linkedId: number, fieldId: number, parentId: number) {
    navigate(`/dynamic/${linkedId}/fieldId/${fieldId}/parentId/${parentId}`)
  }

  function getListColumnsDefs() {
    const listColumnsDefs = columns.filter(isListColumn)
      .map(col => ({
        headerName: col.name,
        cellRenderer: (event: CellClickedEvent) => {
          const enabled = !isEditingRow

          return <span title={col.name} onClick={() => enabled ? navigateTo(col.linked_id as number, col.field_id as number, event.data.id as number) : undefined}>
            <SemanticIcon color={'blue'} name={'list'} disabled={!enabled}
            /></span>
        }
      })) ?? []

    return [...listColumnsDefs]
  }

  async function pinRow(event: CellClickedEvent) {
    const { data } = event

    setPinningUnpinning(true)

    const { status } = await addPinnedItem({
      data_id: data.id,
      data_type: dataType
    })

    if (status === 201) {
      showToast({ message: `${isActionDataType ? getActionName(data) : data['Display Name']} was pinned` })
    }

    refreshPinnedItems()
  }

  async function unpinRow(event: CellClickedEvent) {
    const { data } = event

    const pinned = pinnedItems.find(pinned => pinned.data_id === data.id) as Pinned

    setPinningUnpinning(true)

    const { status } = await removePinnedItem(pinned.id)

    if (status === 204) {
      showToast({ message: `${isActionDataType ? getActionName(data) : data['Display Name']} was unpinned` })
    }

    refreshPinnedItems()
  }

  const deleteColumnDef = {
    pinned: 'left',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      return <span title='delete' onClick={() => !isEditingRow ? setConfirmationModalDynamicOrAction(event.data) : undefined}>
        <SemanticIcon color='red' name='delete' disabled={isEditingRow} />
      </span>
    }
  }

  const pinRowColumnDef = {
    pinned: 'left',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const disabled = isEditingRow || pinningUnpinning
      const isPinned = pinnedItems.some(row => row.data_id === event.data.id && row.data_type === dataType)

      return <span title={isPinned ? 'unpin' : 'pin'} onClick={() => !disabled ? (isPinned ? unpinRow(event) : pinRow(event)) : undefined}>
        <SemanticIcon color={isPinned ? 'blue' : 'grey'} name='pin' disabled={disabled} />
      </span>
    }
  }

  const editColumnDef = {
    pinned: 'left',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const disabled = !(onEditModeRowId === 0)

      return <span title='edit' onClick={() => (!disabled && onEdit) ? onEdit(event.data.id) : undefined}>
        <SemanticIcon color='blue' name='edit' disabled={disabled} />
      </span>
    }
  }

  const cancelColumnDef = {
    pinned: 'left',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const { id: rowId } = event.data
      const disabled = !(rowId === onEditModeRowId)

      return <span title='cancel' onClick={() => !disabled ? onCancel() : undefined}>
        <SemanticIcon color='grey' name='arrow left' disabled={disabled} />
      </span>
    }
  }

  const saveColumnDef = {
    pinned: 'left',
    width: 60,
    cellRenderer: (event: CellClickedEvent) => {
      const { id: rowId } = event.data
      const disabled = !(rowId === onEditModeRowId)

      return <span title='save' onClick={() => !disabled ? saveRow(event) : undefined}>
        <SemanticIcon color={'blue'} name={'save'} disabled={disabled}
        /></span>
    },
  }

  let columnDefs: any[] = []
  if (canDelete && !isEditingRow) {
    columnDefs.push(deleteColumnDef)
  }

  if(!isEditingRow) {
    columnDefs.push(pinRowColumnDef)
  }

  if(canEdit && !isEditingRow) {
    if (onEdit) {
      columnDefs.push(editColumnDef)
    }
  } else {
    columnDefs.push(cancelColumnDef)
    columnDefs.push(saveColumnDef)
  }

  columnDefs = [...columnDefs, ...getListColumnsDefs(), ...getCustomColumnsDefs()]

  const gridOptions: GridOptions = {
    onCellEditingStarted: (event: CellEditingStartedEvent) => setOnEditModeRowId(event.data.id),
    rowDragManaged: dataType === DataType.ACTION,
    onRowDragEnd: (event) => {
      if (dragActionEndCallback !== undefined) {
        dragActionEndCallback(event.node.data.id, event.overIndex)
      }
    }
  }

  function clearAllFilters() {
    setQuickFilterText('')
    ref.current?.api.setFilterModel(null)
  }

  function getActionName(data: RawData): string {
    return (columns.filter(isDictionaryColumn).find(col => col.name === 'Type'))?.options[data['Type'] as number] ?? ''
  }

  async function handleDownload() {
    downloadAsExcel(buildExcelHeadersAndRows(rawData, columns))
  }

  return <>
    {
      displayAddRowAndFilters &&
      <div style={{ marginBottom: '4px' }}>
        {
          canEdit ?
            <SemanticButton disabled={!!onEditModeRowId} text={`Add ${isActionDataType ? 'action' : 'row'}`} onClick={addRow} />
            : undefined
        }
        {
          canEdit && isActionDataType ?
            <SemanticButton disabled={!!onEditModeRowId} text={'Add workflow'} onClick={onAddWorkflowClick} />
            : undefined
        }
        <span style={{ marginLeft: '8px' }}>
          <SemanticInput value={quickFilterText} onChange={(_e, data) => setQuickFilterText(data.value)} />
        </span>
        <span style={{ marginLeft: '8px' }}>
          <SemanticButton text={'Clear all filters'} onClick={() => clearAllFilters()} />
        </span>
        {
          dataType === DataType.DYNAMIC && <span style={{ marginLeft: '8px', float: 'right' }}>
            <SemanticButton onClick={handleDownload} text={'Export'} />
          </span>
        }
        {
          canDelete && entityId && <span style={{ float: 'right' }}>
            <SemanticButton onClick={() => navigate(`/admin/entities/${entityId}`)} text={'Edit worksheets'} />
          </span>

        }
      </div>
    }
    <div style={{ flex: 1 }}>
      <AgGrid columnDefs={columnDefs} rowData={rawData} gridOptions={gridOptions}
        quickFilterText={quickFilterText} gridRef={ref} />
    </div>

    {
      confirmationModalDynamicOrAction &&
      <ConfirmationModal header={dataType === DataType.DYNAMIC ? `Dynamic: ${confirmationModalDynamicOrAction['Display Name']}` : `Action: ${getActionName(confirmationModalDynamicOrAction)}`}
        content={`Are you sure you want to delete this ${dataType}?`}
        onCancel={() => setConfirmationModalDynamicOrAction(undefined)}
        onSaved={() => {
          setConfirmationModalDynamicOrAction(undefined)
          deleteRow(confirmationModalDynamicOrAction.id)
        }} />
    }
  </>
}
