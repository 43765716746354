import { AxiosResponse } from 'axios'
import { request } from '../common'
import { GenericGetResponse } from '../../interfaces/all'

const evidenceDetailsPath = 'evidencedetails'

export const getEvidenceDetails = async (evidenceId: string, fieldId: string): Promise<GenericGetResponse> => {
  const { data } = await request({
    method: 'GET',
    path: `${evidenceDetailsPath}/${ evidenceId }`,
    queryParams: [{ key: 'field_id', value: fieldId }]
  })

  return data as GenericGetResponse
}

export const createEvidenceDetails = async(evidenceId: string, fieldId: string, updatedData: object): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: evidenceDetailsPath,
    body: {
      metadata: {
        evidence_id: evidenceId,
        field_id: fieldId
      },
      updatedData
    }
  })
}

export const deleteEvidenceDetails = async(dynamicId: string, evidenceId: string, fieldId: string): Promise<AxiosResponse> => {
  return await request({
    path: `${evidenceDetailsPath}/${dynamicId}`,
    method: 'DELETE',
    body:{
      metadata: {
        evidence_id: evidenceId,
        field_id: fieldId
      }
    }
  })
}
