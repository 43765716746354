import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'

import { getEvidence, updateEvidence } from '../../api/evidences/evidences'
import { showErrorToast, showToast } from '../../components/toast'
import { Column, DataType, GenericGetResponse, Metadata, RawData, isDictionaryColumn, isListColumn } from '../../interfaces/all'
import { Grid } from 'semantic-ui-react'
import SemanticForm, { getFormFieldsFromColumns } from '../../components/form/form'
import SemanticMenu from '../../components/menu'
import { createEvidenceDetails, deleteEvidenceDetails, getEvidenceDetails } from '../../api/evidences/evidence-details'
import DynamicGrid from '../../components/ag-grid/dynamic-grid'
import { updateDynamic } from '../../api/dynamic/dynamics'
import { canDelete, canEdit, isSysEdit } from '../../utils/permissions'
import SemanticButtonIcon from '../../components/button-icon'
import { SemanticButton } from '../../components/buttons/buttons'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import EditEvidencePageGuides from '../../guides/edit-evidence'
import SemanticTooltip from '../../components/tooltips/generic'

function EvidenceEditPage() {
  const { caseId, evidenceId } = useParams()
  const [evidenceData, setEvidenceData] = useState<RawData | undefined>()
  const [metadata, setMetadata] = useState<Metadata>({ columns: [] })
  const [activeList, setActiveList] = useState<{ fieldName: string, fieldId: number } | undefined>()
  const [details, setDetails] = useState<GenericGetResponse | undefined>()
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()

  useEffect(() => {
    if (evidenceId) {
      getEvidence(evidenceId).then(response => {
        setEvidenceData(response.raw_data[0])
        setMetadata(response.meta)
        setLoading(false)
      })
    }

  }, [evidenceId])

  useEffect(() => {
    if (activeList?.fieldId) {
      getEvidenceDetails(evidenceId as string, activeList.fieldId as unknown as string)
        .then(response => setDetails(response))
    }
  }, [evidenceId, activeList])

  async function reloadEvidenceAndDetails() {
    getEvidence(evidenceId as string).then(response => {
      setEvidenceData(response.raw_data[0])
      setMetadata(response.meta)
    })

    if (activeList?.fieldId) {
      getEvidenceDetails(evidenceId as string, activeList.fieldId as unknown as string)
        .then(response => setDetails(response))
    }
  }

  async function save(body: any) {
    const { status, data } = await updateEvidence({
      'Evidence #': evidenceData?.['Evidence #'],
      'Evidence Type': evidenceData?.['Evidence Type'],
      ...body
    }, evidenceId as string)

    if (status === 200) {
      showToast({ message: 'Evidence updated', position: 'top-left' })

      reloadEvidenceAndDetails()
    } else {
      showErrorToast(data?.message ?? 'Evidence not updated')
    }
  }

  let formFields = getFormFieldsFromColumns(metadata.columns.filter(col => col.name !== 'Evidence #' && col.name !== 'Evidence Type') ?? [])
  formFields = formFields.map(formField => ({
    ...formField,
    defaultValue: evidenceData ? evidenceData[formField.key] : undefined
  }))

  const lists = metadata.columns.filter(isListColumn)
    .map(col => ({
      header: col.name,
      fieldId: col.field_id
    })) ?? []

  async function deleteDetails(dynamicId: number) {
    const { status } = await deleteEvidenceDetails(dynamicId as unknown as string, evidenceId as string, activeList?.fieldId.toString() as string)

    if (status === 204) {
      showToast({ message: `${activeList?.fieldName} deleted` })
    }

    reloadEvidenceAndDetails()
  }

  async function createDetails(data: any) {
    const { status } = await createEvidenceDetails(evidenceId as string, activeList?.fieldId.toString() as string, data)

    if (status === 201) {
      showToast({ message: `${activeList?.fieldName} created` })
    }

    reloadEvidenceAndDetails()
  }

  async function updateDetails(data: any) {
    const { status } = await updateDynamic(data as RawData)

    if (status === 200) {
      showToast({ message: `${activeList?.fieldName} updated` })
    }

    reloadEvidenceAndDetails()
  }

  function getEvidenceType(columns: Column[], evidence: RawData | undefined): string | undefined {
    const options = columns.filter(isDictionaryColumn).find(col => col.name === 'Evidence Type')?.options
    return evidence && options && options[evidence['Evidence Type'] as number]
  }

  const pageContent = <>
    <Grid columns={2} divided style={{ paddingTop: '8px', height: '100%' }}>
      <Grid.Row style={{ height: '100%', overflow: 'auto' }}>
        <Grid.Column width={5} style={{ height: '100%' }}>
          <div style={{ fontSize: '28px', fontWeight: 600, marginBottom: '4px', textAlign: 'center' }}>
            <div style={{ marginBottom: '8px' }}>
              {evidenceData ? `Evidence: ${evidenceData?.['Evidence #']} (${getEvidenceType(metadata.columns, evidenceData)})` : 'loading evidence ...'}
              {
                isSysEdit(metadata.permissions) && evidenceData && <span style={{ paddingLeft: '8px', cursor: 'pointer' }}>
                  <SemanticTooltip text={'Modify Entity Structure'} iconProps={{ name: 'edit', color: 'blue', onClick: () => navigate(`/admin/entities/${evidenceData['Evidence Type']}`) }} />
                </span>
              }
            </div>
            <div>
              <SemanticButton text={'custody'} color='grey' disabled={loading}
                onClick={() => navigate(`/cases/${caseId}/evidences/${evidenceId}/chain-of-custody`, { state: evidenceData })} />
              <SemanticButtonIcon text='transfer' disabled={loading}
                buttonProps={{ labelPosition: 'right', color: 'black', onClick: () => navigate(`/cases/${caseId}/evidences/${evidenceId}/transfer`, { state: evidenceData }) }}
                iconProps={{ name: 'arrow alternate circle right outline' }} />
            </div>
          </div>
          <div style={{ padding: '16px' }}>
            <SemanticForm formFields={formFields}
              onCancel={() => navigate(`/cases/${caseId}/evidences`)} onSubmit={(data: any) => save(data)} />
          </div>
        </Grid.Column>
        <Grid.Column width={11} style={{ height: '100%' }}>
          <div style={{ padding: '8px', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <SemanticMenu items={
              lists.map(list => ({
                name: list.header,
                onClick: () => setActiveList({
                  fieldName: list.header,
                  fieldId: list.fieldId as number
                })
              }))
            }
            />
            {
              details && details?.meta && details.raw_data &&
              <DynamicGrid dataType={DataType.DYNAMIC}
                rows={details.raw_data}
                columns={details.meta.columns}
                onDelete={(dynamicId) => deleteDetails(dynamicId)}
                onCancel={() => reloadEvidenceAndDetails()}
                onCreate={(data) => createDetails(data)}
                onUpdate={(data) => updateDetails(data)}
                canDelete={canDelete(evidenceData?.['Evidence Type'] as number, metadata.permissions)}
                canEdit={canEdit(evidenceData?.['Evidence Type'] as number, metadata.permissions)} />
            }
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>
  return <SidebarWrapperForPage page={pageContent} sidebar={<EditEvidencePageGuides />} />
}

export default EvidenceEditPage
