import { useEffect, useState } from 'react'
import { useParams, useNavigate } from 'react-router-dom'

import { getCase, updateCase } from '../../api/cases/cases'
import { showErrorToast, showToast } from '../../components/toast'
import { Column, DataType, GenericGetResponse, Metadata, RawData, isDictionaryColumn, isListColumn } from '../../interfaces/all'
import SemanticForm, { getFormFieldsFromColumns } from '../../components/form/form'
import { Grid } from 'semantic-ui-react'
import { createCaseDetails, deleteCaseDetails, getCaseDetails } from '../../api/cases/case-details'
import DynamicGrid from '../../components/ag-grid/dynamic-grid'
import { updateDynamic } from '../../api/dynamic/dynamics'
import SemanticMenu from '../../components/menu'
import { canDelete, canEdit, isSysEdit } from '../../utils/permissions'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import EditCasePageGuides from '../../guides/edit-case'
import SemanticTooltip from '../../components/tooltips/generic'
import { CaseItem } from '../../interfaces/api/cases'

function CaseEditPage() {
  const { caseId } = useParams()
  const [caseData, setCaseData] = useState<CaseItem | undefined>()
  const [metadata, setMetadata] = useState<Metadata>({ columns: [] })
  const [activeList, setActiveList] = useState<{ fieldName: string, fieldId: number } | undefined>()
  const [details, setDetails] = useState<GenericGetResponse | undefined>()
  const navigate = useNavigate()

  useEffect(() => {
    getCase(caseId as string).then(response => {
      setCaseData(response.raw_data[0])
      setMetadata(response.meta)
    })
  }, [caseId])

  async function reloadCaseAndDetails() {
    getCase(caseId as string).then(response => {
      setCaseData(response.raw_data[0])
      setMetadata(response.meta)
    })

    if (activeList?.fieldId) {
      getCaseDetails(caseId as string, activeList.fieldId as unknown as string).then(response => setDetails(response))
    }
  }

  useEffect(() => {
    if (activeList?.fieldId) {
      getCaseDetails(caseId as string, activeList.fieldId as unknown as string)
        .then(response => setDetails(response))
    }

  }, [caseId, activeList])

  async function save(body: any) {
    const { status, data } = await updateCase({
      'Case #': caseData?.['Case #'],
      'Case Type': caseData?.['Case Type'],
      ...body,
    }, caseId as string)

    if (status === 200) {
      showToast({ message: 'Case updated', position: 'top-left' })

      reloadCaseAndDetails()
    } else if (status === 400) {
      showErrorToast(data?.message ?? 'Case not updated')
    }
  }

  const formFields = getFormFieldsFromColumns(metadata.columns.filter(col => col.name !== 'Case #' && col.name !== 'Case Type') ?? [])
    .map(formField => ({
      ...formField,
      defaultValue: caseData ? caseData[formField.key] : undefined
    }))

  const lists = metadata.columns.filter(isListColumn)
    .map(col => ({
      header: col.name,
      fieldId: col.field_id
    })) ?? []

  async function deleteDetails(dynamicId: number) {
    const { status } = await deleteCaseDetails(dynamicId as unknown as string, caseId as string, activeList?.fieldId?.toString() as string)

    if (status === 204) {
      showToast({ message: `${activeList?.fieldName} deleted` })
    }

    reloadCaseAndDetails()
  }

  async function createDetails(data: any) {
    const { status } = await createCaseDetails(caseId as string, activeList?.fieldId?.toString() as string, data)

    if (status === 201) {
      showToast({ message: `${activeList?.fieldName} created` })
    }

    reloadCaseAndDetails()
  }

  async function updateDetails(data: any) {
    const { status } = await updateDynamic(data as RawData)

    if (status === 200) {
      showToast({ message: `${activeList?.fieldName} updated` })
    }

    reloadCaseAndDetails()
  }

  function getCaseType(columns: Column[], data: RawData | undefined): string | undefined {
    const options = columns.filter(isDictionaryColumn).find(col => col.name === 'Case Type')?.options
    return data && options && options[data['Case Type'] as number]
  }

  const pageContent = <>
    <Grid columns={2} divided style={{ height: '100%' }}>
      <Grid.Row style={{ height: '100%', overflow: 'auto' }}>
        <Grid.Column width={5} style={{ height: '100%' }}>
          <div style={{ fontSize: '28px', fontWeight: 600, margin: '8px', textAlign: 'center' }}>
            {caseData ? `${caseData?.['Case #']} (${getCaseType(metadata.columns, caseData)})` : 'loading case ...'}
            {
              isSysEdit(metadata.permissions) && caseData && <span style={{ paddingLeft: '8px', cursor: 'pointer' }}>
                <SemanticTooltip text={'Modify Entity Structure'} iconProps={{ name: 'edit', color: 'blue', onClick: () => navigate(`/admin/entities/${caseData['Case Type']}`) }} />
              </span>
            }
          </div>
          <div style={{ padding: '16px'}}>
            <SemanticForm formFields={formFields}
              onCancel={() => navigate('/cases')} cancelLabel={'Return'}
              onSubmit={(data: any) => save(data)} />
          </div>

        </Grid.Column>
        <Grid.Column width={11} style={{ height: '100%' }}>
          <div style={{ padding: '8px', height: '100%', display: 'flex', flexDirection: 'column' }}>
            <SemanticMenu items={
              lists.map(list => ({
                name: list.header,
                onClick: () => setActiveList({
                  fieldName: list.header,
                  fieldId: list.fieldId as number
                })
              }))
            } />
            {details && details?.meta && details.raw_data &&
              <DynamicGrid dataType={DataType.DYNAMIC}
                rows={details.raw_data}
                columns={details.meta.columns}
                onDelete={(dynamicId) => deleteDetails(dynamicId)}
                onCancel={() => reloadCaseAndDetails()}
                onCreate={(data) => createDetails(data)}
                onUpdate={(data) => updateDetails(data)}
                canDelete={canDelete(caseData?.['Case Type'] as unknown as number, metadata.permissions)}
                canEdit={canEdit(caseData?.['Case Type'] as unknown as number, metadata.permissions)} />
            }
          </div>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<EditCasePageGuides />} />
}

export default CaseEditPage
