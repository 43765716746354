import { Form } from 'semantic-ui-react'
import { SemanticButton } from '../buttons/buttons'
import SemanticIcon from '../icons/icon'
import SemanticTextFormField from './text-field'


interface BarcodeFormProps {
  index: number
  value: string
  label?: string
  error?: string
  onChangeCallback: (event: React.ChangeEvent<HTMLInputElement>) => void
  onSubmitCallback: () => void
  onRemoveCallback?: () => void
  onTab?: () => void
  submitOnBlur?: boolean
  loading?: boolean
  autoFocus?: boolean
}
export default function BarcodeForm(props: BarcodeFormProps) {
  const {
    index,
    value,
    error,
    onChangeCallback,
    onSubmitCallback,
    onRemoveCallback,
    onTab,
    submitOnBlur = false,
    label = 'Barcode',
    loading = false,
    autoFocus
  } = props

  return <Form loading={loading}>
    <Form.Group widths='equal'>
      <SemanticTextFormField index={index} value={value} label={label} onTab={onTab}
        placeholder={'scan barcode ...'} error={error} autoFocus={autoFocus}
        onChange={onChangeCallback} onBlur={submitOnBlur ? onSubmitCallback : undefined} />
      {
        onRemoveCallback && <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <SemanticIcon name='remove' color='red' onClick={onRemoveCallback} />
        </span>
      }

      <div style={{ display: 'none' }}>
        <SemanticButton text={'Submit'} onClick={onSubmitCallback} type='submit' />
      </div>
    </Form.Group>
  </Form>
}
