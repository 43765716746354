import { useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'

import SemanticTable, { SemanticTableRow } from '../../components/table'
import { getEvidence, getEvidenceChainOfCustody } from '../../api/evidences/evidences'
import SemanticLoader from '../../components/loader'
import { RawData } from '../../interfaces/all'
import { ChainOfCustody } from '../../interfaces/api/evidences'
import SemanticIcon from '../../components/icons/icon'
import { dateTimeFormatter } from '../../utils/datetime'


export default function EvidenceChainOfCustodyPage() {
  const { evidenceId } = useParams()
  const { state } = useLocation()
  const [chainOfCustody, setChainOfCustody] = useState<ChainOfCustody | undefined>()
  const [evidenceData, setEvidenceData] = useState<RawData | undefined>()
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    async function load() {
      async function getEvidenceCall() {
        if (state) return state

        const { raw_data } = await getEvidence(evidenceId as string)

        return raw_data[0]
      }

      const [evidenceResponse, chainOfCustodyResponse] = await Promise.all([
        getEvidenceCall(),
        getEvidenceChainOfCustody(evidenceId as string)]
      )

      setEvidenceData(evidenceResponse)
      setChainOfCustody(chainOfCustodyResponse.data)
      setLoading(false)
    }

    load()
  }, [evidenceId, state])

  const title = evidenceData ? `Chain of custody for evidence: ${evidenceData?.['Evidence #']}` : 'Loading ...'

  const headers = ['id', 'completed date', 'from', 'to']

  const rows: SemanticTableRow[] = chainOfCustody?.transfers ? chainOfCustody.transfers
    .map((transfer) => {
      return {
        cells: [
          transfer.id,
          dateTimeFormatter(transfer.complete_timestamp),
          transfer.from_display,
          transfer.to_display
        ],
        rowProps: {
          negative: chainOfCustody?.breaking_transfers.includes(transfer.id)
        }
      }
    }) : []

  const chainOfCustodyStatus = <div>
    <SemanticIcon color={chainOfCustody?.broken_chain ? 'red' : 'green'}
      name={chainOfCustody?.broken_chain ? 'x' : 'check'} />
    {chainOfCustody?.broken_chain ? 'Broken chain of custody!' : 'All good!'}
  </div>

  return <>
    <div style={{ maxWidth: '500px', margin: '30px auto' }}>
      {
        loading ? <SemanticLoader /> : <>
          <h4>{title}</h4>
          {chainOfCustodyStatus}
          <SemanticTable headers={headers}
            rows={rows} />
        </>
      }
    </div>
  </>
}
