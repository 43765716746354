import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'

import { ExternalReport } from '../../interfaces/api/external-report'
import { getExternalReport } from '../../api/external-reports'
import IFrame from '../../components/iframe'


export function ReportPage() {
  const { reportId } = useParams()
  const [externalReport, setExternalReport] = useState<ExternalReport | undefined>()

  useEffect(() => {
    if (reportId) {
      getExternalReport(reportId).then(res => {
        setExternalReport(res.data)
      })
    }
  }, [reportId])

  return <>
  
    <div style={{ textAlign: 'center', marginTop: '8px' }}>
      {
        externalReport && <IFrame title={externalReport.name} src={externalReport.link}/>
      }
    </div>
  </>
}
