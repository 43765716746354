import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Form } from 'semantic-ui-react'

import { SemanticButton } from '../../../components/buttons/buttons'
import { joinOrganizationViaLink } from '../../../api/organizations'
import { RegistrationBasicInformation, getRegistrationBasicInformation } from '../../../api/registration'
import { showErrorToast, showToast } from '../../../components/toast'


export default function OrganizationJoinViaLinkPage() {
  const [registrationBasicInformation, setRegistrationBasicInformation] = useState<RegistrationBasicInformation | undefined>()
  const [firstName, setFirstName] = useState<string | undefined>(undefined)
  const [lastName, setLastName] = useState<string | undefined>(undefined)
  const [username, setUsername] = useState<string | undefined>(undefined)
  const [phone, setPhone] = useState<string | undefined>(undefined)
  const [password, setPassword] = useState<string | undefined>(undefined)
  const [passwordError, setPasswordError] = useState<string | undefined>(undefined)
  const [confirmPassword, setConfirmPassword] = useState<string | undefined>(undefined)
  const [confirmPasswordError, setConfirmPasswordError] = useState<string | undefined>(undefined)
  const [joiningOrganization, setJoiningOrganization] = useState(false)
  const navigate = useNavigate()
  const { guid } = useParams()

  useEffect(() => {
    if (guid) {
      getRegistrationBasicInformation(guid).then(res => setRegistrationBasicInformation(res.data))
    }

  }, [guid])

  function onChangePassword(value: string) {
    setPasswordError(undefined)
    setConfirmPasswordError(undefined)
    setPassword(value)
  }

  function onChangeConfirmPassword(value: string) {
    setPasswordError(undefined)
    setConfirmPasswordError(undefined)
    setConfirmPassword(value)
  }

  async function join() {
    if(!(password && password.length > 7)) {
      setPasswordError('Password must be at least 8 characters length')
      return
    }

    if(!(confirmPassword && confirmPassword.length > 7)) {
      setConfirmPasswordError('Password must be at least 8 characters length')
      return
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError('Passwords do not match')
      return
    }

    setJoiningOrganization(true)

    const { status, data } = await joinOrganizationViaLink({
      guid: guid as string,
      firstName: firstName as string,
      lastName: lastName as string,
      username: username as string,
      password,
      phone
    })

    if (status === 201) {
      showToast({message: `You've just joined ${registrationBasicInformation?.organization_name }`})
      navigate('/join-organization-via-link-confirmation')
    } else {
      showErrorToast(`${ data?.message ?? 'Error when joining organization'}`)
    }

    setJoiningOrganization(false)
  }

  return <>
    <div style={{ maxWidth: '300px', margin: '30px auto' }}>
      <h2>Join organization: { registrationBasicInformation?.organization_name}</h2>
      {
        registrationBasicInformation && <>
          <h4>User information</h4>
          <Form>
            <Form.Input fluid required label='First name' placeholder='first name' onChange={(e) => setFirstName(e.target.value)} />
            <Form.Input fluid required label='Last name' placeholder='last name' onChange={(e) => setLastName(e.target.value)} />
            <Form.Input fluid required label='Username' placeholder='username' onChange={(e) => setUsername(e.target.value)} />
            <Form.Input fluid required={false} label='Phone number' placeholder='phone number' onChange={(e) => setPhone(e.target.value)} />
            <Form.Input fluid required type='password' label='Password' placeholder='password' onChange={(e) => onChangePassword(e.target.value)} error={passwordError} />
            <Form.Input fluid required type='password' label='Confirm password' placeholder='password' onChange={(e) => onChangeConfirmPassword(e.target.value)} error={confirmPasswordError} />
            <SemanticButton text={'Submit'} onClick={join} disabled={!(firstName && lastName && username && password && confirmPassword) || joiningOrganization} />
          </Form></>
      }
    </div>
  </>
}
