import { Form } from 'semantic-ui-react'

interface SemanticNumberFormFieldProps {
  index: number
  value: number
  label: string
  required?: boolean
  error?: string
  onChange: (number: number) => void
  min?: number
  disabled?: boolean
}
export default function SemanticNumberFormField(props: SemanticNumberFormFieldProps) {
  const { label, index, value, error, required, onChange, min, disabled = false } = props

  return <><Form.Input type='number' min={min} required={required} key={index} fluid value={value}
    label={label} error={error} disabled={disabled}
    onChange={(event) => onChange(Number(event.target.value))}
  /></>
}
