import { SemanticCOLORS } from 'semantic-ui-react';
import { SemanticButton, SemanticButtonProps } from './buttons';

export default function ConfirmationButton(props: SemanticButtonProps) {
  const confirmationColor: SemanticCOLORS = 'blue'

  const semanticProps  = {
    color: confirmationColor,
    ...props
  }

  return <SemanticButton {...semanticProps}/>
}
