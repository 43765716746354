import { useNavigate, useParams } from 'react-router-dom'

import { showErrorToast } from '../../components/toast'
import EmailVerification from './email-verification'
import { verifyEmail } from '../../api/registration'

export default function UserEmailVerificationPage() {
  const navigate = useNavigate()
  const { guid } = useParams()

  async function emailVerificationHandler(password: string) {
    const { status, data } = await verifyEmail({
      guid: guid as string,
      password
    })

    if (status === 201) {
      navigate('/email-verification-confirmation')
    } else {
      showErrorToast(data?.message ?? 'Could not verify email')
    }
  }

  return <EmailVerification submitCallback={emailVerificationHandler} guid={guid as string}/>
}
