import { BuildLeftAlignedDiv, BuildTitle } from './utils/utils'


export default function DynamicEditPageGuides() {
  return (
    <>
      <div style={{ margin: '8px' }}>
        <BuildTitle title='Edit dynamic' />

        <BuildLeftAlignedDiv content={'TODO ...'} />
      </div>
    </>
  )
}
