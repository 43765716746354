import GlobalConfiguration from '../configuration/global'
import { Entity, User } from '../interfaces/all'

export function getDisplayName(user: User): string {
  const { first_name, last_name, username } = user

  const name = (first_name && last_name) ? `${first_name} ${last_name}` : undefined

  return name ? `${username} (${name})` : user.username
}

export function getEntityDisplayName(entity: Entity): string {
  return new GlobalConfiguration().isCaseBased() ? `${entity.data_name} (${entity.data_type})` : entity.data_name
}

export function addOrRemoveFromList<T>(list: T[], item: T): T[] {
  const index = list.indexOf(item)

  return index > -1 ? list.filter(x => x !== item) : [...list, item]
}

export function validateEmail(value: string): boolean {
  var regex = /\S+@\S+\.\S+/

  return regex.test(value)
}

export function validatePhoneNumber(value: string): boolean {
  // TODO https://github.com/2ndLogic/data-vault/issues/180
  return !!value && value.length > 0
}
