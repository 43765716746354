import { AxiosResponse } from 'axios'
import { request } from '../common'
import { GenericGetResponse } from '../../interfaces/all'

const serviceDetailsPath = 'servicedetails'

export const getServiceDetails = async (serviceId: string, fieldId: string): Promise<GenericGetResponse> => {
  const { data } = await request({
    method: 'GET',
    path: `${serviceDetailsPath}/${ serviceId }`,
    queryParams: [{ key: 'field_id', value: fieldId }]
  })

  return data as GenericGetResponse
}

export const createServiceDetails = async(serviceId: string, fieldId: string, updatedData: object): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: serviceDetailsPath,
    body: {
      metadata: {
        service_id: serviceId,
        field_id: fieldId
      },
      updatedData
    }
  })
}

export const deleteServiceDetails = async(dynamicId: string, serviceId: string, fieldId: string): Promise<AxiosResponse> => {
  return await request({
    path: `${serviceDetailsPath}/${dynamicId}`,
    method: 'DELETE',
    body:{
      metadata: {
        service_id: serviceId,
        field_id: fieldId
      }
    }
  })
}
