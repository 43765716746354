import { BuildLeftAlignedDiv, BuildTitle, BuildList } from './utils/utils'

export default function ReportsGuides() {
  return <>
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Reports'/>
      <BuildLeftAlignedDiv content={'My reports is where you can share your reports with your colleagues. For example if you build reports locally ' +
        'in Power BI, you can share it with some or all of your colleagues. When you share, Power BI will give you a link to the Power BI ' +
        'server where your report resides. You can use this page as a central location to give access to everyone so they do not have to go ' +
        'through their emails to find each report.'}/>
      <br/>
      <BuildLeftAlignedDiv content={'To add a new report after sharing: '}/>
      <BuildList items={[
        'Click on Add a row',
        'Enter a friendly name for your report and paste the link/URL provided by your Power BI',
        'Click the save button on that row'
      ]}/>
      <br/>
      <BuildLeftAlignedDiv content={'To use Power BI, Tableau, Crystal Reports or any other reports locally: '}/>
      <BuildList items={[
        'Contact us to get a private username and password to access your database on AWS cloud',
        'The connection and data exchange is encrypted and some report tools such as BI do not handle that level of security. Read the instructions on how to create an ODBC connection with encrypted connection.',
        'Build your reports and share with your colleagues. Add the links to the Infinite System to make it easier for everyone to find the reports. The permissions are still within your system and in your control. The link will not work for those who have not been given permission by you.'
      ]}/>
    </div>
  </>
}
