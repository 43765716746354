import { AxiosResponse } from 'axios'
import { request } from './common'
import { WorksheetFields, WorksheetFormFields } from '../interfaces/all'

const worksheetPath = 'worksheet'
const worksheetFieldPath = `${ worksheetPath }/worksheet-field`

export const getEntityWorksheets = async (entityId: string): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: worksheetPath,
    queryParams: [{ key: 'entity_id', value: entityId}]
  })
}

export const getWorksheet = async (worksheetId: string): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: `${ worksheetPath }/${ worksheetId }`
  })
}


export const createEntityWorksheet = async (entityId: string, worksheetName: string, worksheetFields: Omit<WorksheetFields, 'id'>[]): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: worksheetPath,
    body: {
      'entity_id': entityId,
      'worksheet_name' : worksheetName,
      'worksheet_fields': worksheetFields
    }
  })
}

export const deleteWorksheet = async (worksheetId: string): Promise<AxiosResponse> => {
  return await request({
    method: 'DELETE',
    path: `${ worksheetPath }/${ worksheetId }`
  })
}


export const checkWorksheetFieldHasData = async (entityId: string, worksheetFieldName: string): Promise<AxiosResponse> => {
  return await request({
    method: 'GET',
    path: `${ worksheetPath }/worksheet-field-has-data`,
    queryParams: [
      { key: 'entity_id', value: entityId },
      { key: 'worksheet_field_name', value: worksheetFieldName }
    ]
  })
}

export type CreateWorksheetFieldProps = WorksheetFormFields & {
  worksheet_id: string
}
export const createWorksheetField = async (body: CreateWorksheetFieldProps): Promise<AxiosResponse> => {
  return await request({
    method: 'POST',
    path: worksheetFieldPath,
    body
  })
}

export const updateWorksheetField = async (body: WorksheetFields): Promise<AxiosResponse> => {
  return await request({
    method: 'PUT',
    path: worksheetFieldPath,
    body
  })
}


export const deleteWorksheetField = async (worksheetId: string, worksheetFieldName: string): Promise<AxiosResponse> => {
  return await request({
    method: 'DELETE',
    path: worksheetFieldPath,
    body: {
      'worksheet_id': worksheetId,
      'worksheet_field_name' : worksheetFieldName,
    }
  })
}
