import { AxiosResponse } from 'axios'
import { request } from './common'
import { Pinned } from '../interfaces/api/me'

const pinnedPath = 'pinned'

type PinnedItemInput = Omit<Pinned, 'id' | 'text'>

export const addPinnedItem = async (body: PinnedItemInput): Promise<AxiosResponse> => {
  return request({
    method: 'POST',
    path: pinnedPath,
    body
  })
}

export const removePinnedItem = async (id: number): Promise<AxiosResponse> => {
  return request({
    method: 'DELETE',
    path: `${ pinnedPath }/${ id }`
  })
}
