import { useEffect, useState } from 'react'
import { getActionTypes } from '../../../api/actions/types'
import { ActionType } from '../../../interfaces/all'
import SemanticTable from '../../../components/table'
import { useNavigate } from 'react-router'
import { SemanticButton } from '../../../components/buttons/buttons'
import SemanticLoader from '../../../components/loader'
import { useDocumentation } from '../../../hooks/documentation'
import TitleWithDocumentation from '../../../components/title-with-documentation'
import ActionsGuides from '../../../guides/actions'
import SidebarWrapperForPage from '../../../components/sidebar-wrapper-for-page'

function ActionTypes() {
  const [actionTypes, setActionTypes] = useState<ActionType[]>([])
  const [loading, setLoading] = useState(true)
  const navigate = useNavigate()
  const documentation = useDocumentation()

  useEffect(() => {
    getActionTypes().then(resp => {
      setActionTypes(resp.data)
      setLoading(false)
    })
  }, [])

  const actionTypesTable = <SemanticTable headers={['Name']}
    rows={actionTypes.map(x => ({
      cells: [x.name]
    }))} />

  const loader = <div style={{ maxWidth: '300px', margin: '30px auto' }}>
    <SemanticLoader />
  </div>

  const pageContent = <>
    <div style={{ maxWidth: '300px', margin: '4px auto' }}>
      <TitleWithDocumentation title={'Action types'} documentationTooltipProps={{ text: documentation.actions }} />

      <SemanticButton text={'Return'} color='grey' onClick={() => navigate('/admin/workflows')} />
      <SemanticButton text={'Create new action type'} onClick={() => navigate('/admin/actiontypes/create')} />
      {
        loading ? loader : actionTypesTable
      }

    </div>
  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<ActionsGuides />} />
}

export default ActionTypes
