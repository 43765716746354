import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { Permissions } from '../../interfaces/all'
import { isSysAdmin, isSysEdit } from '../../utils/permissions'
import GlobalConfiguration from '../../configuration/global'
import { getPermissions } from '../../api/me'
import SidebarWrapperForPage from '../../components/sidebar-wrapper-for-page'
import AdminPageGuides from '../../guides/admin'
import { SemanticButton } from '../../components/buttons/buttons'



export default function AdminPage() {
  const navigate = useNavigate()
  const [permissions, setPermissions] = useState<Permissions>()
  const isCaseBased = new GlobalConfiguration().isCaseBased()

  useEffect(() => {
    getPermissions().then(res => setPermissions(res.data))
  }, [])

  const pageContent = <>
    <div style={{ maxWidth: '700px', margin: '0 auto' }}>
      <h1>Admin Hub</h1>
      <div style={{ margin: '4px' }}>
        <SemanticButton size={'mini'} color={'blue'} text={'Edit'} disabled={!isSysEdit(permissions)}
          onClick={() => navigate('entities')} />
        <span style={{ marginLeft: '4px' }}>Manage entities - Build data structures (Entities & Worksheets)</span>
      </div>
      <div style={{ margin: '4px' }}>
        <SemanticButton size={'mini'} color={'blue'} text={'Edit'} disabled={!isSysAdmin(permissions)}
          onClick={() => navigate('groups')} />
        <span style={{ marginLeft: '4px' }}>Manage groups - Permissions and access to data entities</span>
      </div>
      <div style={{ margin: '4px' }}>
        <SemanticButton size={'mini'} color={'blue'} text={'Edit'} disabled={!isSysAdmin(permissions)}
          onClick={() => navigate('users')} />
        <span style={{ marginLeft: '4px' }}>Manage users - Invite and approve users</span>
      </div>
      {
        isCaseBased && <>
          <div style={{ margin: '4px' }}>
            <SemanticButton size={'mini'} color={'blue'} text={'Edit'} disabled={!isSysAdmin(permissions)}
              onClick={() => navigate('workflows')} />
            <span style={{ marginLeft: '4px' }}>Manage workflows - Ordered series of actions to manage service workflow</span>
          </div>
          <div style={{ margin: '4px' }}>
            <SemanticButton size={'mini'} color={'blue'} text={'Edit'} disabled={!isSysAdmin(permissions)}
              onClick={() => navigate('locations')} />
            <span style={{ marginLeft: '4px' }}>Manage locations - Setup transfer locations (storage, evidence room, labs, refrigerators, etc.)</span>
          </div>
        </>
      }
    </div>
    <div style={{ textAlign: 'center'}}>
      <br/>
      <br/>
      <iframe width="280" height="160"
        src="https://www.youtube.com/embed/y9Hjqte6b2w"
        title="Infinite System Admin Hub" frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerPolicy="strict-origin-when-cross-origin"
        allowFullScreen>
      </iframe>
    </div>

  </>

  return <SidebarWrapperForPage page={pageContent} sidebar={<AdminPageGuides />} />
}
