import SemanticLoader from '../loader'
import SemanticModal from './modal'


interface ConfirmationModalProps {
  title: string
}
export default function LoadingModal(props: ConfirmationModalProps) {
  const { title } = props

  const content = <SemanticLoader/>

  return <>
    <SemanticModal header={title} content={content}/></>
}
