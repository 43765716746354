import { BuildLeftAlignedDiv, BuildList, BuildTitle } from './utils/utils'

export default function WorksheetGuides() {
  return <>
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Crafting Worksheets: Shaping Your Data Structure'/>
      <BuildLeftAlignedDiv content={'Worksheets are the building blocks that define the structure of your data. They specify what information should be captured for each entity and how it should be formatted.'}/>
      <BuildList items={[
        'Each worksheet represents a specific entity or concept within your system.',
        'Fields within a worksheet capture the individual pieces of information you want to track.',
        'Field types determine the format of the data (e.g., text, numbers, dates).'
      ]}/>
      <BuildLeftAlignedDiv content={<span><b>Remember:</b></span>}/>
      <BuildList items={[
        'Linking Fields: Create connections between different pieces of information (Entities) to establish context and relationships. The linking types are "List" and "Picklist".',
        'Display Order: Organize fields logically for clarity and ease of use.', 
        'Technical Details: For more in-depth PostgreSQL or code-related questions, refer to the official documentation or seek assistance from the 2nd Logic team.'
      ]}/>
    </div>
  </>
}
