const defaultTextStyle = { fontFamily: 'cursive', fontSize: 16 }

export function BuildTitle(props: { title: string }) {
  return <h1>{props.title}</h1>
}

export function BuildSubTitle(props: {title: string}) {
  return <h3>{ props.title }</h3>
}

export function BuildDiv(props: { content: any }) {
  return <div style={defaultTextStyle}>{ props.content }</div>
}

export function BuildLeftAlignedDiv(props: { content: any}) {
  return <div style={{...defaultTextStyle, textAlign: 'left' }}>{ props.content }</div>
}

export function BuildList(props: { items: any[] }) {
  return <div style={defaultTextStyle}>
    <ul>
      {
        props.items.map((item, index) => <li key={index} style={{ textAlign: 'left' }}>{item}</li>)
      }
    </ul>
  </div>
}

export function BuildOrderedList(props: { items: any[] }) {
  return <div style={defaultTextStyle}>
    <ol>
      {
        props.items.map((item, index) => <li key={index} style={{ textAlign: 'left' }}>{item}</li>)
      }
    </ol>
  </div>
}
