import { BuildLeftAlignedDiv, BuildDiv, BuildTitle } from './utils/utils';

export default function Actions() {
  return (
    <div style={{ margin: '8px' }}>
      <BuildTitle title='Manage Actions' />
      <BuildLeftAlignedDiv content='An action is a way to assign any activity tracking within the system.' />
      <BuildLeftAlignedDiv content='You can create a workflow in Manage Workflows using the actions you create here.' />
      <br />
      <BuildDiv content='**Action Types**' />
      <br />
      {/* Here you can add a table component to display action types */}
      <p>**Create New Action Type**</p>
      <BuildDiv content='Name: ' />
      <BuildDiv content='Enter the action type name in the input field.' />
      <br />
      <BuildDiv content='Click the "Create" button.' />
    </div>
  );
}
