import { BuildLeftAlignedDiv, BuildList, BuildTitle } from './utils/utils'

export default function CasesPageGuides() {
  return (
    <>
      <div style={{ margin: '8px' }}>
        <BuildTitle title='Managing Cases' />

        <BuildLeftAlignedDiv content={'This section displays a list of all existing cases in your system. Each case row shows information like case number, case type, and other relevant details depending on your system configuration.'} />

        <BuildList items={[
          'Export: Export your case as an xlsx file. Click the export button and choose a folder to save the file.',
          'Add Row (Create New Case): Click the "Add Row" button to initiate creating a new case.'
        ]} />
      </div>
    </>
  )
}
